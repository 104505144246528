import React from 'react';
import { getPackages } from '../../../../api'
import { createSaleTypePayload } from 'services/data-service';
export const TableContext = React.createContext({});

export function TableContextProvider({children}) {

    const [data, setData] = React.useState([]);
    const [accessed, setAccessed] = React.useState(false);
    const [filters, setFilters] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [count, setCount] = React.useState(10);
    const [corporatePackage, setCorporatePackage] = React.useState("false");

    const addFilter = (filterKey, value) => {
        if(filterKey === 'operation') {
            value = createSaleTypePayload(value);
        }
        setFilters((previousFilters) => {return {...previousFilters, [filterKey]:value}})
    };

    const deleteFilter = (key) => {
        setFilters((previousFilters) => {
            delete previousFilters[key];
            return {...previousFilters}
        })
    };

    React.useEffect(() => {
        if(accessed){
            getPackages({ pageSize, page, corporatePackage, ...filters, ...filters.operation }).then((response) => {
                setData((response.packages || []).map(_package => _package.info));
                setCount(response.totalSize)
            })
        }
    }, [filters, pageSize, page, accessed]);

    return (<TableContext.Provider value={{
        data,
        filters,
        addFilter: addFilter,
        deleteFilter: deleteFilter,
        page,
        setPage,
        pageSize,
        setPageSize,
        count,
        corporatePackage,
        setCorporatePackage,
        setAccessed,
    }}>
        {children}
    </TableContext.Provider>)
}