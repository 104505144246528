import React, { useEffect, useState } from 'react';
import { DataService } from '../../../services/data-service';
import { LoaderService } from '../../../services/loader-service';


export const FormContext = React.createContext({
  validated: false,
  setValidationStyleOn: () => {
  },
  formValues: {},
  setFormValues: () => {
  },
  apiFormInfo: {}
});

export const FormContextProvider = (props) => {
  const [validated, setValidation] = useState(false);
  const [formValues, setValues] = useState({});
  const [apiFormInfo, setFormInfo] = useState({});

  useEffect(() => {
    const payloadSubscription = DataService.getPayloadToRetrieveFormInfo()
      .subscribe((payload) => {
        LoaderService.requestLoader();
        props.getFormInfo(payload)
          .then((formInfo) => {
            setFormInfo(formInfo);
          }).finally(() => {
            LoaderService.closeLoaderRequest();
          }
        );
      });

    return () => {
      payloadSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (props.payload) {
      setValues(props.payload);
    }
  }, [props.payload]);

  const setValidationStyleOn = () => {
    setValidation(true);
  };

  const setFormValues = (key, value, valid) => {
    formValues[key] = { value: value, valid: valid };
    setValues({ ...formValues });
  };

  const removeFormValue = (name) => {
    setValues(formValues => {
      delete formValues[name];
      return formValues
    });
  };

  return (
    <FormContext.Provider value={{
      validated: validated,
      setValidationStyleOn: setValidationStyleOn,
      formValues: formValues,
      setFormValues: setFormValues,
      apiFormInfo: apiFormInfo,
      removeFormValue: removeFormValue
    }}>
      {props.children}

    </FormContext.Provider>
  );
};

export const FormContextConsumer = FormContext.Consumer;