import React from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "./index";
import { HistoryService, history } from "../../../services/history-service";

const HistoryBasedBreadcrumbs = () => {
  const [crumbs, setCrumbs] = React.useState([]);
  let router_history = useHistory();

  React.useEffect(() => {
    setCrumbs(HistoryService.getHistory().slice(-4));
  }, [history.length]);

  const goTo = (path) => {
    router_history.push(path);
    const n_paths = Math.abs(crumbs.indexOf(path) - crumbs.length);
    HistoryService.removeNPaths(n_paths);
  };

  return <Breadcrumbs crumbs={crumbs} goTo={goTo} />;
};

export default HistoryBasedBreadcrumbs;
