import { DEP_RESPONSE_MAP } from '../utils/dep-status-codes';

export class DEPStatus{

    status;

    constructor(code){
        this.status = DEP_RESPONSE_MAP["0"] || "No Status Found";
    }

    get Code(){
        return 0;
    }

    get Status(){
        return this.status;
    }
}
