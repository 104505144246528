import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AppContext } from "../app/context";
import { createCardList } from './card-list';
import Card from './card'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        paddingTop: "10%",
        justifyContent: 'center',
    },
    card: {
        width: "275px",
        height: "275px",
        textAlign: "center"
    },
    text: {
        align: 'center',
        color: "white",
    },
    footer: {
        margin: "0 auto",
        textAlign: "center",
        width: "10%",
        padding:"150px"
      } 
});

function ImgMediaCard(props) {
    const classes = useStyles();
    const { language, appmetadata } = useContext(AppContext);
    const cardList = createCardList(appmetadata, language);

    return (
      <><Grid container className={classes.root} spacing={1}>
            {cardList.map((card) => {
                return <Card {...card} />;
            })}
        </Grid>
        <footer className={classes.footer}>
                <text className={classes.text}></text>
        </footer></>
    );
}

export default ImgMediaCard;





