import React, {useContext} from "react";
import {FileContext} from "./form-input-file-context";

const Feedback = () => {
    const {feedback} = useContext(FileContext);

    return (<div style={{"width": "100%",
        "marginTop": "-1.25rem",
        "fontSize": "70%",
        "color": "#990000",
    }}>{feedback}</div>)
};

export default Feedback;