import React from 'react';
import Header from '../header/header';
import Footer from './footer-view';
import Grid from '@material-ui/core/Grid';
import './web-capture-layout.css';
import LoaderOverlay from '../loader';
import HistoryBasedBreadcrumbs from '../material-ui/breadcrumbs/history-based';

export default function WebCaptureLayout(props) {
  return (
    <LoaderOverlay>
      <Grid container style={{ height: '100vh', overflow: 'hidden' }}>
        <Grid item xs={12} style={{height: '82px', overflow: 'hidden' }}>
          <Header/>
        </Grid>
        <Grid item xs={12} style={{ height: '91vh',overflowY: 'auto', overflowX: 'hidden' }}>

          <Grid container xs={12} style={{ height: '91vh' }}>
            <Grid xs={12} item justify="center" style={{ paddingLeft: '20px', paddingRight: '20px', height: '7vh' }}>
              <HistoryBasedBreadcrumbs/>
            </Grid>
            <Grid xs={12} item style={{ paddingLeft: '20px', paddingRight: '20px', height: '84vh' }}>
              {props.children}
            </Grid>
          </Grid>
        </Grid>
        <Footer/>
      </Grid>
    </LoaderOverlay>
  );
};

