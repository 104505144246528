import React, {useContext} from "react";
import IconButton from '@material-ui/core/IconButton';
import {keycloakService} from "../../../services/keycloak-service";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";
import {AppContext} from "../../app/context";
import {useStyles} from "./icons-style";
import {getHelpFile} from '../../../api/index'
import {downloadFile} from "../../../utils";


const downloadHelpFile = () => {
    getHelpFile().then(data => {
        downloadFile('data:application/pdf;base64,' + encodeURI(data), 'ajuda');
    });
}

export default () => {
    const {language, appmetadata} = useContext(AppContext);
    const classes = useStyles();

    return (<Tooltip title={appmetadata.HEADER.HELP[language]}>
        <IconButton
            aria-label="help"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => downloadHelpFile()}
            id='exit'
            color="inherit"
            className={classes.icon}
        >
            <HelpIcon/>
        </IconButton>
    </Tooltip>)
}