import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Progress from '../../material-ui/progress';
import { styles } from './style';

const DialogContent = withStyles(styles)((props) => {
  const { children, classes, text, spinner, textBottom, ...other } = props;

  return (
    <MuiDialogContent dividers className={classes.content} {...other}>
      <Typography gutterBottom>
        {text}
      </Typography >
      {spinner ? <Progress/> : null}
      <Typography className={classes.contentChildren} gutterBottom>
        {textBottom}
      </Typography>
    </MuiDialogContent>
  );
});

export default DialogContent;