import React, {useContext} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import User from "./icons/user-icon";
import Exit from "./icons/exit-icon";
import Help from "./icons/help-icon";
import Logo from "./logo/logo";
import {RenderMenu, MenuButton} from "./icons/menu"
import {useStyles} from "./header-style"
import {AppContext} from "../app/context";
import '../layout/web-capture-layout.css'
import {UserService} from "../../services/user-service";

export function Header() {
    const {language,appmetadata} = useContext(AppContext);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleNavigationMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = 'primary-search-account-menu';

    let helloTxt = "";
    if(UserService.getUserId() !== "")
        helloTxt = appmetadata.HEADER.HELLO_USER[language] + UserService.getUserId();

    return (
        <div >
            <AppBar position="static" className={classes.header}>
                <Toolbar>
                    <Logo/>
                    <Typography className={classes.title} variant="h6" noWrap>
                    {helloTxt}<br/>{appmetadata.HEADER.TITLE[language]}
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.section}>
                        <Exit/>
                        <User/>
                        <Help/>
                        <MenuButton menuId={menuId} handleNavigationMenuOpen={handleNavigationMenuOpen}/>
                    </div>
                </Toolbar>
            </AppBar>
            <RenderMenu anchorEl={anchorEl} menuId={menuId} isMenuOpen={isMenuOpen} handleMenuClose={handleMenuClose} />
        </div>
    );
}

export default Header;