import React from 'react';
import { getPackages } from '../../../api';
import { AUTO_REFRESH_INTERVAL, BOTH_PHASES, PHASE_1, PHASE_2 } from '../../../variables/mesa-thales-filters';
import { createSaleTypePayload } from '../../../services/data-service';
export const MesaThalesTableContext = React.createContext({});


export function MesaThalesTableContextProvider({ children }) {

    const [data, setData] = React.useState([]);
    const [accessed, setAccessed] = React.useState(false);
    const [filters, setFilters] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [count, setCount] = React.useState(10);
    const [corporatePackage, setCorporatePackage] = React.useState("false");
    const [autoRefreshTrigger, setAutoRefreshTrigger] = React.useState(0);

    const addFilter = (filterKey, value) => {
        if (filterKey === 'operation') {
            value = createSaleTypePayload(value);
        }
        setFilters((previousFilters) => { return { ...previousFilters, [filterKey]: value } })
    };

    const deleteFilter = (key) => {
        setFilters((previousFilters) => {
            delete previousFilters[key];
            return { ...previousFilters };
        })
    };

    const getFilteredPackages = async (filters, pagination) => {
        const filteredPackages = {};
        const packagesRequest = await getPackages({ ...{ pageSize: pagination }, page, corporatePackage, ...filters });
        filteredPackages.packages = (packagesRequest.packages || []).map(_package => _package.info);
        filteredPackages.totalSize = packagesRequest.totalSize;
        return filteredPackages;
    }

    const getPhaseFilteredPackages = () => new Promise(async (resolve, reject) => {
        try {
            let filteredPackages = {};
            let treatedFilters = Object.assign({}, { ...filters, ...filters.operation });

            if (filters['phase'] === PHASE_1) {
                delete treatedFilters['phase'];
                treatedFilters.status = "purple";
                treatedFilters["exists!"] = "FLG-MESA-THALES";
                filteredPackages = await getFilteredPackages(treatedFilters, pageSize);

            } else if (filters['phase'] === PHASE_2 && filters['status'] === "purple") {
                delete treatedFilters['phase'];
                treatedFilters["FLG-MESA-THALES"] = "1";
                treatedFilters["status"] = "purple";
                filteredPackages = await getFilteredPackages(treatedFilters, pageSize);

            } else if (filters['phase'] === PHASE_2 && filters['status'] === "purpleVIP") {
                delete treatedFilters['phase'];
                treatedFilters["FLG-MESA-THALES"] = "1";
                treatedFilters["status"] = "purpleVIP";
                filteredPackages = await getFilteredPackages(treatedFilters, pageSize);

            } else if (filters['phase'] === PHASE_2 ) {
                delete treatedFilters['phase'];
                treatedFilters["FLG-MESA-THALES"] = "1";
                treatedFilters["status"] = "purple,purpleVIP";
                filteredPackages = await getFilteredPackages(treatedFilters, pageSize);

            } else if (filters['phase'] === BOTH_PHASES && filters['status'] === "purple") {
                delete treatedFilters['phase'];
                treatedFilters["status"] = "purple";
                filteredPackages = await getFilteredPackages(treatedFilters, pageSize);

            } else if (filters['phase'] === BOTH_PHASES && filters['status'] === "purpleVIP") {
                delete treatedFilters['phase'];
                treatedFilters["status"] = "purpleVIP";
                filteredPackages = await getFilteredPackages(treatedFilters, pageSize);

            } else if (filters['phase'] === BOTH_PHASES) {
                delete treatedFilters['phase'];
                treatedFilters["status"] = "purple,purpleVIP";
                filteredPackages = await getFilteredPackages(treatedFilters, pageSize);
            }
            resolve(filteredPackages);
        } catch (error) {
            reject('Error fetching packages.');
        }
    });

    React.useEffect(() => {
        if (accessed) {
            getPhaseFilteredPackages()
                .then(filteredPackages => {
                    setData(filteredPackages.packages);
                    setCount(filteredPackages.totalSize);
                });
        }
    }, [filters, pageSize, page, accessed, autoRefreshTrigger]);

    React.useEffect(() => {
        const autoRefresh = setInterval(() => {
            const randomTrigger = Math.random();
            setAutoRefreshTrigger(randomTrigger);
        }, AUTO_REFRESH_INTERVAL);
        return () => clearInterval(autoRefresh);
    }, []);

    return (<MesaThalesTableContext.Provider value={{
        data,
        filters,
        addFilter: addFilter,
        deleteFilter: deleteFilter,
        page,
        setPage,
        pageSize,
        setPageSize,
        count,
        corporatePackage,
        setCorporatePackage,
        setAccessed,
    }}>
        {children}
    </MesaThalesTableContext.Provider>)
}