import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import {EditableRowContextProvider} from './row-context';
import Row from './row'
import ActionButtons from './actions'
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import { ModalService } from '../../../../services/modal-service';
import {isUserAllowed} from "../../../permissions";
import {ABILITY} from "../../../../variables/roles-permissions";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  table: {
    width: '100% !important',
  },
}));

export function openFormModal(Form, formProps) {
  ModalService.changeInfo({
    show: true,
    title: 'Documento Corporativo',
    text: <Form {...formProps}/>,
    closeButton: true,
    spinner: false,
    buttons: "",
    textBottom: '',
    maxWidth: false,
  });
}


export default function SimpleTable({header, rows, form, formProps, reload}) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              <Typography variant={'h6'}>
              Documento
              </Typography>
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <IconButton aria-label="done" disabled={isUserAllowed(ABILITY.UPLOAD_CORPORATE_PACKAGES) ? false : true} onClick={() => openFormModal(form, formProps)}>
                <AddIcon fontSize="small"/>
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map(row => (
            <EditableRowContextProvider>
              <TableRow key={row.name}>
                <ActionButtons form={form}
                               formProps={{...formProps, ...row.formProps}}
                               deleteRow={row.deleteRow}
                               reload={reload}
                />
                <Row row={row}/>
              </TableRow>
            </EditableRowContextProvider>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}