import {AVAILABLE_THEMES} from '../themes';

export const firebaseConfig = {
    authDomain: 'test-dcs-2ec0e.firebaseapp.com',
    databaseURL: 'https://test-dcs-2ec0e.firebaseio.com',
    projectId: 'test-dcs-2ec0e',
    storageBucket: '',
    messagingSenderId: '68640560260',
    appId: '1:68640560260:web:4d52edaaa9596812',
};

export const depEnabled = true;

export const API_VERSION = "v1";

export const keycloakRedirectLogin = true;

export const authRequired = true;

export const userPermissionRequired = true;

export const documentFormRedirect = true;

export const fcmApiKey = 'firebase_APIKey_dev';

export const fcmVapKey = 'fcmVapKey_dev';

export const keycloakJsonConfigFile = '/keycloak-dev.json';

export const URI_SUFFIX = '';

const BASE_URL = 'http://localhost:3001/';

const BASE_URL_DEP = 'http://localhost:3000/dep';

export const persistTlsCa = '';

export const persistTlsKey = '';

export const persistTlsCert = '';

export const DOC_MATRIX = {
    BASE_URL: BASE_URL,
    URI_PATH: {
        SALE_TYPE: 'getCombos',
        REFRESH_SALE_TYPE: 'getCombos',
        REFRESH_SALE_TYPE2: 'getCombos',
        DOCUMENT_LIST: 'getDocs',
        STATUS_COLOR: 'getStatusColor/{status}',
        UPDATABLE_STATUS: 'getStatusRules/{status}',
        GET_KEYS: 'getKeys/',
        GET_HELP_FILE: 'getHelpFile',
    },
};

export const SELF_DOCS = {
    BASE_URL: window.location.origin + '/',
    URI_PATH: {
        FILTER_LIST: 'filters',
    },
};

export const TDI = {
    BASE_URL: BASE_URL + 'tdi/',
    URI_PATH: {
        SCENARIO_STEP: 'scenarios/{scenarioId}/state/steps/{stepId}',
        SCENARIO_STATUS: 'scenarios/{scenarioId}/',
        SCENARIO: 'scenarios',
    },
};

export const KEYCLOAK = {
    BASE_URL: BASE_URL,
    URI_PATH: {
        SCENARIO_STEP: '/scenarios/{scenarioId}/state/steps/{stepId}',
        TOKEN: 'realms/TIM-TDI-V1/protocol/openid-connect/token',
        CHANGE_PASSWORD: 'realms/TIM-TDI-V1/account/password',
    },
};

export const CONSULT = {
    BASE_URL: BASE_URL,
    URI_PATH: {
        PACKAGE: 'packages',
        FILE: "packages/{packageId}/files/{fileId}",
        DOCUMENT: "{packageId}/documents/{idDocument}",
        ZIP: 'zip',
        PDF: "pdf",
        UPDATE_STATUS: "updateStatus",
        UPDATABLE_STATUS: 'getStatusTransitions/{status}',
        EXPORT: 'export',
    },
};

export const PERSIST = {
    BASE_URL: BASE_URL + 'persist',
};

export const PROFILE = {
    BASE_URL: BASE_URL + "v2",
    URI_PATH: {
        PROFILE: 'profile',
    },
};

export const idletimeoutBreakEvents = ['onmouseup', 'onscroll', 'onkeypress'];

export const idletimeoutInMillis = 24 * 60 * 60 * 1000;

export const idletimeoutCheckInMillis = 60 * 1000;

export const tdiRetryDelayinMillis = 3 * 1000;

export const tdiPatchStepDelayinMillis = 3 * 1000;

export const FILESIZE_THRESHHOLD_SUM = 3 * 1024 * 1024;

export const MIN_FILESIZE_EACH = 20 * 1024;

export const THEME = AVAILABLE_THEMES.TIM;

export const DYNAMSOFT_LICENSE_KEY = "dynamsoft_d";

export const PUBLIC_KEY = "jwt_public_key";

export const DYNAMSOFT_RESOURCES_URI = "https://tim.dnbtim.se1.mcs-dev.acloud.thalescloud.io/dynamsoft";

export const exportCsvHeaderValues = ['MSISDN','cpf', 'CPFCNPJ','id','Operation','CLIENTID','SUBCLIENTID','CONTRATO','NOVO-PLANO','imei','COND','ENTRY','DISCOUNT','CCP1','CCP2','CC1','CC2','CCA1','CCA2','creationDate','TR_DATE','lastModificationDate','status','statusDescription','username','FRONT','store','superStore'];

