import React from 'react';
import '../form-input-scanner-and-file/form-input-scanner-dynamsoft-style.css';
import Printer from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import { STRINGS } from '../../../../variables/display-strings';
import { DYNAMSOFT_LICENSE_KEY, DYNAMSOFT_RESOURCES_URI } from '../../../../config';
import { keysService } from '../../../../services/keys-service';
import { take } from 'rxjs/operators';
import { base64Size } from '../../../../utils';
import Dynamsoft from 'dwt';

const onAcquireImage = (dwObject) => () => {
    dwObject.CloseSource();
};

function WebTwainComponent({ handleChange, metadata }) {
    // let Dynamsoft = require('dwt');

    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')) {
        if (metadata.updatable === false)
            fieldDisabled = true;
    }

    React.useEffect(() => {
        keysService.getKeys().pipe(
            take(1)
        ).subscribe((keys) => {
            const licenseKey = keys.filter((value) => {
                return Object.keys(value)[0] === DYNAMSOFT_LICENSE_KEY;
            });
            Dynamsoft.DWT.AutoLoad = false;
            Dynamsoft.DWT.ProductKey = licenseKey[0][DYNAMSOFT_LICENSE_KEY];
            // Dynamsoft.WebTwainEnv.Trial = false;
            Dynamsoft.DWT.ResourcesPath = DYNAMSOFT_RESOURCES_URI;
            Dynamsoft.DWT.Containers = [{ ContainerId: 'dwtcontrolContainer', Width: '0px', Height: '0px' }];
            Dynamsoft.DWT.Load();
        });
    }, []);

    const handleScan = () => {
        let dwObject = Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
        console.log('handleScan')
        if (dwObject) {
            let bSelected = dwObject.SelectSource();
            if (bSelected) {
                dwObject.OpenSource();
                dwObject.Resolution = 200;
                dwObject.AcquireImage({}, onAcquireImage(dwObject), onAcquireImage(dwObject));
                dwObject.RegisterEvent('OnPostTransferAsync', function (outputInfo) {
                    let imageIndex = dwObject.ImageIDToIndex(outputInfo.imageId);
                    let imageSize = dwObject.GetImageSize(imageIndex);
                    dwObject.SelectedImagesCount = 1;
                    dwObject.SetSelectedImageIndex(imageIndex, 0);
                    dwObject.GetSelectedImagesSize(imageSize);
                    dwObject.ConvertToBase64([imageIndex], 3, (a) => {
                        dwObject.src = 'data:image/png;base64,' + a._content;
                        handleChange({ image: a._content, size: base64Size(a._content) });
                    }, () => {
                    });

                });
            }
        } else {
            alert('Por favor aguarde o driver do escaner carregar');
        }
    };

    return (
        <div style={{ 'width': '16%' }}>
            <Tooltip title={STRINGS.FORM.SCAN['PT_BR']}>
                <button className={'form-button form-button-append form-button-custom'} onClick={handleScan} disabled={fieldDisabled}><Printer />
                </button>
            </Tooltip>
            <div id='dwtcontrolContainer' />
        </div>
    );

}

export default WebTwainComponent;