import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TableContext } from '../context';
import { ptBR } from "date-fns/locale";


function FormDateRangePicker({ label, filterKey }) {

  const {filters, addFilter, setPage} = React.useContext(TableContext);

  const handleDateChange = (value) => {
    console.log(value);
    let dateNoon = value.setHours(0,0,1);
    let newValue = new Date(dateNoon);
    console.log(newValue)
    if (newValue instanceof Date && !isNaN(newValue)) {
      console.log(newValue)
      let dateFormat = newValue.toISOString().slice(0, 10);
      let dateFormatddmmyyyy = dateFormat.slice(8, 10) + '/' + dateFormat.slice(5, 7) + '/' + dateFormat.slice(0, 4);
      console.log(dateFormatddmmyyyy);
      addFilter(filterKey, dateFormatddmmyyyy);
      setPage(0)
    }
  };

  const convertDateFormatddmmyyyytoDate = (ddmmyyyy) => {
    if (ddmmyyyy && ddmmyyyy.length === 10){
      return Date.parse(ddmmyyyy.slice(3, 5) + '/' + ddmmyyyy.slice(0, 2) + '/' + ddmmyyyy.slice(6, 10))
    }
    return null;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} className={'coisas'} locale={ptBR}>
      <KeyboardDatePicker
        clearable
        label={label}
        value={convertDateFormatddmmyyyytoDate(filters[filterKey])}
        placeholder="10/10/2018"
        onChange={date => handleDateChange(date)}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormDateRangePicker;