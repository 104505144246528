export const KEYCLOAK = {
    EVENT:{
        READY: "onReady",
        AUTH_SUCCESS: "onAuthSuccess",
        AUTH_ERROR: "onAuthError",
        AUTH_REFRESH_SUCCESS: "onAuthRefreshSuccess",
        AUTH_REFRESH_ERROR: "onAuthRefreshError",
        TOKEN_EXPIRED: "onTokenExpired",
        AUTH_LOGOUT: "onAuthLogout",
    },
    TOKEN:{
        ID_TOKEN: "idToken",
        REFRESH_TOKEN: "refreshToken",
        TOKEN: "token"
    },
    TOKEN_ATTR:{
        ROLES: "roles",
        ROLEID_STRING_MATCH: "capture"
    }
};