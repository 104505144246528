import { ReplaySubject } from "rxjs";
import i18n from 'i18next';
import translationEN from '../locales/en/translation.json';
import translationDE from '../locales/de/translation.json';
import { DEFAULT_LANGUAGE } from "config";


let $profile = new ReplaySubject(1);
let localization = null;

export const profileService = {
	getProfile: () => $profile,
	setProfile: (p) => $profile.next(p),
	setLocalization: (l) => localization = l,
	getLocalization: () => {return localization},
	initI18n: (profileData) => {
		i18n.init({
			resources: {
				en: {
					translation: { ...translationEN, ...profileData.localization.en }
				},
				pt: {
					translation: { ...translationDE, ...profileData.localization.pt }
				}
			},
			lng: DEFAULT_LANGUAGE,
			fallbackLng: "en",
			interpolation: {
				escapeValue: false
			}
		});
	}
};
