import React from 'react';
import './button-style.css'

export const BUTTON_STYLE ={
    DEFAULT_PRIMARY: "button-default-primary",
    DEFAULT_SECONDARY: "button-default-secondary",
    POSITIVE_PRIMARY: "button-positive-primary",
    POSITIVE_SECONDARY: "button-positive-secondary",
    NEGATIVE_PRIMARY: "button-negative-primary",
    NEGATIVE_SECONDARY: "button-negative-secondary",
    UNIMPORTANT: "button-gray",
};

const Button = ({children, onClick, variant, style, title}) => {
    return (<button className={"button-default " + variant} onClick={() => onClick()} style={style || {}} title={title || {}}>{children}</button>)
};

export default Button;