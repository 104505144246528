import axios from 'axios';
import { DEPClientService } from 'dep/services/dep-client.service';
import { LoaderService } from 'services/loader-service';
import { depEnabled } from '../config';
import { profileService } from './profile-service';


async function modifyRequest(request) {
    let requestBody;
    let publicKey;
    const xHttpMethod = "X-Http-Method";
    const accessControlAllowMethods = "Access-Control-Allow-Methods";
    const accessControlAllowHeaders = "Access-Control-Allow-Headers";
    const accessControlAllowCredentials = "Access-Control-Allow-Credentials";
    const dep = new DEPClientService();

    if (request.url.indexOf("tdisa") > -1) {
        publicKey = await getTdiPublicKey();
    } else {
        publicKey = await getDnbPublicKey();
    }

    if (request.headers[xHttpMethod] === "GET") {
        request["method"] = "post";
        request.headers[xHttpMethod] = "GET";
        requestBody = JSON.stringify({});
    } else if (request.headers[xHttpMethod] === "PUT") {
        request["method"] = "post";
        request.headers[xHttpMethod] = "PUT";
        requestBody = JSON.stringify(request.data);
    } else if (request.method === "get") {
        request["method"] = "post";
        request.headers[xHttpMethod] = "POST";
        request.headers[accessControlAllowMethods] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
        request.headers[accessControlAllowHeaders] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";
        request.headers[accessControlAllowCredentials] = "true"
        request.headers[xHttpMethod] = "GET";
        requestBody = JSON.stringify({});
    } else if (request.method === "post") {
        request.headers[xHttpMethod] = "POST";
        request.headers[accessControlAllowMethods] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
        request.headers[accessControlAllowHeaders] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";
        request.headers[accessControlAllowCredentials] = "true"
        requestBody = JSON.stringify(request.data);
    } else if (request.method === "put") {
        request["method"] = "post";
        request.headers[xHttpMethod] = "PUT";
        requestBody = JSON.stringify(request.data);
    } else if (request.method === "patch") {
        request["method"] = "post";
        request.headers[xHttpMethod] = "PATCH";
        request.headers[accessControlAllowMethods] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
        request.headers[accessControlAllowHeaders] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";
        request.headers[accessControlAllowCredentials] = "true";
        requestBody = JSON.stringify(request.data);
    }

    let data = await dep.getRequest(requestBody, publicKey);

    request['data'] = data;
    request["responseType"] = "arraybuffer";
    request.headers['content-type'] = 'application/octet-stream';
    request.depInstance = dep;
    return request;
}

function getTdiPublicKey() {
    return new Promise(function (resolve) {
        profileService.getProfile()
            .subscribe(data => {
                resolve(data.configs.k1);
            })
    })
}

function getDnbPublicKey() {
    return new Promise(function (resolve) {
        profileService.getProfile()
            .subscribe(data => {
                resolve(data.configs.k2);
            })
    })
}

export const AxiosService = {

    getInstance: () => {

        const instance = axios;

        instance.interceptors.request.use(request => {
            return request;
        }, error => {
            LoaderService.closeLoaderRequest();
        });

        return instance;
    },

    getInstanceWithLoader: () => {

        const instance = axios;

        instance.interceptors.request.use(request => {
            LoaderService.requestLoader();
            if (depEnabled && request.url.indexOf("dep") > -1) {
                return modifyRequest(request);
            } else {
                return request;
            }
        }, error => {
            LoaderService.closeLoaderRequest();
            return Promise.reject(error);
        });

        instance.interceptors.response.use(response => {
            LoaderService.closeLoaderRequest();
            if (depEnabled && response.config.url.indexOf("dep") > -1) {
                response.data = response.config.depInstance.getResponse(response.data);
            }
            return response;
        }, error => {
            LoaderService.closeLoaderRequest();
            return Promise.reject(error);
        })

        return instance;
    }

}

export default AxiosService;