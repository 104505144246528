import React, { useContext } from "react";
import Select from "../form-input/select"
import { FormContext } from "../../dynamic-form/dynamic-form-context";
import NumberFormat from "react-number-format";

const matchRegEx = (string, pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(string);
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    setFormValues(metadata.name, event.target.value, matchRegEx(event.target.value, ".+") || !Boolean(metadata.required));
};

export const getSelectedValue = (formValues, metadata, setSelectedValue) => {
    if (Object.prototype.hasOwnProperty.call((formValues || {}), metadata.name)){
        if (metadata.options.some((option) => Object.values(option).includes(formValues[metadata.name].value))){
            return formValues[metadata.name].value;
        }
        else{
            setSelectedValue({target:{value: Object.values(metadata.options[0])[0]}})
        }
    }
    else{
        setSelectedValue({target:{value: Object.values(metadata.options[0])[0]}})
    }
};

export default function Dropdown({metadata}){
    const { setFormValues, formValues } = useContext(FormContext);
    const setSelectedValue = onChange(setFormValues, formValues, metadata);
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false)
            fieldDisabled = true;
    }
    return (
        <Select id={metadata.name}
                metadata={metadata}
                valid={matchRegEx(((formValues[metadata.name] || {})["value"] || ''), ".+") || !Boolean(metadata.required)}
                onChange={setSelectedValue}
                type={"select"}
                value={getSelectedValue(formValues, metadata, setSelectedValue)}
                valueFromKey={0}
                disabled={fieldDisabled}
        />)
}