import React from 'react'
import ConsumerTable from './consumer-table'
import FilterChips from './table/filters/chips'
import FilterDate from './table/filters/date'
import FilterList from './table/filters/list'
import Grid from '@material-ui/core/Grid';
import DownloadButtons from './table/footer/download-button';
import TextFilter from './table/filters/text';
import StatusFilter from './table/filters/status';

const filterList = [
  {
    "value": "cpf",
    "label": "CPF",
    "component": <TextFilter label={"Valor"} filterKey={"cpf"} button={1}/>,
  },
  {
    "value": "CPFCNPJ",
    "label": "CNPJ",
    "component": <TextFilter label={"Valor"} filterKey={"CPFCNPJ"} button={1}/>,
  },
  {
    "value": "msisdn",
    "label": "MSISDN",
    "component": <TextFilter label={"Valor"} filterKey={"msisdn"} button={1}/>,
  },
  {
    "value": "imei",
    "label": "IMEI",
    "component": <TextFilter label={"Valor"} filterKey={"imei"} button={1}/>,
  },
  {
    "value": "status",
    "label": "Status",
    "component": <StatusFilter/>,
  },
  {
    "value": "userid",
    "label": "Usuário",
    "component": <TextFilter label={"Valor"} filterKey={"userid"} button={1}/>,
  },
  {
    "value": "serialDevice",
    "label": "Serial Acessório",
    "component": <TextFilter label={"Valor"} filterKey={"serialDevice"} button={1}/>,
  }
];


const ConsultList = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        Data de criação:
      </Grid>
      <Grid item xs={12}>
        <FilterDate filterKey={'creationStart'} label={'De'}/>
        <FilterDate filterKey={'creationEnd'} label={'Até'}/>
      </Grid>
      <Grid item xs={12} style={{'margin-top': '15px'}}>
        <FilterList filterList={filterList}/>
      </Grid>
      <Grid item xs={12}>
        <FilterChips/>
      </Grid>
      <Grid item xs={12} style={{ overflow: 'auto' }}>
        <ConsumerTable/>
      </Grid>
      <Grid item xs={12}>
        <DownloadButtons/>
      </Grid>
    </Grid>
  )
};

export default ConsultList
