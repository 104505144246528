import {isBase64} from "../../utils";
import {CODE} from "./status";

export const createTDIPayloadKey = (field, input, index) => {
    let key;
    if (typeof input === "string" && field.method === "waitForMetadata"){
        key = `${field.method}_metadata_${field.name}`
    }
    else if (isBase64(input)){
        key = `${field.method}_page${index+1}`
    }
    return key
};

export const createTDIStepFieldPayload = (payloadValue, field) => {
    let stepFieldPayload = {};
    if (Array.isArray(payloadValue)){
        payloadValue.forEach((value, index) => {
            stepFieldPayload[createTDIPayloadKey(field, value, index)] = value;
        })
    }
    else {
        stepFieldPayload[createTDIPayloadKey(field, payloadValue)] = payloadValue;
    }
    return stepFieldPayload
};

export const createTDIWaitForMetadataStepExtraPayload = (step, dropdownValues) => {
    let extraPayload = {};
    for (let [key, value] of Object.entries(dropdownValues)) {
        extraPayload = {...extraPayload, ...createTDIStepFieldPayload(value.toString(), {method:step, name:key, type:"text"})};
    }
    return extraPayload;
};

export const createTDIStepPayload = (step, statePayload, fileFormData, dropdownValues) => {
    let stepFields = fileFormData.fields.filter((field) => field.method === step);
    let stepPayload = {};
    let stepFieldPayload = {};
    if (step === 'waitForMetadata'){
        stepPayload = createTDIWaitForMetadataStepExtraPayload(step, dropdownValues);
    }
    stepFields.forEach((field) => {
        if (statePayload.hasOwnProperty(field.name)) {
            stepFieldPayload = createTDIStepFieldPayload(statePayload[field.name], field);
            stepPayload = {...stepPayload, ...stepFieldPayload};
        }
    });
    return stepPayload
};

export const translateCode = (code) => {
    let errorNotFound = "(Erro)\n"+
        "Atenção! Não foi possível criar o pacote de documentos.\n" +
        "\n"+
        "Entre em contato com o suporte informando os detalhes do pacote de documentação da venda e o código do erro:" +code +"\n"
    return (CODE[code] || errorNotFound)
};