import {Observable, ReplaySubject, BehaviorSubject} from "rxjs";
import {take} from "rxjs/operators";
import {ENV} from "../config";
import {getSaleTypeForm} from "../api";

let hash = require('object-hash');

let $payloadToGetPackageCreationInfo = new ReplaySubject(1);
let saleTypeList = {};
let $saleTypeListUpdate = new BehaviorSubject(saleTypeList);

export const createSaleTypePayload = (packageInfo) => {
    const IDCOMBOS = Object.keys(packageInfo).filter((key)=>{
        return key.match(/IDCOMBO/gi)
    });
    const CLIENTIDS = Object.keys(packageInfo).filter((key)=>{
        return key.match(key.match(/CLIENTID/gi))
    });
    let payload = {USERID: packageInfo.USERID,
        ROLEID: packageInfo.ROLEID,
        CLIENTID: 1,
        SUBCLIENTID: 1,
    };
    CLIENTIDS.forEach((CLIENTID) =>{
        payload[CLIENTID] = packageInfo[CLIENTID]
    });
    IDCOMBOS.forEach((IDCOMBO) =>{
        payload[IDCOMBO] = packageInfo[IDCOMBO]
    });
    return payload
};

export const createSaleTypePayloadHash = (packageInfo) => {
    const saleTypePayload = createSaleTypePayload(packageInfo);
    return hash(saleTypePayload, {unorderedObjects: true});
};

const getSaleType = (payload) => {
    return new Observable(subscriber => {
        getSaleTypeForm(payload).then(response => {
            subscriber.next(response);
        }).catch(error => {
            subscriber.error(error);
        })
    });
};

export const selectOption = (name, props) => {
    return function(option) {
        return Object.keys((option || {})).includes((props[name] || "").toString(10));
    }
};

export const DataService = {
    getPayloadToRetrieveFormInfo: () => $payloadToGetPackageCreationInfo,
    setPayloadToRetrieveFormInfo: (payload) => $payloadToGetPackageCreationInfo.next(payload),
    setSaleTypeList: (payload) => {
        const payload_hash = createSaleTypePayloadHash(payload);
        if (!saleTypeList.hasOwnProperty(payload_hash)){
            saleTypeList[payload_hash] = '';
            getSaleType(createSaleTypePayload(payload))
                .pipe(take(1))
                .subscribe((response) => {
                    const saleTypeString = (response.fields || []).map((field) =>
                        (field.options || []).filter(selectOption(field.name, payload))).map((option) => {
                        let value = Object.values(((option || []).pop() || {}));
                        value = (value || []).pop();
                        return value
                    }).join(' ');
                    if (saleTypeString){
                        saleTypeList[payload_hash] = saleTypeString;
                        $saleTypeListUpdate.next(saleTypeList);
                    }
                })
        }
    },
    getSaleTypeList: () => $saleTypeListUpdate,
};