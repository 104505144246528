import React, {useContext} from 'react';
import '../form-input-scanner-and-file/form-input-scanner-dynamsoft-style.css';
import {FileContext} from './form-input-file-context';
import {AppContext} from '../../../app/context';
import CameraIcon from '@material-ui/icons/CameraAlt';
import Tooltip from '@material-ui/core/Tooltip';


const fileToBase64 = (evt, file) => {
    return new Promise(resolve => {
        var reader = new FileReader();
        reader.onload = function (event) {
            resolve(event.target.result);
        };
        reader.readAsBinaryString(file);
    });
};

const handleFileChange = (onChange) => (event) => {
    Array.from(event.currentTarget.files).forEach((file) => {
        fileToBase64(event, file).then((base64Image) => {
            onChange({size: file.size, image: btoa(base64Image)});
        });
    });
    // Fix for EFH340-588
    event.currentTarget.value = null;
};

const FileInputView = ({metadata}) => {
    const {handleChange} = useContext(FileContext);
    const {language, appmetadata} = useContext(AppContext);
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    let classes = 'form-button form-button-secondary'
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false) {
            classes = 'form-button form-button-disabled'
            fieldDisabled = true
        }
    }

    return (
        <div style={{'width': '20%'}}>
            <Tooltip title={appmetadata.FORM.ATTACH[language]}>
                <label htmlFor={metadata.name} className={classes}>
                    <CameraIcon/>
                </label>
            </Tooltip>
            <div className={'hidden'}>
                <input type="file" id={metadata.name}
                       onChange={handleFileChange(handleChange)}
                       accept={metadata.accept}
                       disabled={fieldDisabled}
                       multiple={Boolean(metadata.multiple)}/>
            </div>
        </div>
    );
};

export default FileInputView;