import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { MesaThalesTableContext } from '../../../mesa-thales-context';
import Status from '../../../../status';
import { ALLOWED_STATUSES_PHASE_1, ALLOWED_STATUSES_PHASE_2, BOTH_PHASES, PHASE_1, PHASE_2 } from 'variables/mesa-thales-filters';


function StatusFilter() {

    const [statusList, setStatusList] = React.useState(ALLOWED_STATUSES_PHASE_1);
    const { filters, addFilter, setPage, deleteFilter } = React.useContext(MesaThalesTableContext);

    React.useEffect(() => {
        if (filters['phase'] === PHASE_1) {
            setStatusList(ALLOWED_STATUSES_PHASE_1);
            if (filters['status'] === "purpleVIP") {
                deleteFilter('status');
            }
        } else if (filters['phase'] === PHASE_2 || filters['phase'] === BOTH_PHASES) {
            setStatusList(ALLOWED_STATUSES_PHASE_2);
        }
    }, [filters]);

    const handleChange = (event) => {
        addFilter('status', event.target.value);
        setPage(0)
    };

    return (<TextField
        id="status-filter"
        select
        label="Selecione"
        value={filters.status}
        onChange={handleChange}
        fullWidth={1}
    >
        {statusList.map((status) => (
            <MenuItem key={status.value} value={status.status}>
                <Status status={status} showDescription={1} />
            </MenuItem>
        ))}
    </TextField>)
}

export default StatusFilter;