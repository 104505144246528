import React from 'react';
import Table from '../../../../material-ui/table';
import { PackageContext } from '../../package-context';
import { FILTER_KEYS_NAME_REPLACEMENT, FILTER_VALUES_NAME_REPLACEMENT, LIVETIM_KEY_VALUE_REPLACEMENT } from '../../../../../variables/consult-info';
import { sortKeysIgnoreCase } from '../../../../../utils';
import {StatusRow} from './status-row';

export const createDetailList = (_package, reloadPackage) => {
  return Object.keys(((_package || {}).info || {}))
    .filter((key) => (Object.keys(LIVETIM_KEY_VALUE_REPLACEMENT).includes(key)))
    .map((key) => {
        let value = _package.info[key];
        return {
          name: key,
          value: translatePackageDetails(key, value, _package, reloadPackage)
        };
      }
    );
};

export const LiveTimIndex = () => {
  const { _package, reloadPackage } = React.useContext(PackageContext);
  const detail_list = createDetailList(_package, reloadPackage);
  detail_list.sort( (a, b) => a.value[0].localeCompare(b.value[0], 'fr', { ignorePunctuation: true }));
  return <Table rows={detail_list}/>;
};

function translatePackageDetails(key, value, _package, reloadPackage) {
  let translatedValue = FILTER_VALUES_NAME_REPLACEMENT[value] || value;

  if (key === 'status') {
    return [FILTER_KEYS_NAME_REPLACEMENT[key], StatusRow(value, _package, reloadPackage)];
  } else {
    return [FILTER_KEYS_NAME_REPLACEMENT[key], translatedValue];
  }
}


export default LiveTimIndex;
