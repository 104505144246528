import React from "react";
import { getStatusColor, getUpdatableStatus } from '../../../api/'
import Grid from "@material-ui/core/Grid";
import { FormContextProvider } from "../dynamic-form/dynamic-form-context";
import SubmitButton from "../dynamic-form/dynamic-form-submit-button"
import Form from "../dynamic-form/dynamic-form-view"
import Status from '../../consult/status';
import { DataService } from '../../../services/data-service';
import SaleType from '../../consult/sale-type';
import { generateSaleTypePayload } from '../../consult/list/consumer-table';
import { ModalService } from '../../../services/modal-service';
import StatusConfirmationForm from './confirmation';
import TextArea from "../fields/form-input-textarea";
import CheckBox from "../fields/form-input-checkbox-component";
import "./mesa-thales-style.css"


const onSubmit = (reloadPackage) => (payload) => {
    const justifications = payload["justifications"];
    let newObservations = "";
    const packageType = payload["packageType"];
    if (packageType) {
        newObservations += "Tipo de pacote:" + packageType + ";"
        for (const key in justifications) {
            if (justifications[key].length > 0) {
                newObservations += key + ":";
                justifications[key].forEach(value => {
                    newObservations += value + ",";
                });
                newObservations = newObservations.substring(0, newObservations.length - 1) + ";";
            }
        }

        if (payload["observations"]) {
            newObservations += "Observações Extras:" + payload["observations"] + ";"
        }

        const newPayload = {
            "packageId": payload["packageId"],
            "oldStatus": payload["oldStatus"],
            "status": payload["status"],
            "observations": newObservations
        }

        ModalService.changeInfo({
            show: true,
            title: 'Confirmar alterações do pacote?',
            text: <StatusConfirmationForm reloadPackage={reloadPackage} currentPayload={newPayload} />,
            closeButton: true,
            spinner: false,
            callback: () => { },
            text2: "",
            maxWidth: "md",
        });
    } else {
        alert("Selecione um tipo de pacote para continuar!");
    }
};

async function getStatusForm({ _package }) {
    const updatableStatus = await getUpdatableStatus((_package && (_package.info) && _package.info.status));
    const toColors = await Promise.all(updatableStatus.data.allowedStatus.map((status) => {
        return getStatusColor(status)
    }));
    const props = generateSaleTypePayload(((_package && _package.info) || {}));

    return {
        fields: [
            {
                "label": "ID do pacote",
                "name": "packageId",
                "required": false,
                "type": "text",
                "disabled": true
            },
            {
                "label": "Operação",
                "name": "operation",
                "required": false,
                "type": "component",
                "component": <SaleType {...props} />,
                "disabled": true
            },
            {
                "label": "Status atual",
                "name": "oldStatus",
                "required": false,
                "type": "component",
                "component": <Status
                    showDescription={1}
                    status_name={((_package && _package.info) && _package.info.status)} />,
                "disabled": true
            },
            {
                "label": "Novo status",
                "method": "waitForMetadata",
                "multiple": false,
                "name": "status",
                "options": toColors.map((color) => {
                    return color.data.map((status_info) => {
                        return {
                            [status_info.status]: <Status
                                showDescription={status_info.description}
                                status={status_info} />
                        }
                    })
                }).flat(),
                "required": true,
                "type": "componentSelect",
            },
            {
                "groupTitle": "Tipo de pacote*",
                "name": "packageType",
                "required": false,
                "type": "radioButtons",
                "values": [
                    { "value": "WEB Fase 1" },
                    { "value": "MOBILE Fase 2" }
                ]
            }
        ]
    }
};

function createPayload(_package) {
    return {
        packageId: { value: _package.info.id.toString(), valid: true },
        oldStatus: { value: _package.info.status, valid: true },
    }
}

const StatusFormView = ({ status, _package, reloadPackage }) => {

    React.useEffect(() => {
        DataService.setPayloadToRetrieveFormInfo({ status: status, _package: _package });
    });

    const observationsTextArea = {
        "metadata": {
            "groupTitle": "Observações Extras",
            "name": "observations",
            "required": false,
            "type": "textArea",
            "options": "Nombre"
        }
    };

    const maltaCheckBoxes = [
        {
            "groupId": 1,
            "groupName": "Documento de Identificação Pessoal",
            "groupItems": [
                { "metadata": { "name": "Indexador Divergente", "required": false } },
                { "metadata": { "name": "Indexador Incompleto", "required": false } },
                { "metadata": { "name": "Indexador Ausente", "required": false } },
                { "metadata": { "name": "Expirado", "required": false } },
                { "metadata": { "name": "Layout Suspeito", "required": false } },
                { "metadata": { "name": "Foto de Tela", "required": false } },
                { "metadata": { "name": "Fotocópia", "required": false } },
            ]
        },
        {
            "groupId": 2,
            "groupName": "Nome do Cliente",
            "groupItems": [
                { "metadata": { "name": "Indexador Divergente", "required": false } },
                { "metadata": { "name": "Indexador Incompleto", "required": false } },
                { "metadata": { "name": "Indexador Ausente", "required": false } },
            ]
        },
        {
            "groupId": 3,
            "groupName": "Data de Nascimento",
            "groupItems": [
                { "metadata": { "name": "Indexador Divergente", "required": false } },
                { "metadata": { "name": "Indexador Incompleto", "required": false } },
                { "metadata": { "name": "Indexador Ausente", "required": false } },
            ]
        },
        {
            "groupId": 4,
            "groupName": "Nome da Mãe",
            "groupItems": [
                { "metadata": { "name": "Indexador Divergente", "required": false } },
                { "metadata": { "name": "Indexador Incompleto", "required": false } },
                { "metadata": { "name": "Indexador Ausente", "required": false } },
            ]
        },
        {
            "groupId": 5,
            "groupName": "Outros",
            "groupItems": [
                { "metadata": { "name": "Foto do documento pessoal não foi anexado ao pacote", "required": false } },
                { "metadata": { "name": "Pacote movido para VERMELHO após 24 horas sem atualização do APP TIM Vendas", "required": false } },
                { "metadata": { "name": "Transferência de Titularidade: documento do Cessionário ou Cedente Divergente, Incompleto ou Ausente", "required": false } },
                { "metadata": { "name": "Documento pessoal obrigatório das testemunhas 1 e-ou 2 Divergente, Incompleto ou Ausente", "required": false } },
            ]
        }
    ];


    return (
        <Grid container spacing={0}>
            <FormContextProvider getFormInfo={getStatusForm} payload={createPayload(_package)}>
                <Grid container spacing={0} alignItems={'center'} style={{ "flexFlow": "column" }}>

                    <Form />

                    <div className="form-container">

                        {maltaCheckBoxes.map(group =>
                            <>
                                <p className="group-title">{group.groupName}</p>
                                {group.groupItems.map(item =>
                                    <div style={{ width: group.groupName != "Outros" ? '33%' : '100%', display: 'inline-block' }}>
                                        <CheckBox {...item} {...{ group: group.groupName }} ></CheckBox>
                                    </div>
                                )}
                            </>
                        )}

                    </div>

                    <TextArea {...observationsTextArea} />

                </Grid>
                <SubmitButton text={'Confirmar'} customSubmit={onSubmit(reloadPackage)} />
            </FormContextProvider >
        </Grid >
    )
};

export default StatusFormView;
