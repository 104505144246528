export class Message {
    mediaType;
    content;
    constructor( mediaType, content){
      this.mediaType = mediaType;
      this.content = content;
    }

    get ContentType() {
      return this.mediaType;
    }

    get Content() {
      return this.content;
    }
}
