import FormInputFile from "../fields/form-input-file";
import FormInputText from "../fields/form-input-text";
import FormReduxDropdown from "../fields/form-input-redux-dropdown";
import FormDropdown from "../fields/form-input-dropdown";
import FormInputScanner from "../fields/form-input-scanner";
import FormDatepicker from "../fields/form-input-datepicker";
import HiddenFormField from "../fields/form-input-hidden";
import FormInputFileAndScanner from "../fields/form-input-scanner-and-file";
import FormInputCurrency from "../fields/form-input-currency";
import FormInputMaskedNumber from "../fields/form-input-masked-number";
import FormInputNumber from "../fields/form-input-number";
import FormInputCpf from "../fields/form-input-cpf";
import FormInputImei from "../fields/form-input-imei";
import FormInputPhone from "../fields/form-input-phone";
import FormInputCnpj from "../fields/form-input-cnpj";
import FormInputDisabledComponent from '../fields/form-input-disabled-component'
import FormInputTextArea from '../fields/form-input-textarea'
import FormInputComponentSelect from '../fields/form-input-select-component'
import FormInputCheckBoxSelect from '../fields/form-input-checkbox-component'
import RadioButtonsGroup from "../fields/form-input-radio-buttons";
import FormInputAutocomplete from '../fields/form-input-autocomplete'
import FormInputAddFields from '../fields/form-input-add-fields'
import mobile from "is-mobile";

export const addFields = "addFields";

export const COMPONENT_LIST = {
    file: FormInputFile,
    text: FormInputText,
    maskedNumber: FormInputMaskedNumber,
    password: FormInputText,
    select: FormReduxDropdown,
    option: FormDropdown,
    scanner: mobile() ? FormInputFile : FormInputScanner,
    datepicker: FormDatepicker,
    hidden: HiddenFormField,
    fileAndScanner: mobile() ? FormInputFile : FormInputFileAndScanner,
    currency: FormInputCurrency,
    number: FormInputNumber,
    cpf: FormInputCpf,
    imei: FormInputImei,
    phone: FormInputPhone,
    cnpj: FormInputCnpj,
    component: FormInputDisabledComponent,
    textArea: FormInputTextArea,
    componentSelect: FormInputComponentSelect,
    checkBox: FormInputCheckBoxSelect,
    radioButtons: RadioButtonsGroup,
    autocomplete: FormInputAutocomplete,
    [addFields]: FormInputAddFields,
};