import React from 'react';
import {ABILITY} from '../../../variables/roles-permissions';
import FullDetailsTab from './tabs/full-details-tab';
import Index from './tabs/details-tab';
import PrepaidIndex from './tabs/details-tab/prepaid_index';
import LiveTimIndex from './tabs/details-tab/livetim_index';
import DocumentStatusTab from './tabs/document-status-tab';
import DownloadFilesTab from './tabs/files-tab';
import ScoresTab from './tabs/score-tab';
import DependentsTab from './tabs/dependents-tab';
import EditableDocumentsTab from './tabs/editable-document-tab';
import EditableClienteDocumentsTab from './tabs/editable-cliente-document-tab'
import EditablePedidoDocumentsTab from './tabs/editable-pedido-document-tab'

const label_value = 'Detalhes Completos';

export const PROFILES = {
    CONSUMER: 'consumer',
    CORPORATE: 'corporate',
    PREPAID: 'prepaid',
    LIVETIM: 'timlive',
    MESATHALES: 'mesathales'
};

export const AVAILABLE_TABS = {
    [PROFILES.CONSUMER]: [
        {
            label: label_value,
            index: 0,
            ability: ABILITY.VIEW_COMPLETE_CONSUMER_DETAILS,
            component: <FullDetailsTab/>
        },
        {label: 'Detalhes', index: 1, ability: ABILITY.READ_PACKAGES, component: <Index/>},
        {label: 'Notas', index: 2, ability: ABILITY.READ_PACKAGE_SCORES, component: <ScoresTab/>},
        {label: 'Documentos', index: 3, ability: ABILITY.DOWNLOAD_DOCUMENTS, component: <DownloadFilesTab/>},
        {label: 'Status dos Documentos', index: 4, ability: ABILITY.READ_PACKAGES, component: <DocumentStatusTab/>},
        {label: 'Dependentes', index: 5, ability: ABILITY.VIEW_COMPLETE_CONSUMER_DETAILS, component: <DependentsTab/>}
    ],
    [PROFILES.CORPORATE]: [
        {
            label: label_value,
            index: 0,
            ability: ABILITY.VIEW_COMPLETE_CORPORATE_DETAILS,
            component: <FullDetailsTab/>
        },
        {
            label: 'Documentos de Cliente',
            index: 1,
            ability: ABILITY.READ_CORPORATE_PACKAGES,
            component: <EditableClienteDocumentsTab/>
        },
        {
            label: 'Documentos de Pedido',
            index: 2,
            ability: ABILITY.READ_CORPORATE_PACKAGES,
            component: <EditablePedidoDocumentsTab/>
        },
        {
            label: 'Documentos Corporativos',
            index: 3,
            ability: ABILITY.READ_CORPORATE_PACKAGES,
            component: <EditableDocumentsTab/>
        }
    ],
    [PROFILES.PREPAID]: [
        {
            label: label_value,
            index: 0,
            ability: ABILITY.VIEW_COMPLETE_CONSUMER_DETAILS,
            component: <FullDetailsTab/>
        },
        {label: 'Detalhes', index: 1, ability: ABILITY.READ_PACKAGES, component: <PrepaidIndex/>},
        { label: 'Notas', index: 2, ability: ABILITY.READ_PACKAGE_SCORES, component: <ScoresTab/> },
        { label: 'Documentos', index: 3, ability: ABILITY.DOWNLOAD_DOCUMENTS, component: <DownloadFilesTab/> },
    ],
    [PROFILES.LIVETIM]: [
        {
            label: label_value,
            index: 0,
            ability: ABILITY.VIEW_COMPLETE_CONSUMER_DETAILS,
            component: <FullDetailsTab/>
        },
        {label: 'Detalhes', index: 1, ability: ABILITY.READ_TIMLIVE_PACKAGES, component: <LiveTimIndex/>},
        {label: 'Notas', index: 2, ability: ABILITY.READ_PACKAGE_SCORES, component: <ScoresTab/>},
        {label: 'Documentos', index: 3, ability: ABILITY.DOWNLOAD_DOCUMENTS, component: <DownloadFilesTab/>},
        {label: 'Status dos Documentos', index: 4, ability: ABILITY.READ_PACKAGES, component: <DocumentStatusTab/>}
    ],
    [PROFILES.MESATHALES]: [
        {
            label: label_value,
            index: 0,
            ability: ABILITY.VIEW_COMPLETE_CONSUMER_DETAILS,
            component: <FullDetailsTab/>
        },
        {label: 'Detalhes', index: 1, ability: ABILITY.READ_MESATHALES_PACKAGES, component: <Index/>},
        {label: 'Notas', index: 2, ability: ABILITY.READ_PACKAGE_SCORES, component: <ScoresTab/>},
        {label: 'Documentos', index: 3, ability: ABILITY.DOWNLOAD_DOCUMENTS, component: <DownloadFilesTab/>},
        {label: 'Status dos Documentos', index: 4, ability: ABILITY.READ_PACKAGES, component: <DocumentStatusTab/>},
        {label: 'Dependentes', index: 5, ability: ABILITY.VIEW_COMPLETE_CONSUMER_DETAILS, component: <DependentsTab/>}

    ]

};

