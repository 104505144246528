import React from "react";
import {FormContext} from "../../dynamic-form/dynamic-form-context";
import MetadataLayout from "../form-input/layout-metadata";

const onChange = (setFormValues, formValues, metadata) => (event) => {
  setFormValues(metadata.name, event.target.value, true);
};

const style = {
  'border-radius': '2px',
  width: '100%',
  border: '1px solid rgb(181, 181, 181)'
};

export default function TextArea(props){
  const {metadata} = props;
  const {setFormValues, formValues} = React.useContext(FormContext);

  // const [disabled, setDisabled]  = React.useState(metadata.disabled);


  // React.useEffect(() => {
  //   if(formValues["Otros"]){
  //     setDisabled(!formValues["Otros"]?.value)
  //   }
  // },[formValues]);

  return (
    <MetadataLayout {...props}>
          <textarea id={metadata.name}
                    onChange={onChange(setFormValues, formValues, metadata)}
                    value={((formValues[metadata.name] || {})["value"] || '')}
                    style={style}
                    // disabled={disabled} 
                    rows="4"
          />
    </MetadataLayout>
  )
}
