import React from "react";
import DynamicFormFieldFactory from "../dynamic-field-factory";
import {FormContext} from "../dynamic-form/dynamic-form-context";
import {TDI_STEPS} from "../../tdi/status";
import Grid from "@material-ui/core/Grid"

const MetadataFields = () =>{
    const {apiFormInfo} = React.useContext(FormContext);
    let b = JSON.parse(JSON.stringify((apiFormInfo.fields || [])));
    let fields = (b || []).filter((field) => field.method === TDI_STEPS.WAIT_FOR_METADATA);
    return (
        <Grid item xs={12} md={6} spacing={0} alignItems={'center'} style={{"flexFlow": "column"}}>
            <DynamicFormFieldFactory fields={(fields || [])} handleChange={()=>{}} />
        </Grid>
    )
};

export default MetadataFields;
