import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SaleType from '../sale-type';
import TextFilter from './table/filters/text';
import Status from '../status';
import Operacao from '../operacao'
import StatusFilter from './table/filters/status';
import PageView from '@material-ui/icons/Pageview';
import { PATH } from '../../../routes/path-list';
import { TableContext } from './table/context';
import {getDocs, getSaleTypeForm} from '../../../api/index'
import { Styles } from './table/style';
import Table from './table/table'
import OperationFilter from "./table/filters/operation-filter";
import { profileService } from 'services/profile-service';
import {getProfile} from "api/index"
import {
CONSULT_CONFIG,
TDI_CONFIG,
} from "../../../config"

export const generateSaleTypePayload = (packageInfo) => {
  const IDCOMBOS = Object.keys(packageInfo).filter((key) => {
    return key.match(/IDCOMBO/gi)
  });
  const CLIENTIDS = Object.keys(packageInfo).filter((key) => {
    return key.match(key.match(/CLIENTID/gi))
  });
  let payload = {
    USERID: packageInfo.USERID,
    ROLEID: packageInfo.ROLEID,
    CLIENTID: 1,
    SUBCLIENTID: 1,
  };
  
  IDCOMBOS.forEach((IDCOMBO) => {
    payload[IDCOMBO] = packageInfo[IDCOMBO]
  });
  CLIENTIDS.forEach((CLIENTID) => {
    payload[CLIENTID] = packageInfo[CLIENTID]
  });
  return payload

};

  const getIdCombos = (operacao) => {
    getDocs(operacao).then(data => {
        return data;
    });
 }


function ConsumerTable() {

  let history = useHistory();
  
  useEffect(() => {
    (async () => {
        let profileData = await getProfile();
        profileService.initI18n(profileData);
        profileService.setLocalization(profileData.localization);
        profileService.setProfile(profileData);
    })();
  }, []);

  const columns = React.useMemo(
    () =>[
      {
        Header: 'ID do Pacote',
        columns: [
          {
            accessor: 'id',
            Header: <TextFilter filterKey={'packageId'}/>,
          },
        ],
      },
      {
        Header: 'Operação',
        id: 'saleType',
        columns: [
          {
            id: 'saleType',
            filter: "",
            accessor: d => {
              let props = d;
              return <Operacao {...props} />
            },


            Header: "",
          },
        ],
      },
      {
        Header: 'Tipo Loja',
        columns: [
          {
            accessor: 'ROLEID',
            Header: "",
          },
        ],
      },
      {
        Header: 'Usuário de criação',
        columns: [
          {
            accessor: 'username',
            Header: "",
          },
        ],
      },
      {
        Header: 'CPF',
        columns: [
          {
            accessor: 'cpf',
            Header: <TextFilter filterKey={'CPF'}/>,
          },
        ],
      },
      {
        Header: 'CNPJ',
        columns: [
          {
            accessor: 'CPFCNPJ',
            Header: <TextFilter filterKey={'CPFCNPJ'}/>,
          },
        ],
      },
      {
        Header: 'MSISDN',
        columns: [
          {
            accessor: 'MSISDN',
            Header: <TextFilter filterKey={'MSISDN'}/>,
          },
        ],
      },
      {
        Header: 'Data de Criação',
        columns: [
          {
            accessor: 'creationDate',
            Header: "",
          },
        ],
      },
      {
        Header: 'Status',
        id: 'status',
        columns: [
          {
            id: 'status-filter-field',
            accessor: d => <Status status_name={d.status} showDescription={1}/>,
            Header: <StatusFilter/>,
          },
        ],
      },
      {
        Header: '',
        id: 'view',
        columns: [
          {
            id: 'view',
            accessor: d => <PageView color='primary' style={{cursor: 'pointer'}}
                                     onClick={() => {
                                       history.push({
                                         pathname: PATH.PACKAGE_DETAILS.replace(':packageId', d.id),
                                       })
                                     }}> </PageView>,
            Header: "",
          },
        ],
      },
    ],
  );

  const {data, setAccessed} = React.useContext(TableContext);
  React.useEffect(() => {
    setAccessed(true)
  });

  return (
    <Styles>
      <Table columns={columns} data={data} context={TableContext}/>
    </Styles>
  )
}

export default ConsumerTable;