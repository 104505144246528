import React from 'react'
import CorporateTable from './corporate-table'
import FilterChips from './table/filters/corporate-filters/chips'
import FilterDate from './table/filters/corporate-filters/date'
import FilterList from './table/filters/corporate-filters/list'
import Grid from '@material-ui/core/Grid';
import DownloadCorporateButtons from './table/footer/download-corporate-button';
import TextFilter from './table/filters/corporate-filters/text';
import StatusFilter from './table/filters/corporate-filters/status';

const filterList = [
  {
    "value": "CPFCNPJ",
    "label": "CNPJ",
    "component": <TextFilter label={"Valor"} filterKey={"CPFCNPJ"} button={1}/>,
  },
  {
    "value": "imei",
    "label": "IMEI",
    "component": <TextFilter label={"Valor"} filterKey={"imei"} button={1}/>,
  },
  {
    "value": "P2B",
    "label": "P2B",
    "component": <TextFilter label={"Valor"} filterKey={"P2B"} button={1}/>,
  },
  {
    "value": "RADAR",
    "label": "RADAR",
    "component": <TextFilter label={"Valor"} filterKey={"RADAR"} button={1}/>,
  },
  {
    "value": "userid",
    "label": "Usuário",
    "component": <TextFilter label={"Valor"} filterKey={"userid"} button={1}/>,
  },
    {
    "value": "N-PHOENIX",
    "label": "N-PHOENIX",
    "component": <TextFilter label={"Valor"} filterKey={"N-PHOENIX"} button={1}/>,
  },
    {
    "value": "CLASSE",
    "label": "CLASSE",
    "component": <TextFilter label={"Valor"} filterKey={"CLASSE"} button={1}/>,
  }
];

const CorporateConsultList = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        Data de criação:
      </Grid>
      <Grid item xs={12}>
        <FilterDate filterKey={'creationStart'} label={'De'}/>
        <FilterDate filterKey={'creationEnd'} label={'Até'}/>
      </Grid>
      <Grid item xs={12}>
        <FilterList filterList={filterList}/>
      </Grid>
      <Grid item xs={12}>
        <FilterChips/>
      </Grid>
      <Grid item xs={12} style={{ overflow: 'auto' }}>
        <CorporateTable/>
      </Grid>
      <Grid item xs={12}>
        <DownloadCorporateButtons/>
      </Grid>
    </Grid>
  )
};

export default CorporateConsultList;