import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "./table-pagination-actions";
import React from "react";

function Footer({context, isMesaThales}){
    const {page, pageSize, setPageSize, count} = React.useContext(context);
    const onChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value))
    };

    return (<TableFooter>
        <TableRow >
            <TablePagination
                style={{borderBottom:'none', display: 'flex', flexDirection: 'row', padding: 0}}
                rowsPerPageOptions={isMesaThales? [ 10, 20, 50, 100] : [5, 10, 20, 25, 50, 100]}
                colSpan={3}
                labelRowsPerPage="Linhas por página"
                count={count}
                rowsPerPage={pageSize}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onChangePage={()=>{}}
                onChangeRowsPerPage={onChangeRowsPerPage}
                ActionsComponent={() => <TablePaginationActions context={context}/>}
            />
        </TableRow>
    </TableFooter>)
}

export default Footer;
