import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: 500,
    },
});

export default function Error503() {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '90vh' }}
        >
            <Grid item xs={3}>
                <div className={classes.root}>
                    <Typography variant="h4" gutterBottom>
                        Serviço Temporariamente Indisponível
                    </Typography>
                    Lamentamos pela inconveniência
                </div>
            </Grid>

        </Grid>

    );
}


