import React, { useState, useEffect, useContext } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Form from "../../../../forms/dynamic-form/dynamic-form-view";
import { DataService } from "services/data-service";
import { take } from "rxjs/operators";
import { FormContextProvider } from "components/forms/dynamic-form/dynamic-form-context";
import { getSaleTypeForm } from "api";
import { TableContext } from "../context";
import { AppContext } from "components/app/context";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    paddingTop: "30px",
    flexFlow: "column",
    paddingBottom: "30px",
  },
}));

const OperationFilter = () => {

  const [show, setShow] = useState(false);
  const {addFilter, setPage} = React.useContext(TableContext);
  const {language, appmetadata} = useContext(AppContext);
  const classes = useStyles();
  
  const handleClick = () => {
    setShow(true);
  };
  
  const closeOperationFilter = () => {
    setShow(false);
  };

  const submit = () => {
    DataService.getPayloadToRetrieveFormInfo()
    .pipe(take(1))
    .subscribe((dropdownValues) => {
      setShow(false);
      addFilter('operation', dropdownValues);
      setPage(0)
    });
  };

  return (<Grid xs={12}>
      <Button onClick={handleClick} variant="contained" color="primary">
        Filtrar
      </Button>
      <Dialog open={show} style={{alignItems: 'center', zIndex: '10'}}
        onClose={closeOperationFilter}
      >
        <MuiDialogTitle>
          <Typography variant="h6">Operação Filtrar</Typography>
        </MuiDialogTitle>
        <MuiDialogContent>
          <FormContextProvider getFormInfo={getSaleTypeForm}>
            <Grid item xs={12} spacing={0}>
              <Form/>
            </Grid>
          </FormContextProvider>
        </MuiDialogContent>
        <Grid container item xs={12} alignItems={'center'} className={classes.buttonContainer}>
            <button style={{"width": "20%"}} className={"form-button form-button-primary"}
              onClick={submit}>{appmetadata.FORM.SUBMIT[language]}
            </button>
        </Grid>
      </Dialog>
  </Grid>
  )
}

export default OperationFilter;