import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormContextProvider } from '../dynamic-form/dynamic-form-context';
import { updateDocument, createDocument} from '../../../api';
import SubmitButton from '../dynamic-form/dynamic-form-submit-button';
import Form from '../dynamic-form/dynamic-form-view';
import { FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT } from '../../../variables/consult-info';
import { getDocument } from '../../../api';
import { ModalService } from '../../../services/modal-service';
import { NOTIFICATION_VARIANT, NotificationService } from '../../../services/notification-service';
import {base64Size} from '../../../utils';

async function getCorporateDocumentForm() {
  return {
    fields: [
      {
        'label': 'Documento',
        'name': 'documentName',
        'required': true,
        'type': 'autocomplete',
        'options': Object.keys(FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT)
          .map((key) => {
            return {[key]: FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[key]}
          }),
      },
      {
        'accept': '.*',
        'itemLimit': 4,
        'label': 'Arquivo',
        'name': 'documentFiles',
        'multiple': true,
        'required': true,
        'type': 'fileAndScanner'
      }
    ]
  };
}

const formatPayload = (payload) => {
  const docName = payload.documentName;
  const files = payload.documentFiles.map((file, index) => {
    return {fileType: 'Page'+ (index + 1), file: file}
  });

  return {docName, files}
};

const submit = (packageId, documentId, reload) => (payload) => {
  const formattedPayload = formatPayload(payload);

  if(documentId){
    updateDocument(packageId, documentId, formattedPayload).then(() => {
      ModalService.close();
      reload()
    }).catch((error) => {
      NotificationService.send(
        error.response.status + " Ocorreu algo inesperado. Tente novamente.",
        NOTIFICATION_VARIANT.ERROR
      );
      ModalService.close();
    })
  }
  else{
    createDocument(packageId, formattedPayload).then(() => {
      ModalService.close();
      reload()
    }).catch((error) => {
      NotificationService.send(
        error.response.status + " Ocorreu algo inesperado. Tente novamente.",
        NOTIFICATION_VARIANT.ERROR
      );
      ModalService.close();
    })
  }
};

const CorporateDocumentFormView = ({packageId, document, reload}) => {

  const [payload, setPayload] = React.useState({});

  React.useEffect(() => {
    if (document){
      Promise.all(document.files.map((file) => getDocument(packageId, file.fileId))
      ).then((responses) => {
        setPayload({
          documentName: {value:document.name, valid:1},
          documentFiles: {value:responses.map((res) =>
            {return {
              image: res.file,
              size: base64Size(res.file)
            }}
            ), valid:1}
        });
      });
    }
  }, [packageId, (document && document.documentId)]);

  return (
    <Grid container spacing={0}>
      <FormContextProvider getFormInfo={getCorporateDocumentForm} payload={payload}>
        <Grid container item xs={12} spacing={0} alignItems={'center'} style={{ 'flexFlow': 'column' }}>
          <Form/>
          <SubmitButton customSubmit={submit(packageId, (document && document.documentId), reload)}/>
        </Grid>
      </FormContextProvider>
    </Grid>
  );
};

export default CorporateDocumentFormView;

