export const TDI_STATUS = {
  RUNNING: "Running",
  WAITING: "Waiting",
  FINISHED: "Finished",
  SUCCESS: "Success",
  FAILURE: "Failure",
  ERROR: "Error",
};

export const TDI_STEPS = {
  WAIT_FOR_METADATA: "waitForMetadata",
};

export const METADATA_NAME = {
  PACKAGE_ID: "PACKAGE-ID",
};

const error_msj =  "Atenção! Não foi possível criar o pacote de documentos.\n";

export const CODE = {
  "359": "(Erro 359)\n" +
      "Não foi possível alterar o status do pacote. O prazo limite para alteração do status de um pacote é de 90 dias contados da data de criação.",
  "400": "Solicitação Inválida",
  "401": "Não Autorizado",
  "403": "(Erro 403)\n" +
      error_msj +
      "\n" +
      "Reveja as especificações aceitas para as imagens (disponíveis no FAQ), digitalize os documentos com uma resolução menor utilizando o scanner e finalize enviando o pacote com estas novas imagens.",
  "404": "Não Encontrado",
  "408": "Tempo de Solicitação Esgotado",
  "413": "(Erro 413)\n" +
      error_msj +
      "\n" +
      "Uma ou mais imagens enviadas estão muito grandes.\n" +
      "Reveja as especificações aceitas para as imagens (disponíveis no FAQ), digitalize os documentos com uma resolução menor utilizando um scanner e finalize enviando o pacote com estas novas imagens.",
  "500": "Erro do Servidor Interno",
  "503": "Serviço Indisponível",
  "4000": "\"(Erro 4000)\n" +
      error_msj +
      "\n" +
      "Por favor, tente novamente.\n",
  "4302": "(Erro 4302)\n" +
      error_msj +
      "\n" +
      "O CPF do documento apresentado é ilegível. Solicite ao cliente um documento com CPF legível ou consulte o CPF no site da Receita Federal e envie novamente o pacote para validação.",
  "4301": "Este pacote NÃO PODE ser atualizado",
  "4303": "Pacote não criado - CPF divergente",
  "4304": "Pacote não criado - ID pessoal sem foto",
  "4501": "(Erro 4501)\n" +
      "Atenção, esta ação não é permitida! \n" +
      "\n" +
      "Já existe na base um pacote criado com o mesmo dado podendo ser um dos seguintes campos: Telefone, CPF, Tipo de Venda ou Data de Ativação.",
  "5502": "Falha de comunicação com o servidor",
  "5503": "Usuário sem permissão de atualizar o pacote",
};
