import React, {useContext} from "react";
import IconButton from '@material-ui/core/IconButton';
import {keycloakService} from "../../../services/keycloak-service";
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Tooltip from "@material-ui/core/Tooltip";
import {AppContext} from "../../app/context";
import {useStyles} from "./icons-style";


export default () => {
    const {language,appmetadata} = useContext(AppContext);
    const classes = useStyles();

    return (<Tooltip title={appmetadata.HEADER.LOGOUT[language]}>
        <IconButton
            aria-label="help"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => keycloakService.logout()}
            id='exit'
            color="inherit"
            className={classes.icon}
        >
            <ExitToAppIcon/>
        </IconButton>
    </Tooltip>)
}