import * as KJUR from 'jsrsasign';
import { getByteArrayFromHexString, byteTobuffer, fromDer, derToOid, getbytesToHex, getHexToBytes, applyPaddingDecipher } from './dep.commons';
import { KEY_DERIVATION_OPTIONS, FRAME_DIRECTION, BLOCK_LENGTH } from './dep.constants';
import { DEPCrypto } from './dep-crypto';
import { DEPResponse } from '../models/dep-response';
import { ResponseHeader } from '../models/response-header';
import { DEPVersion } from '../models/dep-version';
import { EncryptedContentInfo } from '../models/encrypted-content-info';
import { Body } from '../models/body';
import { DEPStatus } from '../models/dep-status';
import { NO_ERROR } from './dep-status-codes';

export class DEPDecoder {

    decodeResponse(encodedResponse) {

        const buffer = byteTobuffer(encodedResponse);
        const ASN1object = fromDer(buffer);
        return this.parseASN1Response(ASN1object);
    }

    decryptBody(session, body){

        const deriveDataKey = new DEPCrypto().deriveSecretKey(session, KEY_DERIVATION_OPTIONS.DATA_KEY,
            FRAME_DIRECTION.RESPONSE);
        const temp = applyPaddingDecipher(getbytesToHex(body.EncryptedContent.EncryptedContent), BLOCK_LENGTH);
        const encryptedContent = (getHexToBytes(temp));
        const octetString = new DEPCrypto().decryptContentCryptoJS(deriveDataKey, encryptedContent);

        const octetStringBuffer = byteTobuffer(octetString);
        const content = fromDer(octetStringBuffer);

        try {
            return JSON.parse(content.value);
        }
        catch(e) {
            return content.value;
        }
    }

    parseASN1Response(ASN1object){

        const responseHeaderASN1 = ASN1object.value[0].value[0];

        // ResponseHeader
        const responseHeader= new ResponseHeader(
            new DEPVersion(parseInt(KJUR.utf8tohex(responseHeaderASN1.value[0].value), 16)),
            new Int8Array(getByteArrayFromHexString(KJUR.utf8tohex(responseHeaderASN1.value[1].value))),
            new DEPStatus(parseInt(KJUR.utf8tohex(responseHeaderASN1.value[2].value),16))
        );

        if( NO_ERROR !== responseHeader.StatusCode.Code) {
            //console.log(responseHeader.StatusCode.Code+" - "+DEP_RESPONSE_MAP[responseHeader.StatusCode.Code.toString()]);
            return new DEPResponse(responseHeader, null, null);
        }

        const responseBodyASN1 = ASN1object.value[0].value[1].value[0];
        const bodyEncryptedContentInfoASN1 = responseBodyASN1.value[1];
        const responseMacASN1 = ASN1object.value[0].value[2];
        // EncryptedContentInfo
        const encryptedContentInfo = new EncryptedContentInfo(
            derToOid(bodyEncryptedContentInfoASN1.value[0].value),
            derToOid(bodyEncryptedContentInfoASN1.value[1].value[0].value),
            bodyEncryptedContentInfoASN1.value[2].value
        );
        // Body
        const body = new Body(responseBodyASN1.value[0].value, encryptedContentInfo);
        //  MAC
        const mac = responseMacASN1.value[0].value;
        return new DEPResponse(responseHeader, body, mac);
    }

}
