import React from "react";
import Table from "../../../material-ui/table";
import { decodeImportedData, sortKeysIgnoreCase } from "../../../../utils";
import { PackageContext } from "../package-context";

export const CreateFullDetailList = (_package) => {
    return Object.keys(((_package || {}).info || {}))
        .map((key) => {
            let decodedValue = _package.info[key];
            if (typeof decodedValue === 'string' || decodedValue instanceof String)
                decodedValue = decodeImportedData(_package.info[key]);
            return {
                name: key,
                value: [
                    key,
                    decodedValue
                ]
            }
        });
};

export const FullDetailsTab = () => {
    const { _package } = React.useContext(PackageContext);
    const detail_list = CreateFullDetailList(_package);
    detail_list.sort((a, b) => a.value[0].localeCompare(b.value[0], 'fr', { ignorePunctuation: true }));
    return <Table rows={detail_list} />
};

export default FullDetailsTab;
