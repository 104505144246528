import * as KJUR from 'jsrsasign';
import * as CryptoJS from 'crypto-js';
import { OIDs } from './dep.oids';
import { str2ab, getByteArrayFromHexString, getbytesToHex, byteTobuffer, getPublicKeyFingerprint,
    getCipherInstance, getDecipherInstance, getHexToBytes } from './dep.commons';
import { UPDATE_HEX, DEFAULT_IV, ASYMMETRIC_CRYPTO_ALGORITHM, ASYMMETRIC_CRYPTO_ALGORITHM_HASH,
    HMAC_SHA_256, KEY_DERIVATION_OPTIONS, BLOCK_LENGTH, AES_CBC } from './dep.constants';
import { SessionKeys } from '../models/session-keys';
import { EncryptedContentInfo } from '../models/encrypted-content-info';

export class DEPCrypto {

    async encryptKeys(dataToBeEncrypted, publicKey) {

        const cryptoSubtle = crypto.subtle;
        const binaryDerString = str2ab(atob(publicKey));

        const cryptoPublicKey =  await cryptoSubtle.importKey(
            "spki",
            binaryDerString,
            {
              name: ASYMMETRIC_CRYPTO_ALGORITHM,
              hash: ASYMMETRIC_CRYPTO_ALGORITHM_HASH
            },
            true,
            ["encrypt"]
        ).then( (key) => key);

        const encryptedKeys = await cryptoSubtle.encrypt({
                name: ASYMMETRIC_CRYPTO_ALGORITHM,
            },
            cryptoPublicKey,
            dataToBeEncrypted
        ).then( (buffer) => new Int8Array(buffer));

        return encryptedKeys;
    }

    createSessionKeys(encryptedKeys,publicKey) {

        const encryptedKeysInfo = new EncryptedContentInfo(OIDs.GEMALTO_ID_KEYS, OIDs.RSAOAEP, encryptedKeys);
        const subjectKeyIdentifier = getPublicKeyFingerprint(
            KJUR.KEYUTIL.getKey(`-----BEGIN PUBLIC KEY-----${publicKey}-----END PUBLIC KEY-----`)
        );
        return new SessionKeys(subjectKeyIdentifier,encryptedKeysInfo);
    }

    deriveSecretKey(session, keyOption, frameOption) {

        const origin_key = getbytesToHex((keyOption === KEY_DERIVATION_OPTIONS.DATA_KEY) ? session.DataKey : session.MacKey);
        const MAC = new KJUR.crypto.Mac({alg: HMAC_SHA_256, "pass": {"hex":  origin_key}});
        MAC.updateHex(keyOption);
        MAC.updateHex(frameOption);
        MAC.updateHex(UPDATE_HEX);
        MAC.updateHex(getbytesToHex(session.SessionId));
        const output = MAC.doFinalHex(getbytesToHex(session.SequenceNumber));
        return output.substring(0,BLOCK_LENGTH*2);
    }

    encryptContent(key,dataToBeEncrypted) {

        const iv = getByteArrayFromHexString(DEFAULT_IV);
        const cipher = getCipherInstance(AES_CBC, key);
        cipher.start({ iv: byteTobuffer(iv) });
        cipher.update(byteTobuffer(dataToBeEncrypted));
        cipher.finish();
        return cipher.output.data;
    }

    decryptContent(key, dataToBeDecrypted) {

        const iv = getByteArrayFromHexString(DEFAULT_IV);
        const decipher = getDecipherInstance(AES_CBC, byteTobuffer(key));
        decipher.start({iv: byteTobuffer(iv)});
        decipher.update(dataToBeDecrypted);
        decipher.finish();
        return decipher.output.toHex();
    }

    decryptContentCryptoJS(deriveDatakey, dataToBeDecrypted) {

        const key = CryptoJS.enc.Hex.parse(deriveDatakey);
        const iv = CryptoJS.enc.Hex.parse(DEFAULT_IV);
        const cipheredText = CryptoJS.enc.Hex.parse(getbytesToHex(dataToBeDecrypted));
        const aesDecryptor = CryptoJS.algo.AES.createDecryptor(key, {
             iv,
             mode: CryptoJS.mode.CBC,
             padding: CryptoJS.pad.NoPadding
        });
        const decryptedWordArray = aesDecryptor.process(cipheredText);
        const decryptedHex = CryptoJS.enc.Hex.stringify(decryptedWordArray);
        return getHexToBytes(decryptedHex);
    }

    calculateMac(macKey,macInput) {

        const mac = new KJUR.crypto.Mac({alg: HMAC_SHA_256, "pass": {"hex":  macKey}});
        return mac.doFinalHex(macInput);
    }
}
