import React from 'react';
import { Route } from "react-router-dom";
import WebCaptureLayout from '../components/layout/web-capture-layout'


export default function WebCaptureLayoutRoute({component: Component, ...rest}) {
    return (
        <Route {...rest} render={matchProps => (
            <WebCaptureLayout>
                <Component {...matchProps} />
            </WebCaptureLayout>
        )
        } />
    )
};