import React from "react";
import Input from "./form-input-redux-dropdown-view"
import MetadataLayout from "../form-input/layout-metadata"


export default function ReduxDropdown(props){
    return (<MetadataLayout {...props}>
            <Input {...props}/>
        </MetadataLayout>
    )
}