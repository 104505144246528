import React, {useContext} from "react";
import {FormContext} from "./dynamic-form-context";
import Grid from "@material-ui/core/Grid";
import {AppContext} from "../../app/context";
import { isBase64 } from "../../../utils";
import {addFields} from '../dynamic-field-factory/dynamic-field-factory-component-list';

const validateForm = (formValues, apiFormInfo) => {
    let requiredFields = apiFormInfo.fields
      .filter((field) => {
          return field.required && field.type !== addFields
      })
      .map((field) => field.name);
    const addedFields = apiFormInfo.fields
      .filter((field) => field.required && field.type === addFields)
      .map((field) => field.fields
        .filter((field) => field.required)
        .map((field) => field.name+1)).flat();
    Array.prototype.push.apply(requiredFields, addedFields);
    if (Object.keys(formValues).every((key) => formValues[key]['valid'])){
        return requiredFields.every(function(item) {
            return Object.keys(formValues).indexOf(item) !== -1;
        });
    }
    else{
        return false;
    }
};

const createPayload = (formValues) =>{
    let payload = {};
    Object.keys(formValues).forEach((key) =>{
        let value = formValues[key]['value'];
        payload[key] = value;
        if(Array.isArray(value)) {
            let list = [];
            let isChangeRequired = false;
            value.forEach( (key) =>{
                if(key && key.hasOwnProperty('image') && isBase64(key.image)){
                    isChangeRequired = true;
                    list.push(key.image);
                }
            });

            if(isChangeRequired) {
                payload[key] = list;
            }
        }
    });
    return payload;
};

const submit = (setValidationStyleOn, customSubmit, formValues, apiFormInfo) => {
    setValidationStyleOn();
    if (validateForm(formValues, apiFormInfo)){
        customSubmit(createPayload(formValues), apiFormInfo);
    }
};

const SubmitButton = ({customSubmit, text}) =>{
    const { apiFormInfo, setValidationStyleOn, formValues } = useContext(FormContext);
    const {language,appmetadata} = useContext(AppContext);
    return (
        <Grid container item xs={12} spacing={3} alignItems={'center'} style={{"paddingTop": "30px", "flexFlow": "column", "paddingBottom": "30px",}}>
            <button className={"form-button form-button-primary"}
                    style={{"width": "20%"}}
                    onClick={()=> {submit(setValidationStyleOn, customSubmit, formValues, apiFormInfo)}}>
                {text? text : appmetadata.FORM.SUBMIT[language]}
            </button>
        </Grid>
    )
};

export default SubmitButton;