import React from "react";
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../button';
import {BUTTON_STYLE} from '../../button';
import Grid from '@material-ui/core/Grid'
import 'react-circular-progressbar/dist/styles.css';
import '../consult-styles.css';
import {PATH} from "../../../routes/path-list";
import Tabs from "../../material-ui/tab/forced-scroll"
import UpdatePackageButton from "./update-package-button"
import { AVAILABLE_TABS, } from "./tab-list";
import { PackageContextProvider } from "./package-context";

export const PackageDetail = () => {
    let history = useHistory();
    let location = useLocation();

    const tabs = AVAILABLE_TABS[location.pathname.split('/')[2]];

    return <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                <Tabs tabs={tabs} />
                <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <UpdatePackageButton/>
                    </Grid>
                    <Grid item>
                        <Button variant={BUTTON_STYLE.DEFAULT_PRIMARY} onClick={() => {history.push(PATH.HOME)}}> Home </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

};

export const PackageDetailContextWrapped = () => {
    return <PackageContextProvider>
        <PackageDetail/>
    </PackageContextProvider>
};

export default PackageDetailContextWrapped;