import {keycloakService} from "./services/keycloak-service";
import {UserService} from "./services/user-service";
import {LANGUAGE} from "./variables/display-strings";
import {AVAILABLE_THEMES} from "./variables/themes";
import {Helmet} from "react-helmet";
import React from "react";

export const ENV = process.env.REACT_APP_ENV;

export const VARIABLE = require(
    ENV === 'production' ?  './variables/environment/production' :
        ENV === 'staging' ? './variables/environment/staging' :
            ENV === 'development-build' ? './variables/environment/development-build' :
                ENV === 'development' ? './variables/environment/development' :
                    './variables/environment/demo'
);

export const firebaseConfig = VARIABLE.firebaseConfig;

export const depEnabled = VARIABLE.depEnabled;

export const keycloakRedirectLogin = VARIABLE.keycloakRedirectLogin;

export const authRequired = VARIABLE.authRequired;

export const userPermissionRequired = VARIABLE.userPermissionRequired;

export const documentFormRedirect = VARIABLE.documentFormRedirect;

export const fcmVapKey = VARIABLE.fcmVapKey;

export const fcmApiKey = VARIABLE.fcmApiKey;

export const keycloakJsonConfigFile = VARIABLE.keycloakJsonConfigFile;

export const URI_SUFFIX = VARIABLE.URI_SUFFIX;

export const DOC_MATRIX_CONFIG = VARIABLE.DOC_MATRIX;

export const SELF_DOCS_CONFIG = VARIABLE.SELF_DOCS;

export const TDI_CONFIG = VARIABLE.TDI;

export const KEYCLOAK_CONFIG = VARIABLE.KEYCLOAK;

export const CONSULT_CONFIG = VARIABLE.CONSULT;

export const PERSIST_CONFIG = VARIABLE.PERSIST;

export const PROFILE_CONFIG = VARIABLE.PROFILE;

export const PERSIST_CA = VARIABLE.persistTlsCa;

export const PERSIST_CERT = VARIABLE.persistTlsCert;

export const PERSIST_KEY = VARIABLE.persistTlsKey;

export const DYNAMSOFT_RESOURCES_URI = VARIABLE.DYNAMSOFT_RESOURCES_URI;

export const DEFAULT_LANGUAGE = LANGUAGE.PT_BR;

export const idletimeoutBreakEvents = VARIABLE.idletimeoutBreakEvents;

export const idletimeoutInMillis = VARIABLE.idletimeoutInMillis;

export const idletimeoutCheckInMillis = VARIABLE.idletimeoutCheckInMillis;

export const tdiRetryDelayinMillis = VARIABLE.tdiRetryDelayinMillis;

export const tdiPatchStepDelayinMillis = VARIABLE.tdiPatchStepDelayinMillis;

export const THEME = VARIABLE.THEME;

export const HTML_HEADS = {
    [AVAILABLE_THEMES.TIM]: (<Helmet>
        <link rel="shortcut icon" href={process.env.PUBLIC_URL + "/favicontim.ico"} />
        <title>DNB - Gerenciamento de Docs</title>
    </Helmet>),
    [AVAILABLE_THEMES.THALES]:(<Helmet>
        <link rel="shortcut icon" href={process.env.PUBLIC_URL + "/faviconthales.ico"} />
        <title>DNB - Gerenciamento de Docs</title>
    </Helmet>),
};

export const THEME_CONFIG = {
    [AVAILABLE_THEMES.TIM]: {
        HEADER_BACKGROUND_COLOR: '#004691',
        HEADER_ICON_BACKGROUND_COLOR: '#88c4ff',
        LOGO: require('./assets/timlogo.png'),
        ROUTE_BASENAME: ''
    },
    [AVAILABLE_THEMES.THALES]: {
        HEADER_BACKGROUND_COLOR: 'linear-gradient(left, #2b286d 1%,#2b286d 44%,#5cbfd4 100%)',
        HEADER_ICON_BACKGROUND_COLOR: '#2B286D',
        LOGO: require('./assets/thaleslogo.png'),
        ROUTE_BASENAME: '/demo'
    }
};

export const APP_METADATA_ENDPOINT = "/application-strings-pt.json";

export const publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgcOvsnr7RVyzSc7vKW5f8qvYPbKbHb/WFIg+pl+W2tVB5tb14Z4prbCIklck+0ZULhnF5Vy48dXCEpYjD8DUfY3HOWUawfeHNfgpRRy6rdgT9cMKU7Cwy9xvDr9Eul4CThPdQ9HzxFvVNSUooiGkdsszjBeazB9lF5NHFG+gmDCNMDEumtZO/XLzwrraobUENs4T3Ntlg8g/X4W32d1rySP2qGZrzRP6QWuv/Yym8w6a5V3FlLsVq2Bqt8nuM0CeWGsY8ebcJdoRpNRZtVgrFe0wx18qXUCtTwbaoIPNc+0+UAudOZMT2RXJn1zlXUT75hFU/zGQetPTCDhHvtJt6wIDAQAB";

export const DYNAMSOFT_LICENSE_KEY = VARIABLE.DYNAMSOFT_LICENSE_KEY;

export const FILESIZE_THRESHHOLD_SUM = VARIABLE.FILESIZE_THRESHHOLD_SUM;

export const MIN_FILESIZE_EACH = VARIABLE.MIN_FILESIZE_EACH;

export const exportCsvHeaderValues = VARIABLE.exportCsvHeaderValues;