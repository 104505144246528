import { Subject, BehaviorSubject } from "rxjs";

const $message = new Subject();
const $token = new BehaviorSubject("");
const $refresh = new Subject();

export const fcmService = {
    sendFirebaseCloudMessage: (message) => $message.next(message),
    getFirebaseCloudMessage: () => $message,
    setFirebaseCloudMessageToken: (token) => $token.next(token),
    getFirebaseCloudMessageToken: () => $token,
    refreshFirebaseCloudMessageToken: () => $refresh.next(true),
    refreshFirebaseCloudMessageTokenSignal: () => $refresh,
};