import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


const DeleteModal = ({callback, show, id}) => {
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => callback(false)}
            style={{zIndex: 1500}}
        >
            <Modal.Header closeButton>
                <Modal.Title>Deletar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Tem certeza que deseja deletar?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => callback(false)}>Cancelar</Button>
                <Button variant="danger" onClick={() => callback(true, id)}>Deletar</Button>
            </Modal.Footer>
        </Modal>)
};

export default DeleteModal;



