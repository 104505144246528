import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from './title';
import DialogContent from './content';
import DialogActions from './actions';
import { ModalService } from '../../../services/modal-service';

export default function MaxWidthDialog({ state }) {

  return (
    <Dialog
      fullWidth={true}
      open={state.show}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth={state.maxWidth}
    >
      <DialogTitle id="simple-dialog-title"
                   onClose={state.closeButton ? ModalService.close : null}
      >
        {state.title}
      </DialogTitle>
      <DialogContent
        text={state.text}
        spinner={state.spinner}
        textBottom={state.textBottom}>
      </DialogContent>
      {state.buttons ? <DialogActions>{state.buttons}</DialogActions> : null}
    </Dialog>
  );
}
