import React from "react";

const disbled_style = {
  border: "1px solid #B5B5B5",
  height: "32px",
  width: "100%",
  'border-radius': '2px',
  'margin-bottom': '20px',
  overflow: 'hidden',
  'text-overflow': 'ellipsis',
  'background-color': 'rgba(239, 239, 239, 0.3)',
  'text-align': '-webkit-left',
  'padding-left': '10px',
};

export default function DisabledComponentField({metadata}){
  return <div style={disbled_style}>{metadata.component}</div>
}
