import React from 'react'
import PrepaidTable from './prepaid-table'
import FilterChips from './table/filters/prepaid-filters/chips'
import FilterDate from './table/filters/prepaid-filters/date'
import FilterList from './table/filters/prepaid-filters/list'
import Grid from '@material-ui/core/Grid';
import DownloadButtons from './table/footer/download-prepaid-button';
import TextFilter from './table/filters/prepaid-filters/text';
import StatusFilter from './table/filters/prepaid-filters/status';

const filterList = [
  {
    "value": "cpf",
    "label": "CPF",
    "component": <TextFilter label={"Valor"} filterKey={"cpf"} button={1}/>,
  },
];

const PrepaidConsultList = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        Data de criação:
      </Grid>
      <Grid item xs={12}>
        <FilterDate filterKey={'creationStart'} label={'De'}/>
        <FilterDate filterKey={'creationEnd'} label={'Até'}/>
      </Grid>
      <Grid item xs={12}>
        <FilterList filterList={filterList}/>
      </Grid>
      <Grid item xs={12}>
        <FilterChips/>
      </Grid>
      <Grid item xs={12} style={{ overflow: 'auto' }}>
        <PrepaidTable/>
      </Grid>
    </Grid>
  )
};

export default PrepaidConsultList
