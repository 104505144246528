import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Typography from '@material-ui/core/Typography';
import React from "react";


function Header({headerGroups}){

    return (<TableHead>
        {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps()} className="th">
                        <Typography variant={'h7'}>
                        {column.render('Header')}
                        {/* Use column.getResizerProps to hook up the events correctly */}
                        <div
                            {...column.getResizerProps()}
                            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                        />
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        ))}
    </TableHead>)
}

export default Header;
