import { DEPClientRequest } from '../utils/dep-client-request';
import { DEPClientResponse } from '../utils/dep-client-response';
import { Session } from '../models/session';
import { toDer, fromDer, getbytesToHex, getRandomByteArray, byteTobuffer, getByteArrayFromHexString } from '../utils/dep.commons';
import { SEQUENCE_NUMBER_LENGTH, SESSION_ID_LENGTH, SECRET_KEY_LENGTH } from '../utils/dep.constants';

export class DEPClientService {

    session;
    depClientRequest;
    depClientResponse;

    constructor() {
      this.session = this.createSession();
      this.depClientRequest = new DEPClientRequest();
      this.depClientResponse = new DEPClientResponse();
    }

    createSession() {

        const sessionId = getRandomByteArray(SESSION_ID_LENGTH);
        const sequenceNumber = byteTobuffer(new Int8Array(SEQUENCE_NUMBER_LENGTH)).data;
        const dataKey = getRandomByteArray(SECRET_KEY_LENGTH);
        const macKey = getRandomByteArray(SECRET_KEY_LENGTH);
        return new Session(sessionId, sequenceNumber, dataKey, macKey);
    }

    // method to modify object to make it compatible with EncryptedContentInfo.
    modificationForEncryptedContentInfo(object) {

        // for session keys
        object.value[0].value[1].value[1].value[2] = object.value[0].value[1].value[1].value[2].value[0];
        object.value[0].value[1].value[1].value[2].tagClass = 128;
        object.value[0].value[1].value[1].value[2].type = 0;

        // for request body
        object.value[0].value[2].value[1].value[2] = object.value[0].value[2].value[1].value[2].value[0];
        object.value[0].value[2].value[1].value[2].tagClass = 128;
        object.value[0].value[2].value[1].value[2].type = 0;
    }


    async getRequest(content, publicKey) {

        // encoded dep request hex string
        const encodedDEPRequest =  await this.depClientRequest.createDEPRequest(content,this.session,publicKey
            ).then( (encodedRequest) => encodedRequest);
        const requestBuffer = byteTobuffer(getByteArrayFromHexString(encodedDEPRequest));
        // converting into asn1 object to make modifications for EncryptedContentInfo object of bouncy castle.
        const requestASN1Obj  = fromDer(requestBuffer);
        this.modificationForEncryptedContentInfo(requestASN1Obj);
        return getByteArrayFromHexString(getbytesToHex(toDer(requestASN1Obj).getBytes()));
    }

    getResponse(responseBuffer) {

        return this.depClientResponse.createDEPResponse(responseBuffer,this.session);
    }

    // updates the sequence number for next request. Can be used for more after response tasks in the future.
    close() {
        this.session.increaseSequenceNumber();
    }
}

// function f() {
//     return 1;
// }

// async function fun() {
//     var x = await f();
//     return x;
// }

// fun().then(function(value){alert(value)});