import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {getStatusColor} from '../../../../../api';
import {TableContext} from '../context';
import Status from '../../../status';

function StatusFilter (){

  const [statusList, setStatusList] = React.useState([]);
  const {filters, addFilter, setPage} = React.useContext(TableContext);

  React.useEffect(() => {
    getStatusColor('any').then((response) => {
      setStatusList(response.data);
    })
  }, []);

  const handleChange = (event) => {
      addFilter('status', event.target.value);
      setPage(0)
  };

  return (<TextField
    id="status-filter"
    select
    label="Selecione"
    value={filters.status}
    onChange={handleChange}
    fullWidth={1}
  >
    {statusList.map((status) => (
      <MenuItem key={status.value} value={status.status}>
        <Status status={status} showDescription={1}/>
      </MenuItem>
    ))}
  </TextField>)
}

export default StatusFilter;