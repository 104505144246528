import { ABILITY } from '../../variables/roles-permissions';
import { PATH } from '../../routes/path-list';
import PostAddIcon from '@material-ui/icons/AddBox';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import WorkIcon from '@material-ui/icons/Work'
import BackupIcon from '@material-ui/icons/Backup'
import RouterIcon from '@material-ui/icons/Router';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

export function createCardList(appmetadata, language){
  return [
    {
      icon: <PostAddIcon style={{"fontSize": "9.5rem"}}/>,
      ability: ABILITY.CREATE_PACKAGE,
      path: PATH.SALE_TYPE_FORM,
      key: "package",
      title: appmetadata.HOME_PAGE.PACKAGE_CARD.TITLE[language],
      description: appmetadata.HOME_PAGE.PACKAGE_CARD.DESCRIPTION[language],
    },
    {
      icon: <SearchIcon style={{"fontSize": "9.5rem"}}/>,
      ability: ABILITY.READ_CONSUMER_PACKAGES,
      path: PATH.PACKAGE,
      key: "search",
      title: appmetadata.HOME_PAGE.SEARCH_CARD.TITLE[language],
      description: appmetadata.HOME_PAGE.SEARCH_CARD.DESCRIPTION[language],
    },
    
    {
      icon: <ImportantDevicesIcon style={{"fontSize": "9.5rem"}}/>,
      ability: ABILITY.READ_PREPAID_PACKAGES,
      path: PATH.PREPAID_PACKAGE,
      key: "prepaid",
      title: appmetadata.HOME_PAGE.PREPAID_SEARCH_CARD.TITLE[language],
      description: appmetadata.HOME_PAGE.PREPAID_SEARCH_CARD.DESCRIPTION[language],
    },
    {
      icon: <RouterIcon style={{"fontSize": "9.5rem"}}/>,
      ability: ABILITY.READ_TIMLIVE_PACKAGES,
      path: PATH.TIMLIVE_PACKAGE,
      key: "timlive",
      title: appmetadata.HOME_PAGE.TIMLIVE_SEARCH_CARD.TITLE[language],
      description: appmetadata.HOME_PAGE.TIMLIVE_SEARCH_CARD.DESCRIPTION[language],
    },
    {
      icon: <WorkIcon style={{"fontSize": "9.5rem"}}/>,
      ability: ABILITY.READ_CORPORATE_PACKAGES,
      path: PATH.CORPORATE_PACKAGE,
      key: "corporate",
      title: appmetadata.HOME_PAGE.CORPORATE_SEARCH_CARD.TITLE[language],
      description: appmetadata.HOME_PAGE.CORPORATE_SEARCH_CARD.DESCRIPTION[language],
    },
    {
      icon: <VerifiedUserIcon style={{"fontSize": "9.5rem"}}/>,
      ability: ABILITY.READ_MESATHALES_PACKAGES,
      path: PATH.MESATHALES_PACKAGE,
      key: "mesathales",
      title: appmetadata.HOME_PAGE.MESATHALES_SEARCH_CARD.TITLE[language],
      description: appmetadata.HOME_PAGE.MESATHALES_SEARCH_CARD.DESCRIPTION[language],
    }
  ]
}

