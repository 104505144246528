
export function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
        return ele !== value;
    });
}

export function format(strings) {
    return function (...vals) {
        return strings.map(function (s, i) {
            return `${s}${vals[i] || ""}`;
        }).join("");
    };
}

export function isBase64(str) {
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export function sanitizeAlphanumeric(string) {
    return string.toLowerCase().replace(/[^a-z0-9]/gi, '');
}

export function sleep(ms) {
    var now = new Date().getTime();
    while ( new Date().getTime() < now + ms ){
        break;
    }
}

export function polling(maxTimeLimitSeconds, _function, promise) {
    let received = false;
    for (let i = 0; i < maxTimeLimitSeconds * 2; i++) {
        sleep(500);
        if (_function) {
            received = _function();
        }
        else {
            received = promise().then((response) => response)
        }
        if (received) {
            return true;
        }
    }
    return false;
}

export const promiseTimeout = function (ms, promise) {
    let timeout = new Promise((resolve, reject) => {
        let id = setTimeout(() => {
            clearTimeout(id);
            reject('Timed out in ' + ms + 'ms.')
        }, ms)
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ])
};

export function isArrayEmpty(array) {
    return array && array.constructor === Array && array.length === 0
}

export function addBase64Extension(encoded) {
    let extension = guessImageMime(encoded);
    return "data:" + extension + ";base64," + encoded;
}

export function guessImageMime(data) {
    const signatures = {
        zip: "UEsDBB",
        tiff: "TU0AK",
        tiff_2: "SUkqA",
        png: "iVBORw0",
        jpeg: "/9j/",
        jpeg_2: "_9j_",
        gif: "R0lGODlh",
        mp3: "SUQz",
        pdf: "JVBER"
    };

    if (data) {
        if (data.substring(0, 5) === "/+NIx") {
            return "audio/mp3";
        } else if (data.substring(0, 6) === signatures.zip) {
            return "application/zip";
        } else if (data.substring(0, 4) === signatures.mp3) {
            return "audio/mp3";
        } else if (data.substring(0, 5) === signatures.pdf) {
            return "application/pdf";
        } else if (data.substring(0, 7) === signatures.png) {
            return "image/png";
        } else if (data.substring(0, 5) === signatures.tiff) {
            return "image/tiff";
        } else if (data.substring(0, 5) === signatures.tiff_2) {
            return "image/tiff";
        } else if (data.substring(0, 4) === signatures.jpeg) {
            return "image/jpeg";
        } else if (data.substring(0, 4) === signatures.jpeg_2) {
            return "image/jpeg";
        } else if (data.substring(0, 8) === signatures.gif) {
            return "image/gif";
        }
    }
    return ''
}

/*function typeOf( obj ) {
    return ({}).toString.call( obj ).match(/\s(\w+)/)[1].toLowerCase();
}*/

/*function checkTypes( args, types ) {
    args = [].slice.call(args);
    for (var i = 0; i < types.length; ++i) {
        if (typeOf(args[i]) !== types[i]) {
            throw new TypeError('param ' + i + ' must be of type ' + types[i]);
        }
    }
}*/

export const isPlainObject = function (obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

export function base64Size(base64String) {
    if (isString(base64String)) {
        return (base64String.length * (3 / 4)) - 1
    }
    return 0;

}


export function TestaCPF(strCPF) {
    let Soma;
    let Resto;
    Soma = 0;
    strCPF = (strCPF || '');
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}


/**
 * @return {boolean}
 */
export function TestaCNPJ(cnpj) {

    if (!cnpj) {
        return false
    }

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14)
        return false;


    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;


    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;

}

export const substringWithNOccurrencesOfCharacter = (inputString, character, nOccurrences) => {
    return inputString.slice(0, inputString.split(character, nOccurrences).join(character).length + 1);
};

export function longestString(str_ara) {
    let max = str_ara[0].length;
    str_ara.map(v => max = Math.max(max, v.length));
    return str_ara.filter(v => v.length === max)[0];
}

export function closestSizeString(str_ara, size) {
    let filtered_string_array = str_ara.filter(v => (v.split('#').length - 1) - size >= 0)
    let closest = filtered_string_array[0];
    filtered_string_array
        .map(v => closest = (closest.split('#').length - 1) - size > (v.split('#').length - 1) - size ? v : closest);
    return closest;
}

export function isObject(obj) {
    const type = typeof obj;
    return (type === 'function' || type === 'object') && !!obj;
}

export function cloneObject(src) {
    let target = {};
    for (let prop in src) {
        if (src.hasOwnProperty(prop)) {
            // if the value is a nested object, recursively copy all it's properties
            if (isObject(src[prop])) {
                target[prop] = cloneObject(src[prop]);
            } else {
                target[prop] = src[prop];
            }
        }
    }
    return target;
}

export const decodeImportedData = (data) => {
    const dataBuffer = Buffer.from(data, 'latin1');
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();
    const encodedData = encoder.encode(dataBuffer);
    const decodedData = decoder.decode(encodedData);
    return decodedData;
}

export const addExtensionToFileName = (href, filename) => {
    let firstChar = href.indexOf('/') + 1
    let lastChar = href.indexOf(';')
    let extension = href.slice(firstChar, lastChar)
    extension = '.' + extension
    let newFilename = filename + extension
    return newFilename
}

export const downloadFile = (href, filename) => {
    let newFilename = addExtensionToFileName(href, filename)
    let hiddenElement = document.createElement('a');
    hiddenElement.setAttribute('href', href);
    hiddenElement.setAttribute('download', newFilename);
    hiddenElement.style.display = 'none';
    hiddenElement.click();
};

export const deepCompare = (a, b) => {
    if (a === b) return true;

};

export const sortKeysIgnoreCase = (keys) => keys.sort((a, b) => a.value[0].toLowerCase() < b.value[0].toLowerCase() ? -1 : 0);