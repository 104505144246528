export const PHASE_1 = "Fase 1";
export const PHASE_2 = "Fase 2";
export const BOTH_PHASES = "Ambas";
export const PHASES_FILTER_LIST = [
    {
        value: PHASE_1
    },
    {
        value: PHASE_2
    },
    {
        value: BOTH_PHASES
    }
];
export const ALLOWED_STATUSES_PHASE_1 = [
    {
        "status": "purple",
        "color": "#800080",
        "description": "Em análise"
    }
];
export const ALLOWED_STATUSES_PHASE_2 = [
    {
        "status": "purple",
        "color": "#800080",
        "description": "Em análise"
    },
    {
        "status": "purpleVIP",
        "color": "#800080",
        "description": "Em análise VIP"
    }
];
export const AUTO_REFRESH_INTERVAL = 5 * 60 * 1000;