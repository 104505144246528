import { Subject } from "rxjs";

const $notification = new Subject();

export const NOTIFICATION_VARIANT = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    INFO: "info",
};

export const NotificationService = {
    send: (message, variant = NOTIFICATION_VARIANT.SUCCESS) => $notification.next({message, variant}),
    receive: () => $notification
};

