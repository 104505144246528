import { PATH } from '../routes/path-list';

export const ABILITY = {
  CREATE_PACKAGE: 'CREATE_PACKAGE',
  READ_PACKAGES: 'READ_PACKAGES',
  READ_CONSUMER_PACKAGES: 'READ_CONSUMER_PACKAGES',
  READ_PREPAID_PACKAGES: 'READ_PREPAID_PACKAGES',
  READ_TIMLIVE_PACKAGES: 'READ_TIMLIVE_PACKAGES',
  READ_MESATHALES_PACKAGES: 'READ_MESATHALES_PACKAGES',
  CORPORATE_USER: 'CORPORATE_USER',
  READ_CORPORATE_PACKAGES: 'READ_CORPORATE_PACKAGES',
  WRITE_CORPORATE_PACKAGES: 'WRITE_CORPORATE_PACKAGES',
  UPLOAD_CORPORATE_PACKAGES: 'UPLOAD_CORPORATE_PACKAGES',
  DELETE_CORPORATE_PACKAGES: 'DELETE_CORPORATE_PACKAGES',
  DOWNLOAD_DOCUMENTS: 'DOWNLOAD_DOCUMENTS',
  ACCESS_SENSITIVE_INFO: 'ACCESS_SENSITIVE_INFO',
  REQUEST_DOCUMENTS: 'REQUEST_DOCUMENTS',
  UPDATE_PACKAGE: 'UPDATE_PACKAGE',
  EDIT_DOCUMENT: 'EDIT_DOCUMENT',
  READ_PACKAGE_SCORES: 'READ_PACKAGE_SCORES',
  READ_PACKAGES_FULL_DETAILS: 'READ_PACKAGES_FULL_DETAILS',
  UPDATE_STATUS: 'UPDATE_STATUS',
  EXPORT_CSV: 'EXPORT_CSV',
  VIEW_COMPLETE_CORPORATE_DETAILS: 'VIEW_COMPLETE_CORPORATE_DETAILS',
  VIEW_COMPLETE_CONSUMER_DETAILS: 'VIEW_COMPLETE_CONSUMER_DETAILS'
};

export const KEYCLOAK_ROLE = {
  read_package: 'read_package',
  read_consumer_packages: 'read_consumer_packages',
  read_corporate_packages: 'read_corporate_packages',
  read_prepaid_packages: 'read_prepaid_packages',
  read_timlive_packages: 'read_timlive_packages',
  read_mesathales_packages: 'TIBANMM1',
  update_package: 'update_package',
  download_package: 'download_package',
  access_sensitive_info: 'access_sensitive_info',
  tim_capture: 'tim_capture',
  partner_capture: 'partner_capture',
  mobile_capture: 'mobile_capture',
  thales_tester: 'thales_tester',
  update_yellow_package_status: 'update_yellow_package_status',
  update_any_package_status: 'update_any_package_status',
  view_complete_details: 'view_complete_details',
  view_scores: 'view_scores',
  corporate_users: 'corporate_users',
  write_corporate_packages: 'write_corporate_packages',
  upload_corporate_packages: 'upload_corporate_packages',
  delete_corporate_packages: 'delete_corporate_packages',
  csv_export: 'csv_export',
  view_complete_corporate_details: 'view_complete_corporate_details',
  view_complete_consumer_details: 'view_complete_consumer_details',
};

export const ROLES_ABILITIES = {
  [ABILITY.READ_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_package,
  ],
  [ABILITY.READ_CONSUMER_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_consumer_packages,
  ],
  [ABILITY.READ_CORPORATE_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_corporate_packages,
  ],
  [ABILITY.READ_PREPAID_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_prepaid_packages,
  ],
  [ABILITY.READ_TIMLIVE_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_timlive_packages,
  ],
  [ABILITY.READ_MESATHALES_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_mesathales_packages,
  ],
  [ABILITY.WRITE_CORPORATE_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.write_corporate_packages,
  ],
  [ABILITY.DELETE_CORPORATE_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.delete_corporate_packages,
  ],
  [ABILITY.UPLOAD_CORPORATE_PACKAGES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.upload_corporate_packages,
  ],
  [ABILITY.READ_PACKAGES_FULL_DETAILS]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.view_complete_details,
    KEYCLOAK_ROLE.corporate_users,
  ],
  [ABILITY.DOWNLOAD_DOCUMENTS]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.download_package,
  ],
  [ABILITY.ACCESS_SENSITIVE_INFO]: [
    KEYCLOAK_ROLE.access_sensitive_info,
  ],
  [ABILITY.READ_PACKAGE_SCORES]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.view_scores,
  ],
  [ABILITY.CREATE_PACKAGE]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.tim_capture,
    KEYCLOAK_ROLE.partner_capture,
  ],
  [ABILITY.UPDATE_PACKAGE]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.update_package,
  ],
  [ABILITY.UPDATE_STATUS]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.update_yellow_package_status,
    KEYCLOAK_ROLE.update_any_package_status,
  ],
  [ABILITY.EXPORT_CSV]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.csv_export,
  ],
  [ABILITY.VIEW_COMPLETE_CORPORATE_DETAILS]: [
    KEYCLOAK_ROLE.view_complete_corporate_details,
  ],
  [ABILITY.VIEW_COMPLETE_CONSUMER_DETAILS]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.view_complete_consumer_details,
    KEYCLOAK_ROLE.view_complete_details
  ],
};

export const ROLES_ROUTES = {
  [PATH.PACKAGE]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_package,
  ],
  [PATH.CORPORATE_PACKAGE]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.corporate_users,
  ],
  [PATH.PREPAID_PACKAGE]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_prepaid_packages,
  ],
  [PATH.TIMLIVE_PACKAGE]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_timlive_packages,
  ],
  [PATH.MESATHALES_PACKAGE]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.read_mesathales_packages,
  ],
  [PATH.SALE_TYPE_FORM]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.tim_capture,
    KEYCLOAK_ROLE.partner_capture
  ],
  [PATH.DOCUMENTS_FORM]: [
    KEYCLOAK_ROLE.thales_tester,
    KEYCLOAK_ROLE.tim_capture,
    KEYCLOAK_ROLE.partner_capture,
  ],
};