import React from 'react';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { firebaseConfig, fcmVapKey, fcmApiKey } from '../../config';
import { fcmService } from '../../services/fcm-service';
import { keysService } from '../../services/keys-service';
import { take } from 'rxjs/operators';
import { NotificationService, NOTIFICATION_VARIANT } from '../../services/notification-service';


class Firestore extends React.Component {
  messaging = null;

  constructor(props) {
    super(props);
    this.initFirebase();
    this.getFirebaseToken = this.getFirebaseToken.bind(this);
  }

  componentDidMount() {
    this.subscription = fcmService.refreshFirebaseCloudMessageTokenSignal().subscribe(() =>
      this.getFirebaseToken()
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  initFirebase() {
    if (('Notification' in window)) {
      keysService.getKeys().pipe(
        take(1)
      ).subscribe((keys) => {
        const apiKey = keys.filter((value) => {
          return Object.keys(value)[0] === fcmApiKey;
        });
        const vapKey = keys.filter((value) => {
          return Object.keys(value)[0] === fcmVapKey;
        });
        try {
          firebaseConfig['apiKey'] = apiKey[0][fcmApiKey];
          firebase.initializeApp(firebaseConfig);
          this.messaging = firebase.messaging();
          this.messaging.usePublicVapidKey(vapKey[0][fcmVapKey]);
          this.getFirebaseToken();
          this.messaging.onMessage((message) => fcmService.sendFirebaseCloudMessage(message));
        } catch (e) {
          NotificationService.send(
            'Falha na busca por chaves de autenticação, favor checar o provisionamento'
            , NOTIFICATION_VARIANT.ERROR);
        }
      });
    }
  }

  getFirebaseToken() {
    if (('Notification' in window)) {
      if (this.messaging) {
        this.messaging.getToken().then((token) => {
          fcmService.setFirebaseCloudMessageToken(token);
        });
      }
    }
  }

  render() {
    return (null);
  }
}

export default Firestore;