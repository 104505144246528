import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { getStatusColor } from '../../api';
import Grid from '@material-ui/core/Grid';

function Status({status_name, status, showDescription}){
  const [color, setColor] = React.useState();
  const [description, setDescription] = React.useState();

  React.useEffect(() => {
    if (status_name){
      getStatusColor(status_name).then((response) => {
        setColor(((response.data || [])[0] || {}).color);
        setDescription(((response.data || [])[0] || {}).description);
      })
    }
  }, [status_name]);

  React.useEffect(() => {
    if (status && status.color && status.description){
      setColor(status.color);
      setDescription(status.description);
    }
  }, [status]);

  return (<Grid style={{display: 'inline-flex', verticalAlign: 'middle'}}><Tooltip title={description || ""}>
    <span style={{"backgroundColor": color}} className={"dot"}/>
  </Tooltip>
    {showDescription? description : null}
  </Grid>)
}

export default Status;