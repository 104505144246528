import React, {useEffect, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getDocumentForm } from "../../../api";
import Grid from "@material-ui/core/Grid";
import {FormContextProvider} from "../dynamic-form/dynamic-form-context";
import SubmitButton from "../dynamic-form/dynamic-form-submit-button";
import {documentFormRedirect} from "../../../config";
import {SALE_TYPE} from "../../../variables/document-matrix";
import {PATH} from "../../../routes/path-list";
import {TDIService, $stop} from "../../../services/tdi-service";
import {takeUntil} from "rxjs/operators";
import SaleType from "../../consult/sale-type";
import { DataService } from "../../../services/data-service";
import "./document-form-style.css";
import { take } from "rxjs/operators";
import MetadataFields from "./metadata-fields";
import DocFields from "./document-fields";
import { retrieveDocuments, arrayOfObjectsToObject } from "../../consult/detail/update-package-button";
import {version} from '../../../../package.json'

const onSubmit = (payload, apiFormInfo) => {
    payload = addFrontParameter(payload)
    DataService.getPayloadToRetrieveFormInfo()
        .pipe(take(1))
        .subscribe((dropdownValues) => {
            TDIService.invoke(payload, apiFormInfo, dropdownValues).pipe(
                takeUntil($stop),
            ).subscribe()
        });
};

const addFrontParameter = (payload) => {
    let userAgent = navigator.userAgent
    let frontVersion = 'v' + version
    let front = 'TIM DnB WebApp ' + frontVersion + ' ' + userAgent
    payload['FRONT'] = front
    return payload
}

const createPayload = (payload) => {
    let dynamicFormContextPayload = {};
    Object.keys(payload || {}).forEach((key) =>{
        dynamicFormContextPayload[key] = {value:payload[key], valid:true}
    });
    return dynamicFormContextPayload
};

const DocumentFormView = (props) => {
    const [dropdownValues, setDropdownValues] = useState({});
    let history = useHistory();
    let location = useLocation();
    const [payload, setPayload] = useState({});
    const [hasFullPayload, setHasFullPayload] = useState( false);
    useEffect(() => {

        if(!location.state) {
            setHasFullPayload(true);
        }  
        DataService.getPayloadToRetrieveFormInfo().pipe(
            take(1)
        ).subscribe((payload) => {
            setDropdownValues(payload);
            if (documentFormRedirect){
                if ((typeof payload === "object"
                    && !Object.keys(payload).includes(SALE_TYPE))
                    || typeof payload != "object") {
                    history.push(PATH.SALE_TYPE_FORM);
                }
            }
        });

        if(location.state && location.state.packageId) {
            retrieveDocuments(location.state.package, location.state.packageId)
            .then( (documents) => {
                delete location.state.packageId;
                delete location.state.package;
                setPayload( () => {
                    return {
                        ...createPayload(location.state),
                        ...createPayload(arrayOfObjectsToObject(documents))
                    }
                });
                setHasFullPayload(true);
            });
        }
    }, []);

    TDIService.getComplete().subscribe((packageId) => {
        history.push(PATH.PACKAGE_DETAILS_CREATION.replace(':scenarioId', packageId))
    });

    return (
        hasFullPayload ? <div>
            <SaleType className={"sale-type"} {...dropdownValues} />
            <Grid container spacing={0}>
                <FormContextProvider getFormInfo={getDocumentForm} payload={payload}>
                    <MetadataFields/>
                    <DocFields/>
                    <SubmitButton customSubmit={onSubmit}/>
                </FormContextProvider>
            </Grid>
        </div>
        : null 
    )
};

export default DocumentFormView;
