import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import './image-viewer-style.css';
import DeleteModal from '../modal/delete-modal';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { FileDisplayEditService } from '../../services/file-display-edit-service';
import { arrayRemove } from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Carousel from './carousel';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
    marginRight: '80px',
  },
  paper: {
    height: '83vh',
    display: 'flex',
    position: 'fixed',
    width: '90%',
    zIndex: '10',
  },
  container:{
    justifyContent: 'flex-end',
    marginRight: '80px',
  }
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MediaViewer = () => {

  const classes = useStyles();
  const [fileList, setFileList] = useState([]);
  const [title, setTitle] = useState('');
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [index, setIndex] = React.useState(0);

  const shiftLeft = () => {
    setIndex((previousIndex) => (previousIndex + fileList.length - 1) % fileList.length)
  };

  const shiftRight = () => {
    setIndex((previousIndex) => (previousIndex + 1) % fileList.length)
  };

  const showImageViewer = () => {
    setShow(true);
  };

  useEffect(() => {
    const fileListSubscription = FileDisplayEditService.getFileList()
      .subscribe((fileList) => {
        let isArrayContainsObject = fileList.some( file => (file && file.hasOwnProperty('image')) ? true : false );
        if(!isArrayContainsObject) {
          let list = [];
          fileList.forEach(file => {
            list.push({image: file});
          });
          fileList = list;
        }
        setFileList(fileList);
        showImageViewer();
      });
    return () => {
      fileListSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const titleSubscription = FileDisplayEditService.getTitle()
      .subscribe((title) => {
        setTitle(title);
      });
    return () => {
      titleSubscription.unsubscribe();
    };
  }, []);

  const removeFile = useCallback((image) => {
    setFileList(prevFiles => [...arrayRemove(prevFiles, image)]);
    setIndex(0);
  }, [fileList]);


  const openDeleteModal = (id) => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteImage = (deleteConfirmation, file) => {
    closeDeleteModal();
    if (deleteConfirmation) {
      FileDisplayEditService.deleteFile(file);
      removeFile(file);
    }
  };

  const closeImageViewer = () => {
    setIndex(0);
    setShow(false);
  };

  return (<Grid container>

    <DeleteModal
      show={showDeleteModal}
      callback={deleteImage}
      style={{ zIndex: 1500 }}
      id={fileList && fileList.length && fileList[index]}
    />
    <Grid item lg={6} justify={'flex-end'}>
      <Dialog style={{ zIndex: 1400, justifyContent: 'flex-end' }}
              onClose={closeImageViewer}
              aria-labelledby="customized-dialog-title"
              open={show}
              BackdropProps={{ classes: { root: classes.root } }}
              PaperProps={{ classes: { root: classes.paper } }}
              classes={{
                container: classes.container
              }}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeImageViewer}>
          {title}
        </DialogTitle>
        <MuiDialogContent>
          <Carousel fileList={fileList} shiftLeft={shiftLeft} shiftRight={shiftRight} index={index}/>
        </MuiDialogContent>
        <MuiDialogActions style={{justifyContent: 'center'}}>
          {fileList.length !== 0? <Button variant="contained" color="secondary" style={{
            width: '25px',
            alignSelf: 'center',
            margin: '10px'
          }}
                  onClick={openDeleteModal}>
            <DeleteIcon/>
          </Button>: null}
        </MuiDialogActions>
      </Dialog>
    </Grid>
  </Grid>);
};

export default MediaViewer;
