export const GET_DOCS_PAYLOAD_KEYS = {
  SALE_TYPE: "IDCOMBO1",
  SALE_SUBTYPE: "IDCOMBO2",
  OFFER: "IDCOMBO3",
  CLIENTID: "CLIENTID",
  SUBCLIENTID: "SUBCLIENTID",
  PACKAGE_ID: "PACKAGE-ID",
  ROLE_ID: "ROLEID",
  USER_ID: "USERID",
};
export const SALE_TYPE = "IDCOMBO1";
export const SALE_SUBTYPE = "IDCOMBO2";
export const OFFER = "IDCOMBO3";
export const CLIENTID = "CLIENTID";
export const SUBCLIENTID = "SUBCLIENTID";
export const PACKAGE_ID = "PACKAGE-ID";
export const ROLE_ID = "ROLEID";
export const USER_ID = "USERID";
