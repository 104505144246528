import React from 'react';
import { useHistory } from 'react-router-dom';
import { getSaleTypeForm } from '../../../api/';
import Grid from '@material-ui/core/Grid';
import { FormContextProvider } from '../dynamic-form/dynamic-form-context';
import SubmitButton from '../dynamic-form/dynamic-form-submit-button';
import Form from '../dynamic-form/dynamic-form-view';
import { PATH } from '../../../routes/path-list';
import { fcmService } from '../../../services/fcm-service';
import { DataService } from '../../../services/data-service';
import { take } from 'rxjs/operators';
import { PACKAGE_ID, ROLE_ID, USER_ID } from '../../../variables/document-matrix';
import { UserService } from '../../../services/user-service';

const onSubmit = (history) => () => {
  history.push(PATH.DOCUMENTS_FORM);
};

const SaleTypeFormView = () => {
  let history = useHistory();
  if (!("Notification" in window)) {
    alert("Este browser não suporta notificações de Desktop");
  }
  else{
    if (!fcmService.getFirebaseCloudMessageToken().getValue()) {
      history.push(PATH.NOTIFICATION_PERMISSION);
    }
  }


  React.useEffect(() => {
    DataService.getPayloadToRetrieveFormInfo().pipe(take(1)).subscribe((payload) => {
        delete payload[PACKAGE_ID];
        payload[ROLE_ID] = UserService.getCaptureRole();
        payload[USER_ID] = UserService.getUserId();
        DataService.setPayloadToRetrieveFormInfo(payload);
      }
    );
  }, []);


  return (
    <Grid container
          direction="column"
          justify="flex-start"
          alignItems="center">
      <FormContextProvider getFormInfo={getSaleTypeForm}>
        <Grid item xs={12} md={6} lg={4} spacing={0}>
          <Form/>
          <SubmitButton customSubmit={onSubmit(history)}/>
        </Grid>
      </FormContextProvider>
    </Grid>
  );
};

export default SaleTypeFormView;
