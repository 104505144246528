import React from 'react';
import { withRouter } from 'react-router-dom';
import NotificationPermissionButton from "../../components/firebase/notification-permission-button"

const NotificationPermission = withRouter(({history}) => {
    return (
        <NotificationPermissionButton history={history}> Clique aqui para permitir notificações </NotificationPermissionButton>
    )
});

export default NotificationPermission