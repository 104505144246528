import React, {useEffect} from 'react';
import {DataService, createSaleTypePayloadHash} from "../../services/data-service";

export const SaleType = (props) => {
    const [saleType, setSaleType] = React.useState("");

    React.useEffect(() => {
        const IDCOMBOS_PRESENT = Object.keys(props || {})
            .some((element) => {
                return element.includes('IDCOMBO')
            });
        if (IDCOMBOS_PRESENT) {
            DataService.setSaleTypeList(props);
        }
    }, [props]);

    useEffect(() => {
        const getSaleTypeSubscription = DataService.getSaleTypeList()
            .subscribe((saleTypeList) => {
                setSaleType(saleTypeList[createSaleTypePayloadHash(props)])
            });
        return () => {
            getSaleTypeSubscription.unsubscribe()
        }
    }, [props]);
    //console.log(saleType)

    return <div className={props.className}>{saleType}</div>
};

export default SaleType;
