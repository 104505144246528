import React, {useEffect, useState} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { LoaderService } from '../../services/loader-service';

export default ({children}) =>{
    const [active, setActive] = useState(false);

    useEffect(() => {
        const loaderSubscription = LoaderService.getLoaderValue()
            .subscribe((loader) => {
                setActive(!(loader === 0));
            });
        return () => {
            loaderSubscription.unsubscribe()
        }
    }, []);

    return <LoadingOverlay
        active={active}
        spinner
        text='Carregando...'
    >
        { children }
    </LoadingOverlay>
}