import React from 'react';
import { withRouter } from 'react-router-dom';
import NotificationPermissionButton from "../../components/firebase/notification-permission-button"

const NotificationPermission = withRouter(({history}) => {
    return ( <div>
        Não foi possivel autorizar o envio de notificações, favor permitir o seu browser de enviar notificações para a url desta aplicação.
        <br/>
        <NotificationPermissionButton history={history}> Tentar Novamente </NotificationPermissionButton>
    </div>);
})


export default NotificationPermission;