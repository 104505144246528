import { Message } from '../models/message';
import { APPLICATION_JSON, KEY_DERIVATION_OPTIONS, FRAME_DIRECTION, DEP_V1 } from './dep.constants';
import { DEPCrypto } from './dep-crypto';
import { DEPRequest } from '../models/dep-request';
import { DEPEncoder } from './dep-encoder';
import { OIDs } from './dep.oids';
import { DEPVersion } from '../models/dep-version';
import { Keys } from '../models/keys';
import { fromDer, toDer, toBuffer, getByteArrayFromHexString, getbytesToHex, byteTobuffer } from './dep.commons';
import { EncryptedContentInfo } from '../models/encrypted-content-info';
import { RequestHeader } from '../models/request-header';
import { Body } from '../models/body';

export class DEPClientRequest {

    crypto;
    encoder;

    constructor() {

        this.crypto = new DEPCrypto();
        this.encoder = new DEPEncoder();
    }


    async createDEPRequest(content, session, publicKey) {

        // setting all the initial objects for building DEP request.
        const requestBody = toBuffer(content);
        const message = new Message(APPLICATION_JSON, requestBody);

        // creating session keys object.
        const keys = new Keys(session.DataKey, session.MacKey);

        // encoding keys as asn1 object.
        const encodedKeys = this.encoder.encodeKeys(keys);

        // modifying keys asn1 object for compatible with DerApplicationSpecific of bouncy castle.
        const derObj = fromDer( byteTobuffer(getByteArrayFromHexString(encodedKeys)));
        derObj.tagClass = 64;
        derObj.type = 2;

        const finalEncodedKeys = getByteArrayFromHexString(getbytesToHex(toDer(derObj).getBytes()));
        const encryptedKeys = await this.crypto.encryptKeys(finalEncodedKeys, publicKey).then( (keys) => keys);
        const sessionKeys = this.crypto.createSessionKeys(encryptedKeys, publicKey);

        // deriving both data key and mac key from session keys.
        const dataKey = this.crypto.deriveSecretKey(session, KEY_DERIVATION_OPTIONS.DATA_KEY, FRAME_DIRECTION.REQUEST);
        const macKey = this.crypto.deriveSecretKey(session, KEY_DERIVATION_OPTIONS.MAC_KEY, FRAME_DIRECTION.REQUEST);

        // creating body object.
        const contentWithPadding = this.encoder.getDEREncodedBodyWithPadding(getbytesToHex(message.Content));
        const encryptedContent = this.crypto.encryptContent(byteTobuffer(getByteArrayFromHexString(dataKey)), getByteArrayFromHexString(contentWithPadding));
        const body = new Body(message.ContentType, new EncryptedContentInfo(OIDs.GEMALTO_ID_OCTET_STRING,
            OIDs.AES128_CBC, encryptedContent));

        // creating request header object.
        const timestamp = new Date().getTime() ;
        const depVersion = new DEPVersion(DEP_V1);
        const requestHeader = new RequestHeader(OIDs.HMAC_WITH_SHA256, timestamp, depVersion, session.SessionId, session.SequenceNumber);

        // calculating dep request mac.
        const macInput = this.encoder.encodeMacInput(requestHeader, body);
        const mac = this.crypto.calculateMac(macKey, macInput);

        const depRequest = new DEPRequest(requestHeader,sessionKeys,body,mac);

        return this.encoder.encodeRequest(depRequest);
    }

}
