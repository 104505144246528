import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import { EditableRowContext } from './row-context';
import DynamicFormFieldFactory from '../../../forms/dynamic-field-factory/dynamic-field-factory-view';
import { Box, Grid } from '@material-ui/core';

function Row({row, form}) {

  const {editing, setEditing, onDelete, onSave} = React.useContext(EditableRowContext);

  return (row.value.map((field) => {
    return <TableCell key={Math.random()} style={{"whiteSpace": "pre-line"}} >{field}</TableCell>
  }))
}


export default Row