import React from 'react';
import KeycloakProvider from '../../keycloak/keycloak-provider';
import { routing } from '../../routes/routes';
import ImageViewer from '../image-viewer/image-viewer-container';
import MediaViewer from '../image-viewer/media-viewer';
import GenericModal from '../../components/modal';
import Snackbar from '../material-ui/snackbar';
import Firestore from '../firebase/firestore';
import { AppContextProvider } from './context';
import { TableContextProvider } from '../consult/list/table/context';
import IdleTimeout from 'components/idletimeout';
import Head from './html-head';
import { KeysProvider } from './keys-context';
import { TableCorporateContextProvider } from "../consult/list/corporate-context";
import { TablePrepaidContextProvider } from "../consult/list/prepaid-context";
import { TableLiveContextProvider } from "../consult/list/livetim-context";
import { MesaThalesTableContextProvider } from 'components/consult/list/mesa-thales-context';
import ThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { muiTheme } from '../../variables/themes'
require('../tdi/tdi-view');


function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <AppContextProvider>
        <Head />
        <KeycloakProvider>
          <KeysProvider />
          <TableContextProvider>
            <TableCorporateContextProvider>
              <TablePrepaidContextProvider>
                <TableLiveContextProvider>
                  <MesaThalesTableContextProvider>
                    <IdleTimeout />
                    <Firestore />
                    <Snackbar />
                    <GenericModal />
                    <MediaViewer />
                    {routing}
                  </MesaThalesTableContextProvider>
                </TableLiveContextProvider>
              </TablePrepaidContextProvider>
            </TableCorporateContextProvider>
          </TableContextProvider>
        </KeycloakProvider>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
