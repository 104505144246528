export class DEPVersion {
    version
    constructor(version){
      this.version = version;
    }

    get VersionInt() {
      return this.version
    }

    get VersionString() {
      return "V"+this.version
    }
}
