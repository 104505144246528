import Button, { BUTTON_STYLE } from '../../../../button';
import React from 'react';
import { ModalService } from '../../../../../services/modal-service';
import StatusForm from '../../../../forms/status-form';
import StatusFormMesaThales from '../../../../forms/status-form-mesathales';
import { getUpdatableStatus } from '../../../../../api';
import {useLocation} from "react-router-dom";

const UpdateStatusButton = ({value, _package, reloadPackage, notPrepaid = true}) => {
  let location = useLocation();

  const [allowed, setAllowed] = React.useState(false);

  React.useEffect(() => {
    getUpdatableStatus((_package && (_package.info) && _package.info.status)).then((updatableStatus) => {
      setAllowed(updatableStatus?.data?.allowedStatus?.length)
    })
  });

  let details_path = location.pathname.split('/')[2];
  let isDetailsAllowed = true;

  if(details_path === 'mesathales'){
    return (allowed && notPrepaid ? <Button variant={BUTTON_STYLE.NEGATIVE_SECONDARY}
      onClick={() => updateStatusMesaThales(value, _package, reloadPackage)}
      style={{
        'marginBottom': '12px',
        'float': 'right',
        'width': 'auto'
      }}>
      Atualizar
      </Button> : null)
  }else{
    return (allowed && notPrepaid ? <Button variant={BUTTON_STYLE.NEGATIVE_SECONDARY}
      onClick={() => updateStatus(value, _package, reloadPackage)}
      style={{
        'marginBottom': '12px',
        'float': 'right',
        'width': 'auto'
      }}>
      Atualizar
      </Button> : null)
  }

  
};

function updateStatus(status, _package, reloadPackage) {
  ModalService.changeInfo({
    show: true,
    title: 'Deseja alterar o status do pacote?',
    text: <StatusForm _package={_package} reloadPackage={reloadPackage}/>,
    closeButton: true,
    spinner: false,
    textBottom: '',
    maxWidth: 'md'
  });
}

function updateStatusMesaThales(status, _package, reloadPackage) {
  ModalService.changeInfo({
    show: true,
    title: 'Deseja alterar o status do pacote?',
    text: <StatusFormMesaThales _package={_package} reloadPackage={reloadPackage}/>,
    closeButton: true,
    spinner: false,
    textBottom: '',
    maxWidth: 'md'
  });
}

export default UpdateStatusButton;

