import {makeStyles} from "@material-ui/core";
import {THEME, THEME_CONFIG} from "../../../config";

export const useStyles = makeStyles(theme => ({
    icon: {
        color: THEME_CONFIG[THEME].HEADER_ICON_BACKGROUND_COLOR,
        fontSize: "default"
    },
    typography: {
        padding: theme.spacing(2),
    },
}));