
export const TEXT_PLAIN_VALUE = "text/plain";

export const APPLICATION_JSON = "application/json";

export const APPLICATION_OCTET_STREAM = "application/octet-stream";

export const ASYMMETRIC_CRYPTO_ALGORITHM = "RSA-OAEP";

export const BLOCK_LENGTH = 16;

export const ASYMMETRIC_CRYPTO_ALGORITHM_HASH = "SHA-1";

export const SYMMETRIC_CIPHER_ALGORITHM = "AES-128/GCM";

export const HMAC_SHA_256 = "HmacSHA256";

export const AES_CBC = "AES-CBC";

export const SEQUENCE_NUMBER_LENGTH = 4;

export const SESSION_ID_LENGTH = 4;

export const SECRET_KEY_LENGTH = 16;

export const DEP_V1 = 1;

export const UPDATE_HEX = '00';

export const DEFAULT_IV = "00000000000000000000000000000000";

export const X_HTTP_HEADER = 'X-Http-Method';

export const CONTENT_TYPE_HEADER = 'Content-Type';

export const RESPONSE_TYPE = 'arraybuffer';

export const HTTP_GET_METHOD = "GET";

export const HTTP_POST_METHOD = "POST";

export const HTTP_RESPONSE_BODY = "body";

export const DEP_API_PATH = "dep";

export const KEY_DERIVATION_OPTIONS = {
    DATA_KEY : '01',
    MAC_KEY : '02'
}

export const FRAME_DIRECTION = {
    REQUEST : '01',
    RESPONSE : '02'
}
