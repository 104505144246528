import { distinctUntilChanged } from 'rxjs/operators';
import { TDIService } from "../../services/tdi-service";
import { ModalService } from "../../services/modal-service";

const RANDOM_TIPS = [
    "Nunca forneça as informações de sua conta para outra pessoa.",
    "Para usar o nosso sistema as notificações do navegador devem estar habilitadas.",
    "Aguarde mais um pouco.",
    "Validando os dados do pacote.",
    "Os administradores nunca pedirão a sua senha.",
    "Os documentos necessários para criação de um pacote dependem do tipo de venda.",
    "Processando pacote...",
];

TDIService.getCurrentStep()
    .pipe(distinctUntilChanged())
    .subscribe((currentStep) => {
    ModalService.changeInfo({
        show: true,
        text: 'Aguarde enquanto as suas informações estão sendo enviadas',
        title: 'Aguarde',
        closeButton: false,
        spinner: true,
        textBottom: "Aguarde. O pacote de documentos que você criou está sendo validado e classificado.",
        maxWidth: 'xs',
    })

});

TDIService.isRunning().subscribe((open) =>{
    ModalService.changeInfo({
        show: open,
        text: 'Aguarde enquanto as suas informações estão sendo enviadas',
        title: 'Aguarde',
        closeButton: false,
        spinner: true,
        textBottom: "",
        maxWidth: 'xs',
    })
});


