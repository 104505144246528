export const OIDs = {

    HMAC_WITH_SHA256 : "1.2.840.113549.2.9",

    GEMALTO_ID_KEYS : "1.3.6.1.4.1.31746.3.1.1.1",

    GEMALTO_ID_OCTET_STRING :"1.3.6.1.4.1.31746.3.1.1.2",

    RSAOAEP : "1.2.840.113549.1.1.7",

    AES128_CBC : "2.16.840.1.101.3.4.1.2"
}
