import React from "react";
import Input from "./input"
import MetadataLayout from "../form-input/layout-metadata"


export default function Cnpj(props){
    return (<MetadataLayout {...props}>
            <Input {...props}/>
        </MetadataLayout>
    )
}