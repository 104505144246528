import * as forge from 'node-forge';

export function getRandomByteArray(length) {
    return forge.random.getBytesSync(length);
}

export function toBuffer(content) {
    return forge.util.createBuffer(content, 'utf8');
}

export function byteTobuffer(byte) {
    return forge.util.createBuffer(byte);
}

export function fromDer(buffer) {
    return forge.asn1.fromDer(buffer);
}

export function toDer(obj) {
    return forge.asn1.toDer(obj);
}

export function getPublicKeyFingerprint(publicKey) {
    return forge.pki.getPublicKeyFingerprint(publicKey).data;
}

export function getCipherInstance(algo, key) {
    return forge.cipher.createCipher(algo, key);
}

export function getDecipherInstance(algo, key) {
    return forge.cipher.createDecipher(algo, key);
}

export function derToOid(der) {
    return forge.asn1.derToOid(der);
}

export function getByteArrayFromHexString(hex) {
    const num = new Int8Array(hex.length/2);
    for(let i = 0,j=0; i < hex.length;j++) {
        num[j] = parseInt(hex[i]+hex[i+1],16);
        i +=2;
    }
    return num;
}

export function getbytesToHex(bytes) {
    return forge.util.bytesToHex(bytes);
}

export function getHexToBytes(hex){
    return forge.util.hexToBytes(hex);
}

export function applyPadding(input,blocksize) {
    const remainder = (input.length/2) % blocksize;
    if (remainder === 0) {
        return input;
    }
    const paddingLength = blocksize - remainder;

    let paddingHex = "";
    for(let i = 0; i < paddingLength; i++) {
        paddingHex += forge.util.bytesToHex(forge.random.getBytesSync(1))
    }

    return input + paddingHex;
}

export function applyPaddingDecipher(input,blocksize) {
    const remainder = (input.length/2) % blocksize;
    const paddingLength = blocksize - remainder ;

    let paddingHex = "";
    for(let i = 0; i < paddingLength; i++) {
        paddingHex += forge.util.bytesToHex(forge.random.getBytesSync(1))
    }

    return input + paddingHex;
}

export function decimalToBinary(num) {

    if (num < 0) {
        num = 0xFFFFFFFF + num + 1;
    }
    return parseInt(num.toString(), 10).toString(2);
}

export function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
}
