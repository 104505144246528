export let _roles = [];
let _captureRole = "";
let _userId = "";

export const UserService = {
    getRoles: () => _roles,
    setRoles: (roles) => _roles = roles,
    getCaptureRole: () => _captureRole,
    setCaptureRole: (role) => _captureRole = role,
    getUserId: () => _userId,
    setUserId: (userId) => _userId = userId,
};