import React, {useContext} from "react";
import { FileDisplayEditService } from "../../../../services/file-display-edit-service";
import Tooltip from '@material-ui/core/Tooltip';
import {FileContext} from "./form-input-file-context";
import {AppContext} from "../../../app/context";


const ShowImagesButton = ({metadata}) => {
    const {files} = useContext(FileContext);
    const {language,appmetadata} = useContext(AppContext);
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    let classes = 'form-button form-button-lateral form-button-secondary'
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false) {
            classes = 'form-button form-button-lateral form-button-disabled'
            fieldDisabled = true;
        }
    }

            return (<Tooltip title={appmetadata.FORM.VIEW_DELETE[language]}>
        <button style={{"width": "17%"}}
                disabled={fieldDisabled}
                className={classes}
                onClick={() => {
                    FileDisplayEditService.setFileList(files);
                    FileDisplayEditService.setTitle(metadata.label);
                }}>
            {appmetadata.FORM.VIEW_DELETE[language]}
        </button>
    </Tooltip>)
};

export default ShowImagesButton;