import React from "react";
import HiddenField from "./form-input-hidden-container"
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context";

export default function Text(props){
    return (
        <FormContextConsumer>
            {({ setFormValues }) => (
                <HiddenField {...props} setFormValues={setFormValues} />
            )}
        </FormContextConsumer>

    )
}