import React from "react";
import lodash from 'lodash';
import {COMPONENT_LIST} from "./dynamic-field-factory-component-list";

export function getFieldComponent(type) {
    if (!COMPONENT_LIST) {
        return;
    }
    return COMPONENT_LIST[type];
}

export function instanciateComponent(CustomComponent, metadata, handleChange) {
    if (!CustomComponent || !metadata) {
        return null;
    }
    return <CustomComponent metadata={metadata} handleChange={handleChange} key={Math.random()} />;
}

export default class DynamicFieldFactoryView extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !lodash.isEqual(nextProps.fields, this.props.fields);
    }

    render() {
        const { fields, handleChange } = this.props;
        if (!fields || !fields.length) {
            return null;
        }
        return fields
            .filter(field => field && field.type)
            .map(field => {
                const CustomComponent = getFieldComponent(field.type);
                return instanciateComponent(CustomComponent, field, handleChange);
        })
    }
}