import React, {useContext} from "react";
import {FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT} from "../../../../variables/consult-info";
import Button from "@material-ui/core/Button";
import GetAppIcon from '@material-ui/icons/GetApp'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Tooltip from "@material-ui/core/Tooltip";
import {AppContext} from "../../../app/context";
import Table from "../../../material-ui/table/editable";
import {PackageContext} from "../package-context";
import {downloadFiles, openImageViewer} from './utils';
import CorporateDocumentForm from '../../../forms/corporate-document-form';
import {deleteDocument} from '../../../../api';

function DownloadFileRows(props){
  const {language,appmetadata} = useContext(AppContext);
  const {_package, reloadPackage}  = React.useContext(PackageContext);

  let rows = (_package.documents || [])
    .filter((document) => document.files.length !== 0 && document.name.includes('PED_'))
    .map((document) => {
      return {
        name: document.name,
        value:[FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[document.name] || document.name,
          <Tooltip title={appmetadata.CONSULT.SEE[language]}>
            <Button variant="contained" color="primary" onClick={
              () => openImageViewer(document, _package)
            }>
              <VisibilityIcon/>
            </Button>
          </Tooltip>,
          <Tooltip title={appmetadata.CONSULT.DOWNLOAD[language]}>
            <Button variant="contained" color="primary" onClick={() => downloadFiles(document, _package)}>
              <GetAppIcon/>
            </Button>
          </Tooltip>
        ],
        formProps: {document: document},
        deleteRow: () => deleteDocument((_package && _package.info) && _package.info.id, document.documentId)
      }
    });

  return <Table rows={rows}
                form={CorporateDocumentForm}
                reload={reloadPackage}
                formProps={{
                  packageId: ((_package && _package.info) && _package.info.id),
                  reload: reloadPackage
                }}
  />
}

export default DownloadFileRows;