import React from "react";
import {useHistory} from "react-router-dom";
import {PATH} from "../../../routes/path-list";
import {useStyles} from "./logo-style";
import {THEME, THEME_CONFIG} from "../../../config";

export default () => {
    const classes = useStyles();
    let history = useHistory();

    return (<img
        alt=""
        src={THEME_CONFIG[THEME].LOGO}
        onClick={() => history.push(PATH.HOME)}
        className={classes.logo}
    />)
}