import Can from '../permissions';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '10px'
  },
  card: {
    width: "275px",
    height: "275px",
    textAlign: "center"
  },
});


function CustomCard({ability, key, path, title, description, icon}){
  const classes = useStyles();
  let history = useHistory();

  return (<Can I={ability}>
    <Grid
          justify="center"
          alignItems="center"
          key={key}
          className={classes.root}
    >
      <Card className={classes.card}>
        <CardActionArea style={{height: "100%"}} onClick={() => history.push(path)}>
          <CardMedia>
            {icon}
          </CardMedia>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  </Can>)
}

export default CustomCard;