import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import StyledTableRow from './rows'
import React from "react";

function Body ({rows, prepareRow, getTableBodyProps}){
    return (<TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
            prepareRow(row);
            return (
                <StyledTableRow {...row.getRowProps()} className="tr">
                    {row.cells.map(cell => {
                        return (
                            <TableCell {...cell.getCellProps()} className="td">
                                {cell.render('Cell')}
                            </TableCell>
                        )
                    })}
                </StyledTableRow>
            )
        })}
    </TableBody>)
}

export default Body;