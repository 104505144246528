import React from 'react';
import MetadataLayout from '../form-input/layout-metadata';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DynamicFieldFactoryView from '../../dynamic-field-factory';
import {FormContext} from "../../dynamic-form/dynamic-form-context";

const AddFields = (props) => {

  const [formFields, setFormFields] = React.useState([]);
  const [index, setIndex] = React.useState(1);
  const {removeFormValue} = React.useContext(FormContext);

  const addFormFields = () => {
    setIndex((previousValue) => Number(previousValue) + 1);
    const newFields = props.metadata.fields.map((field) => {
      return {
        ...field,
        name: field.name+index,
        label: field.label+ ' ' + index
      }
    });
    setFormFields((previousValue) => [...previousValue, ...newFields]);
  };

  React.useEffect(() => {
    if (props.metadata.required){
      addFormFields();
    }
  }, [props]);

  const removeFormFields = () => {
    props.metadata.fields.forEach((field) => {
      removeFormValue(field.name+(index-1));
    });
    if (props.metadata.required && formFields.length === props.metadata.fields.length){
        return null
    }
    setIndex((previousValue) => {
      if (parseInt(previousValue) > 1){
        return parseInt(previousValue) - 1
      }
      return parseInt(previousValue)
    });
    setFormFields((previousValue) => {
      return previousValue.slice(0, -props.metadata.fields.length)
    });
  };

  return (
    <MetadataLayout {...props}>
      <Fab size="small" color="primary" aria-label="add">
        <AddIcon onClick={addFormFields}/>
      </Fab>
      <Fab size="small" color="secondary" aria-label="add">
        <RemoveIcon onClick={removeFormFields}/>
      </Fab>
      <DynamicFieldFactoryView fields={(formFields || [])} handleChange={() => {
      }}/>
    </MetadataLayout>
  );
};

export default AddFields;