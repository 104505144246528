import React from "react";
import CheckBox from "./checkbox"
import MetadataLayout from "../form-input/layout-metadata"


export default function CheckboxesGroup(props){
  return (
    <MetadataLayout {...props}>
    <CheckBox {...props}/>
  </MetadataLayout>
)
}