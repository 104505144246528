
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormContext } from '../../dynamic-form/dynamic-form-context';
import { Radio, RadioGroup } from '@material-ui/core';


export default function RadioButtons(metadata) {

    const { formValues, setFormValues } = React.useContext(FormContext);

    const handleChange = (event) => {
        if (metadata.onChange) {
            metadata.onChange(event);
        } else {
            setFormValues(metadata.metadata.name, event.target.value, true);
        }
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup
                style={{ flexDirection: 'row', color: 'black' }}
                onChange={handleChange}>
                {(metadata.metadata?.values || [])
                    .map(item => <FormControlLabel label={item.value} value={item.value}
                        control={<Radio color="primary" />} />
                    )}
            </RadioGroup>
        </FormControl>
    );
}


