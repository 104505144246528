import React from "react";
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context"

const  chooseClassName = (valid, validated) =>{
    if (valid && validated){
        return "form-field-input-default form-field-input--valid"
    }
    else if (!valid && validated){
        return "form-field-input-default form-field-input--invalid"
    }
    else{
        return "form-field-input-default form-field-input"
    }
};

const FormInputField = (props) => {
        return (
            <FormContextConsumer>
                {({ validated }) => (
                    <input className={chooseClassName(props.valid, validated || Boolean(props.validated))} {...props} />
                )}
            </FormContextConsumer>
        );
};

export default FormInputField;