import React from 'react';
import Grid from '@material-ui/core/Grid';
import MesaThalesTable from './mesa-thales-table';
import FilterDate from './table/filters/mesa-thales/date';
import PhaseFilter from './table/filters/mesa-thales/phase';
import FilterChips from './table/filters/mesa-thales/chips';


const MesaThalesConsultList = () => {
  return (
    <Grid container>

      <Grid container xs={12} md={6}>
        <Grid item xs={12}>
          Data de criação:
        </Grid>
        <Grid item xs={12}>
          <FilterDate filterKey={'creationStart'} label={'De'} />
          <FilterDate filterKey={'creationEnd'} label={'Até'} />
        </Grid>
      </Grid>

      <Grid container xs={12} md={6}>
        <Grid item xs={12}>
          Fase de projeto:
        </Grid>
        <Grid item xs={12}>
          <PhaseFilter filterKey={'phase'} label={'Selecione'} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FilterChips />
      </Grid>

      <Grid item xs={12} style={{ overflow: 'auto' }}>
        <MesaThalesTable />
      </Grid>

    </Grid>
  )
};

export default MesaThalesConsultList;
