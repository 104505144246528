import React from "react";
import DynamicFormFieldFactory from "../dynamic-field-factory";
import {FormContext} from "./dynamic-form-context";

const Form = () =>{
    const {apiFormInfo} = React.useContext(FormContext);

    return (
        <DynamicFormFieldFactory fields={(apiFormInfo.fields || [])} handleChange={()=>{}} />
    )
};

export default Form;
