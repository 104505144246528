import {composeURI} from "../api/api-utils"
import {
    URI_SUFFIX,
    DOC_MATRIX_CONFIG,
    SELF_DOCS_CONFIG,
    TDI_CONFIG,
    KEYCLOAK_CONFIG,
    CONSULT_CONFIG,
    PERSIST_CONFIG,
    PROFILE_CONFIG
} from "../config"

export const DOC_MATRIX = {
    SALE_TYPE: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.SALE_TYPE, URI_SUFFIX),
    REFRESH_SALE_TYPE: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.REFRESH_SALE_TYPE, URI_SUFFIX),
    REFRESH_SALE_TYPE2: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.REFRESH_SALE_TYPE2, URI_SUFFIX),
    DOCUMENT_LIST: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.DOCUMENT_LIST, URI_SUFFIX),
    STATUS_COLOR: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.STATUS_COLOR, URI_SUFFIX),
    UPDATABLE_STATUS: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.UPDATABLE_STATUS, URI_SUFFIX),
    GET_KEYS: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.GET_KEYS, URI_SUFFIX),
    GET_HELP_FILE: composeURI(DOC_MATRIX_CONFIG.BASE_URL, DOC_MATRIX_CONFIG.URI_PATH.GET_HELP_FILE, URI_SUFFIX),
};

export const SELF_DOCS = {
    FILTER_LIST: composeURI(SELF_DOCS_CONFIG.BASE_URL, SELF_DOCS_CONFIG.URI_PATH.FILTER_LIST, '.json'),
};

export const PROFILE = {
    BASE: composeURI(PROFILE_CONFIG.BASE_URL, PROFILE_CONFIG.URI_PATH.PROFILE, URI_SUFFIX),
};

export const TDI = {
    SCENARIO_STEP: composeURI(TDI_CONFIG.BASE_URL, TDI_CONFIG.URI_PATH.SCENARIO_STEP, ""),
    SCENARIO_STATUS: composeURI(TDI_CONFIG.BASE_URL, TDI_CONFIG.URI_PATH.SCENARIO_STATUS, ""),
    SCENARIO: composeURI(TDI_CONFIG.BASE_URL, TDI_CONFIG.URI_PATH.SCENARIO, ""),
};

export const KEYCLOAK = {
    SCENARIO_STEP: KEYCLOAK_CONFIG.BASE_URL + TDI_CONFIG.URI_PATH.SCENARIO_STEP,
    TOKEN: KEYCLOAK_CONFIG.BASE_URL + TDI_CONFIG.URI_PATH.TOKEN,
    CHANGE_PASSWORD: KEYCLOAK_CONFIG.BASE_URL + KEYCLOAK_CONFIG.URI_PATH.CHANGE_PASSWORD,
};

export const CONSULT = {
    PACKAGE: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.PACKAGE, URI_SUFFIX),
    EXPORT: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.EXPORT, URI_SUFFIX),
    FILE: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.FILE, URI_SUFFIX),
    DOCUMENT: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.DOCUMENT, URI_SUFFIX),
    ZIP: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.ZIP, URI_SUFFIX),
    PDF: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.PDF, URI_SUFFIX),
    UPDATE_STATUS: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.UPDATE_STATUS, URI_SUFFIX),
    UPDATABLE_STATUS: composeURI(CONSULT_CONFIG.BASE_URL, CONSULT_CONFIG.URI_PATH.UPDATABLE_STATUS, URI_SUFFIX),
};

export const PERSIST = {
    PACKAGE: composeURI(PERSIST_CONFIG.BASE_URL, "", "")
};

