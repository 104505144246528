import React from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationService, NOTIFICATION_VARIANT } from "../../../services/notification-service";

const variantIcon = {
    [NOTIFICATION_VARIANT.SUCCESS]: CheckCircleIcon,
    [NOTIFICATION_VARIANT.WARNING]: WarningIcon,
    [NOTIFICATION_VARIANT.ERROR]: ErrorIcon,
    [NOTIFICATION_VARIANT.INFO]: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    [NOTIFICATION_VARIANT.SUCCESS]: {
        backgroundColor: green[600],
    },
    [NOTIFICATION_VARIANT.ERROR]: {
        backgroundColor: theme.palette.error.dark,
    },
    [NOTIFICATION_VARIANT.INFO]: {
        backgroundColor: theme.palette.primary.main,
    },
    [NOTIFICATION_VARIANT.WARNING]: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function PositionedSnackbar(props) {
    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        variant: NOTIFICATION_VARIANT.SUCCESS,
        message: "<(''<) Kirby! (>'')>"
    });

    NotificationService.receive().subscribe(({message, variant}) =>{
        setState({...state, open: true, variant: variant, message: message})
    });

    const { vertical, horizontal } = state;
    const Icon = variantIcon[(state.variant || "info")];
    const classes = useStyles1();
    const closeSnackbar = () => {
        setState({...state, open: false})
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={5000}
                open={state.open}
                onClose={closeSnackbar}
            >
            <SnackbarContent
                className={classes[state.variant]}
                key={`${vertical},${horizontal}`}
                message={
                    <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {state.message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={closeSnackbar}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
            </Snackbar>
        </div>
    );
}

export default PositionedSnackbar