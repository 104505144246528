import NumberFormat from "react-number-format";
import React from "react";
import Input from "../form-input/input"
import {FormContext} from "../../dynamic-form/dynamic-form-context";

const matchRegEx = (string) => {
    const regex = "[0-9]+";
    return Boolean((string || "").match(regex));
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    setFormValues(metadata.name, event.target.value.replace(/\.|R\$/g,'').replace(',', '.'), matchRegEx(event.target.value) || !metadata.required);
};


export default function Currency({metadata}){
    const { setFormValues, formValues } = React.useContext(FormContext);
    const value = ((formValues[metadata.name] || [])['value'] || '');
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false)
            fieldDisabled = true;
    }

    return (<NumberFormat customInput={Input}
                          id={metadata.name}
                          thousandSeparator={'.'}
                          prefix={'R$'}
                          decimalSeparator={','}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          metadata={metadata}
                          valid={matchRegEx(value) || !metadata.required}
                          onChange={onChange(setFormValues, formValues, metadata)}
                          allowNegative={false}
                          isNumericString={true}
                          value={value}
                          disabled={fieldDisabled}
    />)
}

