import React from 'react';
import {PATH} from "../../routes/path-list";
import { Route, Redirect } from 'react-router-dom';
import {ROLES_ROUTES} from "../../variables/roles-permissions"
import WebCaptureLayout from '../../components/layout/web-capture-layout'
import {checkPermisions} from "../../components/permissions"
import {UserService} from "../../services/user-service";

export const PrivateRouteByRole = (props) => {
    const roles = UserService.getRoles();
    return (
        <Route {...props} component={matchProps => (
            checkPermisions(ROLES_ROUTES, props.basepath, roles)  ?
                <WebCaptureLayout>
                    <props.component {...matchProps} />
                </WebCaptureLayout>:
                    <Redirect to={PATH.HOME}/>
                    )
        } />
    )
};

export default PrivateRouteByRole;


