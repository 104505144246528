import React from "react";
import {PackageContext} from "../package-context";
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import {FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT, FILTER_CIRCLES_KEYS_NAME_REPLACEMENT} from "../../../../variables/consult-info";
import ExpansionPanels from '../../../material-ui/expansion-panel'

function ScoresTab(props){

const {_package}  = React.useContext(PackageContext);

let sortedScores =  ((_package || {}).scores || []).map((score, index) => {
        let document = score.documentName;  
        let scores = [];
        return {
            title: FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[document] || document,
            id: 'panel' + index,
            content: score.attributes.map((attribute) => {
                const [scoreKey, scoreValue] = Object.entries(attribute)[0];
                scores.push([scoreKey, (scoreValue*100).toFixed(2)]);
                scores.sort(function(a, b){
                    if(a < b) return -1;
                    if(a > b) return 1;
                    return 0;
                });
                return scores;
            })
        }
    });

    sortedScores.sort( (a, b) => a.title[0].localeCompare(b.title[0], 'fr', { ignorePunctuation: true }));

    let rows =  (sortedScores || []).map((cont, index) => {
        if(cont.content.length === 0){
            return{
                title: cont.title,
                id: 'panel' + index,
            }
        }else{
            return {
                title: cont.title,
                id: 'panel' + index,
                content: cont.content[0].map((scoreKey, scoreValue) => {
                        return <div className={'width50'}>
                        <CircularProgressbarWithChildren value={scoreValue} maxValue={1}>
                            <div style={{marginTop: -5, hyphens: "auto", textAlign: "center"}}>
                                {FILTER_CIRCLES_KEYS_NAME_REPLACEMENT[scoreKey[0]] || scoreKey[0]}
                            </div>
                            <div style={{marginTop: -5 }}>
                                <strong id={`${cont.title}.${scoreKey[0]}`}>{`${scoreKey[1]}%`}</strong>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                    
                })
            } 
        }
        
    });

    
return <ExpansionPanels style={'flex'} panels={rows}/>

}

export default ScoresTab;