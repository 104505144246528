import { Subject, ReplaySubject  } from "rxjs";

const $token = new ReplaySubject (1);
const $refresh = new Subject();
const $refreshed = new Subject();
const $logout = new Subject();

export const keycloakService = {
    getToken: () => $token,
    setToken: (token) => $token.next(token),
    refreshToken: () => $refresh.next(true),
    getRefreshTokenSignal: () => $refresh,
    sendRefreshedTokenSignal: () => $refreshed.next(true),
    getRefreshedTokenSignal: () => $refreshed,
    logout: () => $logout.next(true),
    getLogoutSignal: () => $logout,
};


