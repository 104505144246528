export class ResponseHeader{
    protocolVersion;
    sequenceNumber;
    statusCode;
    constructor(protocolVersion, sequenceNumber,
         statusCode){
        this.protocolVersion = protocolVersion;
        this.sequenceNumber = sequenceNumber;
        this.statusCode = statusCode;
    }

    get ProtocolVersion() {
        return this.protocolVersion;
    }

    get SequenceNumber() {
        return this.sequenceNumber;
    }

    get StatusCode() {
        return this.statusCode;
    }

}


