import React from "react";
import ShowImagesButton from "./show-images-button"
import FileInput from "./form-input-file-view"
import FileInfoViewer from "./form-input-files-information"
import DocumentLayout from "../form-input/layout-documents"
import {FileContextProvider, } from "./form-input-file-context"
import Feedback from "./feedback"

const FileField = (props) =>{
    return (
        <FileContextProvider metadata={props.metadata}>
                    <DocumentLayout metadata={props.metadata}>
                        <FileInput metadata={props.metadata} />
                        <FileInfoViewer itemLimit={(props.metadata.itemLimit || 1)}
                                        metadata={props.metadata}
                                        style={{"width": "60%", "marginLeft": "-1px"}}
                        />
                        <ShowImagesButton metadata={props.metadata} />
                        <Feedback/>
                    </DocumentLayout>
        </FileContextProvider>
    )
};

export default FileField;
