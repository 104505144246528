import React from 'react';
import { guessImageMime, isBase64 } from '../../../utils';
import Figure from 'react-bootstrap/Figure';

const createFileSrc = (file) => {
  return file ? URL.createObjectURL(file) : '';
};

const MediaFactory = ({ file }) => {
  // assumption for now mime types pdf and image will be supported
  let MIMEType = guessImageMime(file.image);
  let data = null;

  if (isBase64(file.image)) {
    data = 'data:' + MIMEType + ';base64,' + file.image;
  } else {
    data = createFileSrc(file.image);
  }

  if (MIMEType.indexOf('pdf') > -1) {
    return (<object style={{ minWidth: '100%', minHeight: '66vh' }} className="pdfViewer" data={data}/>);
  } else if (MIMEType.indexOf('image') > -1) {
    // return <ImageViewer src={data} />
    return (<Figure className="imageViewer"><Figure.Image className="figure-container" src={data}/></Figure>);
  }
  else{
    return <div> Tipo de mídia não suportado </div>
  }
};

export default MediaFactory;