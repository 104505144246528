import NumberFormat from "react-number-format";
import React from "react";
import Input from "../form-input/input"
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context";
import {closestSizeString, longestString, substringWithNOccurrencesOfCharacter} from "../../../../utils";

const matchRegEx = (string, pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(string);
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    setFormValues(metadata.name, event.target.value.replace(/\D/g,''),
        matchRegEx(event.target.value.replace(/\D/g,''), metadata.regexp));
};

export const selectMask = (formValues, metadata) => {
    const id = ((formValues[metadata.name] || {})["value"] || '');
    const masks = metadata.mask.split("|");
    const longestMask = longestString(masks);
    const closestMask = closestSizeString(masks, id.length);
    let initialMask = '#'.repeat(longestMask.split('#').length -1);
    const mask = longestMask === closestMask ? longestMask : closestMask + "#";
    if (id.length === 0) {
        return initialMask;
    }
    else {
        return substringWithNOccurrencesOfCharacter(mask,'#', id.length+1);
    }
};

export default function Number({metadata}){
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false)
            fieldDisabled = true;
    }
    return (<FormContextConsumer>
        {({ setFormValues, formValues }) => (
            <NumberFormat customInput={Input}
                          id={metadata.name}
                          format={selectMask(formValues, metadata)}
                          metadata={metadata}
                          mask={" "}
                          valid={matchRegEx(((formValues[metadata.name] || {})["value"] || ''), metadata.regexp) || !Boolean(metadata.required)}
                          onChange={onChange(setFormValues, formValues, metadata)}
                          isNumericString={true}
                          value={((formValues[metadata.name] || {})["value"] || '')}
                          disabled={fieldDisabled}
            />
        )}
    </FormContextConsumer>)
}
