import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import { openFormModal } from './table';
import DeleteIcon from '@material-ui/icons/Delete';
import { ModalService } from '../../../../services/modal-service';
import {NotificationService, NOTIFICATION_VARIANT} from '../../../../services/notification-service';
import Button, { BUTTON_STYLE } from '../../../button';
import {isUserAllowed} from "../../../permissions";
import {ABILITY} from "../../../../variables/roles-permissions";


const onDelete = (onDelete) => {
  ModalService.changeInfo({
    show: true,
    title: 'Deletar',
    text: 'Tem certeza que deseja deletar?',
    textBottom: '',
    closeButton: true,
    spinner: false,
    buttons: <Button variant={BUTTON_STYLE.NEGATIVE_PRIMARY} onClick={onDelete}>Deletar</Button>,
    maxWidth: 'xs'
  });
};

function ActionField({ form, formProps, deleteRow, reload }) {
  return (
    <>
      <TableCell key={Math.random()} padding="checkbox" style={{ 'whiteSpace': 'pre-line' }}>
        <IconButton aria-label="edit" disabled={isUserAllowed(ABILITY.WRITE_CORPORATE_PACKAGES) ? false : true} onClick={() => openFormModal(form, formProps)}>
          <EditIcon fontSize="small"/>
        </IconButton>
      </TableCell>
      <TableCell key={Math.random()} padding="checkbox" style={{ 'whiteSpace': 'pre-line' }}>
        <IconButton aria-label="delete" disabled={isUserAllowed(ABILITY.DELETE_CORPORATE_PACKAGES) ? false : true}>
          <DeleteIcon fontSize="small" onClick={() => onDelete(() => deleteRow()
            .then(() => {
              reload();
              ModalService.close();
            }).catch((error) => {
              NotificationService.send(
                error.response.status + " Ocorreu algo inesperado. Tente novamente.",
                NOTIFICATION_VARIANT.ERROR
              );
              ModalService.close();
            })
          )}/>
        </IconButton>
      </TableCell>
    </>
  );
}

export default ActionField;