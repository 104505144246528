import { createMuiTheme } from '@material-ui/core';
export const AVAILABLE_THEMES = {
    TIM: "TIM",
    THALES: "THALES"
};

export const muiTheme = createMuiTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ].join(',') 
    }
  });