import {useHistory} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SaleType from '../sale-type';
import TextFilter from './table/filters/livetim-filters/text';
import PageView from '@material-ui/icons/Pageview';
import {PATH} from '../../../routes/path-list';
import {LiveTableContext} from './livetim-context';
import {Styles} from './table/style';
import Table from './table/table';
import {generateSaleTypePayload} from './consumer-table';
import OperationFilter from "./table/filters/operation-filter";
import Status from "../status";
import StatusFilter from "./table/filters/livetim-filters/status";
import Operacao from '../operacao';
import { profileService } from 'services/profile-service';
import {getProfile} from "api/index"

function LiveTimTable() {

    let history = useHistory();

    useEffect(() => {
        (async () => {
            let profileData = await getProfile();
            profileService.initI18n(profileData);
            profileService.setLocalization(profileData.localization);
            profileService.setProfile(profileData);
        })();
      }, []);
    

      const columns = React.useMemo(
        () => [
            {
                Header: 'Data de Criação',
                columns: [
                    {
                        accessor: 'creationDate',
                        Header: "",
                    },
                ],
            },
            {
                Header: 'ID do Pacote',
                columns: [
                    {
                        accessor: 'id',
                        Header: <TextFilter filterKey={'packageId'}/>
                    }
                ]
            },
            {
                Header: 'Protocolo Live',
                columns: [
                    {
                        accessor: 'LIVE-PROTOCOLO',
                        Header: <TextFilter filterKey={'LIVE-PROTOCOLO'}/>
                    }
                ]
            },
            {
                Header: 'CPF',
                columns: [
                    {
                        accessor: 'cpf',
                        Header: <TextFilter filterKey={'cpf'}/>,
                    },
                ],
            },
            {
                Header: 'CNPJ',
                columns: [
                    {
                        accessor: 'CNPJ',
                        Header: <TextFilter filterKey={'CNPJ'}/>
                    }
                ]
            },
            {
                Header: 'PDV',
                columns: [
                    {
                        accessor: 'CUSTCODE-PDV',
                        Header: "",
                    },
                ],
            },
            {
                Header: 'Usuário de criação',
                columns: [
                    {
                        accessor: 'username',
                        Header: "",
                    },
                ],
            },
            {
                Header: 'Operação',
                id: 'saleType',
                columns: [
                    {
                        id: 'saleType',
                        filter: "",
                        accessor: d => {
                          let props = d;
                          return <Operacao {...props} />
                        },
                        Header: "",
                    },
                ],
            },
            /*{
                Header: 'Tipo Loja',
                columns: [
                    {
                        accessor: 'ROLEID',
                        Header: "",
                    },
                ],
            },
            {
                Header: 'Documento Sócio',
                columns: [
                    {
                        accessor: 'SOCIO-DOC',
                        Header: <TextFilter filterKey={'SOCIO-DOC'}/>,
                    },
                ],
            },*/
            {
                Header: 'Status',
                id: 'status',
                columns: [
                    {
                        id: 'status-filter-field',
                        accessor: d => <Status status_name={d.status} showDescription={1}/>,
                        Header: <StatusFilter/>,
                    },
                ],
            },
            {
                Header: '',
                id: 'view',
                columns: [
                    {
                        id: 'view',
                        accessor: d => <PageView color='primary' style={{cursor: 'pointer'}}
                                                 onClick={() => {
                                                     history.push({
                                                         pathname: PATH.TIMLIVE_PACKAGE_DETAILS.replace(':packageId', d.id),
                                                     });
                                                 }}> </PageView>,
                        Header: ''
                    }
                ]
            }
        ]
    );

    const {data, setAccessed} = React.useContext(LiveTableContext);

    React.useEffect(() => {
        setAccessed(true)
    });

    return (
        <Styles>
            <Table columns={columns} data={data} context={LiveTableContext}/>
        </Styles>
    );
}

export default LiveTimTable;