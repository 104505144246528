export class RequestHeader {
    oid;
    timestamp;
    protocolVersion;
    sessionIdentifier;
    sequenceNumber;
    constructor(oid,  timestamp,
       protocolVersion,  sessionIdentifier,
       sequenceNumber) {
        this.oid = oid;
        this.timestamp = timestamp;
        this.protocolVersion = protocolVersion;
        this.sessionIdentifier = sessionIdentifier;
        this.sequenceNumber = sequenceNumber;
    }

    get OID() {
      return this.oid
    }

    get Timestamp() {
      return this.timestamp
    }

    get ProtocolVersion() {
      return this.protocolVersion
    }

    get SessionIdentifier() {
      return this.sessionIdentifier
    }

    get SequenceNumber() {
      return this.sequenceNumber
    }
}
