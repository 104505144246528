import * as KJUR from 'jsrsasign';
import { byteTobuffer, getByteArrayFromHexString, toDer, fromDer, applyPadding, decimalToBinary, getbytesToHex, getHexToBytes } from './dep.commons';
import { BLOCK_LENGTH } from './dep.constants';

export class DEPEncoder {

    encodeKeys(keys) {

        const data = getbytesToHex(keys.DataKey);
        const mac = getbytesToHex(keys.MacKey);

        return new KJUR.asn1.DERTaggedObject({
            obj : new KJUR.asn1.DERSequence({
                "array" : [
                    new KJUR.asn1.DEROctetString({hex: data}),
                    new KJUR.asn1.DEROctetString({hex: mac})]
            })
        }).getEncodedHex();
    }

    getDEREncodedBodyWithPadding(bodyHex) {

        const hex = new KJUR.asn1.DEROctetString({hex: bodyHex}).getEncodedHex();
        return applyPadding(hex, BLOCK_LENGTH);
    }

    encodeTimestamp(timestamp) {

        const result = new Int8Array(8);
        const operands = [56,48,40,32,24,16,8];
        const binArray = decimalToBinary(timestamp).split("");
        for(let i = 0;i < operands.length; i++ ) {
            let j = operands[i];
            if(j >= binArray.length) {
                result[i] = 0
            }
            else {
                const tmpArray = [...binArray];
                while(j > 0) {
                    j--;
                    tmpArray.pop();
                }
                result[i] = parseInt(parseInt(tmpArray.join(""), 2).toString(10));
            }
        }
        return  new KJUR.asn1.DEROctetString({hex: getbytesToHex(result)});
    }

    encodeMacInput(requestHeader, requestBody) {

        const encodedRequestHeader = this.encodeRequestHeader(requestHeader).getEncodedHex();
        let encodedBody = this.encodeBody(requestBody).getEncodedHex();
        const requestBodyBuffer = byteTobuffer(getByteArrayFromHexString(encodedBody));

        const asn1Obj  = fromDer(requestBodyBuffer);

        asn1Obj.value[1].value[2] = asn1Obj.value[1].value[2].value[0];
        asn1Obj.value[1].value[2].tagClass = 128;
        asn1Obj.value[1].value[2].type = 0;

        encodedBody = getbytesToHex(toDer(asn1Obj).getBytes());
        return (encodedRequestHeader + encodedBody);
    }

    encodeMacInputResponse(responseHeader, requestBody) {

        const encodedResponsetHeader = this.encodeResponseHeader(responseHeader).getEncodedHex();
        let encodedBody = new KJUR.asn1.DERTaggedObject({obj : this.encodeBody(requestBody)}).getEncodedHex();
        const requestBodyBuffer = byteTobuffer(getHexToBytes(encodedBody));

        const asn1Obj  = fromDer(requestBodyBuffer);
        asn1Obj.value[0].value[1].value[2] = asn1Obj.value[0].value[1].value[2].value[0];
        asn1Obj.value[0].value[1].value[2].tagClass = 128;
        asn1Obj.value[0].value[1].value[2].type = 0;

        encodedBody = getbytesToHex(toDer(asn1Obj).getBytes());
        return (encodedResponsetHeader + encodedBody);
    }

    encodeResponseHeader(responseHeader){

        return new KJUR.asn1.DERSequence({
            "array" :[
                this.encodeProtocolVersion(responseHeader.ProtocolVersion.VersionInt),
                new KJUR.asn1.DEROctetString({hex: getbytesToHex(responseHeader.SequenceNumber)}),
                new KJUR.asn1.DERInteger({'int': responseHeader.StatusCode.Code})
            ]
        });
    }

    encodeRequestHeader(requestHeader) {

        return new KJUR.asn1.DERSequence({
            "array" :[
                this.encodeProtocolVersion(requestHeader.ProtocolVersion.VersionInt),
                new KJUR.asn1.DEROctetString({hex: getbytesToHex(requestHeader.SessionIdentifier)}),
                new KJUR.asn1.DEROctetString({hex: getbytesToHex(requestHeader.SequenceNumber)}),
                this.encodeTimestamp(requestHeader.Timestamp),
                new KJUR.asn1.DERSequence({
                    "array" : [
                        new KJUR.asn1.DERObjectIdentifier({oid : requestHeader.OID })
                        ]
                })
            ]
        });
    }

    encodeProtocolVersion(version) {
        return new KJUR.asn1.DERInteger({'int': version});
    }

    encodeBody(requestBody) {

        return new KJUR.asn1.DERSequence({
            "array" : [
                new KJUR.asn1.DERUTF8String({ str : requestBody.MediaType}),
                this.encodeEncryptedContentInfo(requestBody.EncryptedContent)
            ]
        });
    }

    encodeEncryptedContentInfo(encryptedContentInfo) {

        const encryptedContentHex = getbytesToHex(encryptedContentInfo.EncryptedContent);
        return  new KJUR.asn1.DERSequence({
                "array" : [
                    new KJUR.asn1.DERObjectIdentifier({oid : encryptedContentInfo.ContentType }),
                    new KJUR.asn1.DERSequence({
                        "array" : [
                            new KJUR.asn1.DERObjectIdentifier({oid : encryptedContentInfo.ContentEncryptionAlgorithm })
                        ]
                    }),
                    new KJUR.asn1.DERTaggedObject({
                         obj : new KJUR.asn1.DEROctetString({hex: encryptedContentHex})
                    })
                ]
        });
    }

    encodeRequest(request) {

        const requestASNObject = new KJUR.asn1.DERTaggedObject({
            tag : '60',
            obj : new KJUR.asn1.DERSequence({
                "array" : [
                   this.encodeRequestHeader(request.RequestHeader),
                   this.encodeSessionKeys(request.SessionKeys),
                   this.encodeBody(request.Body),
                   new KJUR.asn1.DEROctetString({hex: request.Mac})
                ]
            })
        });
        return requestASNObject.getEncodedHex();
    }

    encodeSessionKeys(sessionKeys) {

        return new KJUR.asn1.DERSequence({
            "array" : [
                new KJUR.asn1.DEROctetString({hex: getbytesToHex(sessionKeys.SubjectKeyIdentifier)}),
                this.encodeEncryptedContentInfo(sessionKeys.EncryptedKeys)
            ]
        });
    }

}
