import styled from 'styled-components';

export const Styles = styled.div`

  .table {
    display: inline-block;
    border-spacing: 0;
    overflow-x: auto !important;
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      :nth-of-type(2n+1): {
            background-color: purple;
      }
    }
        
    .th{
        font-weight: bold;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        overflow: hidden;
        text-overflow: ellipsis; 
    },
    .td {
      margin: 0;
      padding: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis; 
      border-right: 1px solid rgba(0, 0, 0, 0.05);

      ${'' /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer{
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
        
        :hover{
            background: gray;
        }

        &.isResizing {
          background: red;
        }
      }
    }
  }
`;