import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SaleType from '../sale-type';
import TextFilter from './table/filters/prepaid-filters/text';
import Status from '../status';
import StatusFilter from './table/filters/prepaid-filters/status';
import PageView from '@material-ui/icons/Pageview';
import { PATH } from '../../../routes/path-list';
import { PrepaidTableContext } from './prepaid-context';
import { Styles } from './table/style';
import Table from './table/table'
import OperationFilter from "./table/filters/operation-filter";
import { generateSaleTypePayload } from './consumer-table';
import { profileService } from 'services/profile-service';
import {getProfile} from "api/index";


function PrepaidTable() {

  let history = useHistory();

  useEffect(() => {
    (async () => {
        let profileData = await getProfile();
        profileService.initI18n(profileData);
        profileService.setLocalization(profileData.localization);
        profileService.setProfile(profileData);
    })();
  }, []);

  const columns = React.useMemo(
    () =>[
      {
        Header: 'ID do Pacote',
        columns: [
          {
            accessor: 'id',
            Header: <TextFilter filterKey={'packageId'}/>,
          },
        ],
      },
      {
        Header: 'SessionID',
        columns: [
          {
            accessor: 'SESSIONID',
            Header: <TextFilter filterKey={'SESSIONID'}/>,
          },
        ],
      },
      {
        Header: 'Data de Criação',
        columns: [
          {
            accessor: 'creationDate',
            Header: "",
          },
        ],
      },
      {
        Header: 'CPF',
        columns: [
          {
            accessor: 'cpf',
            Header: <TextFilter filterKey={'CPF'}/>,
          },
        ],
      },
      {
        Header: 'Status',
        id: 'status',
        columns: [
          {
            id: 'status-filter-field',
            accessor: d => <Status status_name={d.status} showDescription={1}/>,
            Header: <StatusFilter/>,
          },
        ],
      },
      {
        Header: '',
        id: 'view',
        columns: [
          {
            id: 'view',
            accessor: d => <PageView color='primary' style={{cursor: 'pointer'}}
                                     onClick={() => {
                                       history.push({
                                         pathname: PATH.PREPAID_PACKAGE_DETAILS.replace(':packageId', d.id),
                                       })
                                     }}> </PageView>,
            Header: "",
          },
        ],
      },
    ],
  );

  const {data, setAccessed} = React.useContext(PrepaidTableContext);

  React.useEffect(() => {
    setAccessed(true)
  });

  return (
    <Styles>
      <Table columns={columns} data={data} context={PrepaidTableContext}/>
    </Styles>
  )
}

export default PrepaidTable;