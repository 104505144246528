import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {isUserAllowed} from "../../permissions";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0} style={{"height":"inherit"}}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '75vh'
    },
    selected: {
        backgroundColor: '#fff',
    },
    panel:{
        height: '68vh',
    }
}));

export default function ScrollableTabsButtonForce({tabs, children}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const allowed_tabs = (tabs || []).filter((tab) => {
        return isUserAllowed(tab.ability)
    });

    return (

        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs"
                    wrapped={1}
                >
                    {allowed_tabs.map( (tab, index) => {
                        return (
                            <Tab label={tab.label} {...a11yProps(index)} onClick={() => {setValue(index)}}/>
                        )
                    })}
                </Tabs>
            </AppBar>
            {allowed_tabs.map( (tab, index) => {
                return <TabPanel className={classes.panel} value={value} index={index}>{tab.component}</TabPanel>
            })}
        </div>
    );
}
