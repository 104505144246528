export const NO_ERROR = 0;

export const BAD_REQUEST = 10;

export const UNSUPPORTED_VERSION = 11;

export const INVALID_PUBLIC_KEY = 12;

export const INVALID_SESSION_KEY_IDENTIFIER = 13;

export const INVALID_TIMESTAMP = 14;

export const SERVER_ERROR = 20;

export const DEP_RESPONSE_MAP = {

    0  : "NO_ERROR",
    10 : "BAD_REQUEST",
    11 : "UNSUPPORTED_VERSION",
    12 : "INVALID_PUBLIC_KEY",
    13 : "INVALID_SESSION_KEY_IDENTIFIER",
    14 : "INVALID_TIMESTAMP",
    20 : "SERVER_ERROR"
}
