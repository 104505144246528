export class EncryptedContentInfo {
    contentType;
    contentEncryptionAlgorithm;
    encryptedContent;
    constructor(contentType, contentEncryptionAlgorithm, encryptedContent){
        this.contentType = contentType;
        this.contentEncryptionAlgorithm = contentEncryptionAlgorithm;
        this.encryptedContent = encryptedContent;
    }

    get ContentType() {
        return this.contentType;
    }

    get ContentEncryptionAlgorithm() {
        return this.contentEncryptionAlgorithm;
    }

    get EncryptedContent() {
        return this.encryptedContent;
    }

}
