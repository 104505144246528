import { DEPDecoder } from './dep-decoder';
import { NO_ERROR } from './dep-status-codes';
import { OIDs } from './dep.oids';
import { DEPCrypto } from './dep-crypto';
import { KEY_DERIVATION_OPTIONS, FRAME_DIRECTION } from './dep.constants';
import { DEPEncoder } from './dep-encoder';
import { getbytesToHex } from './dep.commons';


export class DEPClientResponse {

    decoder;

    constructor() {
        this.decoder = new DEPDecoder();
    }

    createDEPResponse(responseBuffer, session) {

        const encodedResponse = new Int8Array(responseBuffer);
        const depResponse =  this.decoder.decodeResponse(encodedResponse);
        if(!this.isResponseValid(session,depResponse)){
            throw new Error("Bad Request");
        }
        return this.decoder.decryptBody(session,depResponse.Body);
    }

    isResponseValid(session, depResponse){

        return (this.isStatusValid(depResponse.ResponseHeader.StatusCode.Code)
                && this.isOidValid(
                    depResponse.Body.EncryptedContent.ContentType,
                    depResponse.Body.EncryptedContent.ContentEncryptionAlgorithm
                )
                && this.isMacValid(session, depResponse));
    }

    isStatusValid(statusCode){
        return (statusCode === NO_ERROR);
    }

    isOidValid(contentType, contentEncryptionAlgo){
        return (contentType === OIDs.GEMALTO_ID_OCTET_STRING)
                && (contentEncryptionAlgo === OIDs.AES128_CBC);
    }

    isMacValid(session,depResponse){

        const derivedMacKey = new DEPCrypto().deriveSecretKey(session, KEY_DERIVATION_OPTIONS.MAC_KEY,
            FRAME_DIRECTION.RESPONSE);
        const macInput = new DEPEncoder().encodeMacInputResponse(depResponse.ResponseHeader, depResponse.Body);
        const mac = new DEPCrypto().calculateMac(derivedMacKey, macInput);
        return mac === getbytesToHex(depResponse.MAC);
    }
}
