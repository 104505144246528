import React from 'react'
import Table from "../../../material-ui/table";
import { FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT, FILTER_VALUES_NAME_REPLACEMENT } from "../../../../variables/consult-info";
import { PackageContext } from "../package-context";
import Status from '../../status';
import { decodeImportedData } from 'utils';

function DocumentStatusTab(props) {
    const { _package } = React.useContext(PackageContext);

    let rows = (_package.documents || [])
        .filter((document) => document.files.length !== 0)
        .map((document) => {
            let decodedStatusDescription = document.statusDescription;
            if (typeof decodedStatusDescription === 'string' || decodedStatusDescription instanceof String)
                decodedStatusDescription = decodeImportedData(document.statusDescription);
            return {
                name: document.name,
                value: [FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[document.name] || document.name,
                <Status status_name={document.status} />,
                ((decodedStatusDescription) || "").replace(/\\n/g, "\n")]
            }

        });

    rows.sort((a, b) => a.value[0].localeCompare(b.value[0], 'fr', { ignorePunctuation: true }));

    return <Table rows={rows} />
}

export default DocumentStatusTab;