import Button, {BUTTON_STYLE} from '../../../../button';
import React, {useContext} from 'react';
import {TableContext} from '../context';
import {
    CONSULT_API_MAX_PAGE_SIZE,
    FILTER_KEYS_EXPORT_NAME_PLACEMENT,
} from '../../../../../variables/consult-info';
import {decodeImportedData, downloadFile} from '../../../../../utils';
import {getPackages, getSaleTypeForm} from '../../../../../api';
import {selectOption} from '../../../../../services/data-service';
import _ from 'lodash';
import {useLocation} from 'react-router-dom';
import {PROFILES} from '../../../detail/tab-list';
import {exportCsvHeaderValue} from "../../../../../config";
import {ABILITY} from '../../../../../variables/roles-permissions';
import Can from '../../../../permissions';
import {getExport} from '../../../../../api/index'
import {LiveTableContext} from "../../livetim-context";
import Tooltip from "@material-ui/core/Tooltip";
import {AppContext} from "../../../../app/context";


let hash = require('object-hash');

function DownloadLiveButtons() {

    const {data, filters, pageSize, page} = React.useContext(LiveTableContext);
    const {language, appmetadata} = useContext(AppContext);
    let location = useLocation();


    const downloadCsv = (data) => {
        const decodedData = decodeImportedData(data);
        downloadFile("data:application/csv;charset=UTF-8,%EF%BB%BF" + encodeURI(decodedData), 'pacotes.csv');
    };

    const isTimeSpanMoreThanAMonth = (filters) => {
        let creationEndDay = filters['creationEnd'].slice(0, 2)
        let creationEndMonth = filters['creationEnd'].slice(3, 5) - 1
        let creationEndYear = filters['creationEnd'].slice(6, 10)
        let creationStartDay = filters['creationStart'].slice(0, 2)
        let creationStartMonth = filters['creationStart'].slice(3, 5) - 1
        let creationStartYear = filters['creationStart'].slice(6, 10)
        let endDate = new Date(creationEndYear, creationEndMonth, creationEndDay)
        let startDate = new Date(creationStartYear, creationStartMonth, creationStartDay)
        let timeSpan = endDate - startDate;
        timeSpan = timeSpan / (1000 * 60 * 60 * 24);
        console.log(timeSpan)
        if (timeSpan > 30) {
            return true;
        }
        else {
            return false;
        }
    }

    const getLast30Days = (filters) => {
        let creationEndDay = filters['creationEnd'].slice(0, 2)
        let creationEndMonth = filters['creationEnd'].slice(3, 5) - 1
        let creationEndYear = filters['creationEnd'].slice(6, 10)
        let endDate = new Date(creationEndYear, creationEndMonth, creationEndDay)
        let last30days = new Date(endDate.setDate(endDate.getDate() - 30));
        let creationStart = last30days.toISOString().slice(0, 10);
        filters['creationStart'] = creationStart.slice(8, 10) + '/' + creationStart.slice(5, 7) + '/' + creationStart.slice(0, 4);
    }

    const getLast30DaysFromToday = (filters) => {
        let now = new Date();
        let nowFormat = now.toISOString().slice(0, 10);
        let last30days = new Date(now.setDate(now.getDate() - 30));
        let creationStart = last30days.toISOString().slice(0, 10);
        filters['creationEnd'] = nowFormat.slice(8, 10) + '/' + nowFormat.slice(5, 7) + '/' + nowFormat.slice(0, 4);
        filters['creationStart'] = creationStart.slice(8, 10) + '/' + creationStart.slice(5, 7) + '/' + creationStart.slice(0, 4);
    }

    const downloadTableCsv = (data, filters) => {
        if(filters['creationEnd'] && filters['creationStart']){
            if (isTimeSpanMoreThanAMonth(filters)){
                getLast30Days(filters)
            }
        }
        else if(filters['creationEnd']){
            getLast30Days(filters)
        }
        else{
            getLast30DaysFromToday(filters)
        }
        requestData(2000, 0, [], filters).then((data) => {
            downloadCsv(data);
        });
    };

    const generateSaleTypePayload = (packageInfo) => {
        const IDCOMBOS = Object.keys(packageInfo).filter((key) => {
            return key.match(/IDCOMBO/gi)
        });
        const CLIENTIDS = Object.keys(packageInfo).filter((key) => {
            return key.match(key.match(/CLIENTID/gi))
        });
        let payload = {
            USERID: packageInfo.USERID,
            ROLEID: packageInfo.ROLEID,
            CLIENTID: 1,
            SUBCLIENTID: 1,
        };
        IDCOMBOS.forEach((IDCOMBO) => {
            payload[IDCOMBO] = packageInfo[IDCOMBO]
        });
        CLIENTIDS.forEach((CLIENTID) => {
            payload[CLIENTID] = packageInfo[CLIENTID]
        });
        return payload
    };

    const addSaleType = (data) => {
        let payloadHashes = {};
        data.map((packageInfo) => {
            const payload = generateSaleTypePayload(packageInfo);
            const payloadHash = hash(payload);
            payloadHashes[payloadHash] = payload;
            packageInfo["Operation"] = payloadHash;
            return packageInfo;
        });

        return Promise.all(Object.keys(payloadHashes).map((hash) => {
            return getSaleTypeForm(payloadHashes[hash]).then(response => {
                return (response.fields || []).map((field) => {
                    return (field.options || [])
                        .filter(selectOption(field.name, payloadHashes[hash]))
                })
                    .map((option) => {
                        let value = Object.values(((option || []).pop() || {}));
                        value = (value || []).pop();
                        return value
                    }).join(' ');
            });
        })).then((responses) => {
            responses.map((_package, index) => {
                data.map((packageInfo) => {
                    let payload = generateSaleTypePayload(packageInfo);
                    const payloadHash = hash(payload);
                    payloadHashes[payloadHash] = payload;
                    packageInfo["Operation"] = packageInfo["Operation"] === Object.keys(payloadHashes)[index] ? _package : packageInfo["Operation"];
                    return packageInfo;
                });
            });
            return data;
        });
    };

    const downloadFullCsv = (filters) => {
        requestDataAll(filters).then((data) => {
                downloadCsv(data);
            }
        );
    };

    const requestDataAll = () => {
        let livePackage = false;
        if (location.pathname.split('/')[2] === PROFILES.LIVETIM) {
            livePackage = true;
        }
        return getExport({livePackage})
    };

    const requestData = (pageSize, page, sorted, filters) => {
        let livePackage = false;
        if (location.pathname.split('/')[2] === PROFILES.LIVETIM) {
            livePackage = true;
        }
        return getExport({pageSize, page, livePackage, ...filters})
    };

    return (<Can I={ABILITY.EXPORT_CSV}>
        <Button title={appmetadata.CONSULT.EXPORT_TABLE[language]} variant={BUTTON_STYLE.UNIMPORTANT}
                onClick={() => downloadTableCsv(data, filters)}>
            Exportar Conteúdo da Busca
        </Button>
        <Button title={appmetadata.CONSULT.EXPORT_ALL[language]} variant={BUTTON_STYLE.UNIMPORTANT}
                onClick={() => downloadFullCsv(filters)}>
            Exportar Conteúdo Completo
        </Button>
    </Can>);

}

export default DownloadLiveButtons;