import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Can from "../../permissions";
import {ABILITY} from "../../../variables/roles-permissions";
import {PATH} from "../../../routes/path-list";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from '@material-ui/core/IconButton';
import {AppContext} from "../../app/context";
import {useStyles} from "./icons-style";

export const RenderMenu = ({anchorEl, menuId, isMenuOpen, handleMenuClose}) => {
    let history = useHistory();
    const {language,appmetadata} = useContext(AppContext);

    return (<Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
    >
        <Can I={ABILITY.CREATE_PACKAGE}>
            <MenuItem key={"cadastro"} id={"register-package"}
                      onClick={() => {history.push(PATH.SALE_TYPE_FORM)}}
            >
                {appmetadata.HEADER.CREATE_PACKAGE[language]}
            </MenuItem>
        </Can>
        <Can I={ABILITY.READ_CONSUMER_PACKAGES}>
            <MenuItem key={"busca"} id={"search"} onClick={() => {history.push(PATH.PACKAGE)}}>
                {appmetadata.HEADER.SEARCH[language]}
            </MenuItem>
        </Can>
        <Can I={ABILITY.READ_CORPORATE_PACKAGES}>
            <MenuItem key={"busca_corp"} id={"search"} onClick={() => {history.push(PATH.CORPORATE_PACKAGE)}}>
                {"Busca Corporativa"}
            </MenuItem>
        </Can>
        <Can I={ABILITY.READ_TIMLIVE_PACKAGES}>
            <MenuItem key={"busca_timlive"} id={"search"} onClick={() => {history.push(PATH.TIMLIVE_PACKAGE)}}>
                {"Busca TIM Live"}
            </MenuItem>
        </Can>
        <Can I={ABILITY.READ_MESATHALES_PACKAGES}>
            <MenuItem key={"busca_mesathales"} id={"search"} onClick={() => {history.push(PATH.MESATHALES_PACKAGE)}}>
                {"Busca Mesa Thales"}
            </MenuItem>
        </Can>
    </Menu>)
};

export const MenuButton = ({menuId, handleNavigationMenuOpen}) => {
    const classes = useStyles();

    return(<IconButton
        edge="end"
        aria-label="navigation menu"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleNavigationMenuOpen}
        color="inherit"
        className={classes.icon}
    >
        <MenuIcon />
    </IconButton>)
};
