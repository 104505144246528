import {getDocument, getPdf, getZip} from '../../../../api';
import { addBase64Extension, downloadFile, base64Size } from '../../../../utils';
import { FileDisplayEditService } from '../../../../services/file-display-edit-service';
import { FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT } from '../../../../variables/consult-info';

export function download(filename, documentId, props){
  getDocument(props.info.id,documentId ).then((response) =>{
    downloadFile(addBase64Extension(response.file), filename);
  });
}

export function downloadFiles(document, props){
  document.files.forEach((file) => {
    download(document.name + props.info.id +file.fileType, file.fileId, props)
  })
}

export function downloadZip(props){
  getZip(props.info.id).then((response) =>{
    downloadFile(addBase64Extension(response.file), props.info.id+ "_files");
  });
}

export function downloadPdf(props){
  getPdf(props.info.id).then((response) =>{
    downloadFile(addBase64Extension(response.file), props.info.id+ "_files");
  });
}

export function openImageViewer (document, props) {
  Promise.all(document.files.map((file) => {return getDocument(props.info.id, file.fileId)}))
    .then((responses) =>{
      FileDisplayEditService.setFileList(responses.map(res => {
        return {
          image: res.file,
          size: base64Size(res.file)
        }
      }));
      FileDisplayEditService.setTitle(FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[document.name] || document.name);
    });
}