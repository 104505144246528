import React from "react";
import Field from "./field"
import MetadataLayout from "../form-input/layout-metadata"


export default function DisabledComponentField(props){
  return (<MetadataLayout {...props}>
      <Field {...props}/>
    </MetadataLayout>
  )
}