import React from 'react';
import MaUTable from '@material-ui/core/Table';
import TableBody from './body/body';
import { useBlockLayout, useResizeColumns, useTable } from 'react-table';
import TableHead from './header/header';
import TableFooter from './footer/footer';

function Table({ columns, data, context, isMesaThales }) {
    const defaultColumn = React.useMemo(
        () => ({
            width: 150,
            maxWidth: 400,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useBlockLayout,
        useResizeColumns
    );

    return (
      <div>
            <MaUTable {...getTableProps()} className="table" style={{'paddingLeft': '0'}}>
                <TableHead headerGroups={headerGroups}/>
                <TableBody rows={rows} prepareRow={prepareRow} getTableBodyProps={getTableBodyProps}/>
                <TableFooter pageSize={pageSize} pageIndex={pageIndex} count={data.length} context={context} isMesaThales={isMesaThales} />
            </MaUTable>
      </div>
    )
}

export default Table;
