import React from 'react';

export const EditableRowContext = React.createContext();

export function EditableRowContextProvider({children, onSave, onDelete}) {

  const [editing, setEditing] = React.useState(false);

  const saveRow = () => {

  };

  const deleteRow = () => {

  };

  return (<EditableRowContext.Provider value={{editing, setEditing, onSave, onDelete}}>
    {children}
  </EditableRowContext.Provider>)
}