import React from "react";
import {updateStatus} from '../../../api/'
import Grid from "@material-ui/core/Grid";
import {FormContextProvider} from "../dynamic-form/dynamic-form-context";
import SubmitButton from "../dynamic-form/dynamic-form-submit-button"
import Form from "../dynamic-form/dynamic-form-view"
import Status from '../../consult/status';
import {DataService} from '../../../services/data-service';
import {NOTIFICATION_VARIANT, NotificationService} from '../../../services/notification-service';
import {ModalService} from '../../../services/modal-service';
import {CODE} from "../../tdi/status";

export const translateCode = (code) => {
    let defaultError = "Atenção! Não foi possível atualizar o Status.\n" +
        "Entre em contato com o suporte informando os detalhes do pacote de documentação da venda e o código do erro: " + code;
    return (CODE[code] || defaultError)
};

const onSubmit = (reloadPackage) => (payload) => {
    updateStatus(payload).then(response => {
        let status = response.data.status;
        if (status === "200") {
            ModalService.close();
            reloadPackage();
        } else {
            ModalService.close();
            NotificationService.send(
                translateCode(status), 'error'
            );
        }
    }).catch(error => {
        ModalService.close();
        console.log(error);
        NotificationService.send(
            translateCode(error.response.status), 'error'
        );
    })
}

async function getStatusForm(currentPayload) {
    return {
        fields: [
            {
                "label": "ID do pacote",
                "name": "packageId",
                "required": false,
                "type": "hidden",
                "placeholder": currentPayload.packageId
            },
            {
                "label": "Justificativa",
                "name": "observations",
                "required": false,
                "type": "hidden",
                "placeholder": currentPayload.observations
            },
            {
                "label": "Status atual",
                "name": "oldStatus",
                "required": false,
                "type": "component",
                "component": <Status
                    showDescription={1}
                    status_name={(currentPayload && currentPayload.oldStatus)}/>,
                "disabled": true
            },
            {
                "label": "Novo status",
                "name": "status",
                "required": false,
                "type": "component",
                "component": <Status
                    showDescription={1}
                    status_name={(currentPayload && currentPayload.status)}/>,
                "disabled": true
            }
        ]
    }
}

function createPayload(currentPayload) {
    return {
        oldStatus: {value: currentPayload.oldStatus, valid: true},
        status: {value: currentPayload.status, valid: true},
    }
}

const StatusFormView = ({currentPayload, reloadPackage}) => {
    React.useEffect(() => {
        DataService.setPayloadToRetrieveFormInfo(currentPayload);
    });

    return (
        <Grid container spacing={0}>
            <FormContextProvider getFormInfo={getStatusForm} payload={createPayload(currentPayload)}>
                <Grid container item xs={12} spacing={0} alignItems={'center'} style={{"flexFlow": "column"}}>
                    <Form/>
                </Grid>
                <SubmitButton text={'Confirmar'} customSubmit={onSubmit(reloadPackage)}/>
            </FormContextProvider>
        </Grid>
    )
};

export default StatusFormView;
