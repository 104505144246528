import {HistoryService} from "../../services/history-service";
import React from 'react';
import {useLocation} from 'react-router-dom';
import {matchPathListHistory} from "../../routes/path-list";

export const HistoryTracker = () => {
    let location = useLocation();

    React.useEffect(() => {
        if (matchPathListHistory(location.pathname)){
            HistoryService.setHistory(location.pathname);
        }
    }, [location.pathname]);

    return null
};

export default HistoryTracker;