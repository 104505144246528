import React from 'react';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from 'react-keycloak';
import { KEYCLOAK } from '../variables/keycloak-const';
import { keycloakJsonConfigFile } from '../config';
import { keycloakService } from '../services/keycloak-service';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { authRequired } from "../config";
import { NotificationService, NOTIFICATION_VARIANT } from "../services/notification-service";

const keycloak = new Keycloak(keycloakJsonConfigFile);

try{
  let referer = sessionStorage.getItem('referer');
  sessionStorage.setItem('referer', 'auth/realms');
}catch(error) {
  //NotificationService.send(error && error.message, NOTIFICATION_VARIANT.ERROR);
  console.log(error && error.message);
}

class PersistedApp extends React.Component {
  constructor() {
    super();
    this.refreshTokenSignal = keycloakService
      .getRefreshTokenSignal()
      .subscribe(() => keycloak.updateToken(-1));

    this.logoutSignal = keycloakService
      .getLogoutSignal()
      .subscribe(() => keycloak.logout());
  }

  onKeycloakEvent = (event, error) => {
    console.log('onKeycloakEvent', event, error);
    switch (event) {
      case KEYCLOAK.EVENT.AUTH_SUCCESS: {
        const captureRole = (keycloak.tokenParsed.roles || [])
          .filter((role) => {
            return role.includes('capture');
          });
          
        if(captureRole.length > 0){
          const index = captureRole.indexOf("mobile_capture");
          if (index > -1) { // only splice array when item is found
            captureRole.splice(index, 1); // 2nd parameter means remove one item only
          } 
        }    
        let captureRoleString = captureRole.toString(); 
        DataService.setPayloadToRetrieveFormInfo({
          USERID: keycloak.tokenParsed.preferred_username,
          ROLEID: captureRole,
        });
        UserService.setRoles(keycloak.tokenParsed.roles);
        UserService.setUserId(keycloak.tokenParsed.preferred_username);
        UserService.setCaptureRole(captureRoleString);
        break;
      }
      default: {
      }
    }

    if(authRequired) {
      try{
        let referer = JSON.parse(sessionStorage.getItem('referer')) || [];
        let isReferedByKeycloak = referer.some( item => item.includes('auth/realms'));
        if(!isReferedByKeycloak) {
          keycloakService.logout();
        }
      }
      catch(error) {
        //NotificationService.send(error && error.message, NOTIFICATION_VARIANT.ERROR);
        console.log(error && error.message);
      }
    }
    
  };

  onKeycloakTokens = ({ idToken, refreshToken, token }) => {
    keycloakService.setToken(token);
    keycloakService.sendRefreshedTokenSignal();
  };

  componentWillUnmount() {
    this.refreshTokenSignal.unsubscribe();
    this.logoutSignal.unsubscribe();
  }

  render() {
    return (
      <KeycloakProvider
        keycloak={keycloak}
        onTokens={this.onKeycloakTokens}
        onEvent={this.onKeycloakEvent}
      >
        {this.props.children}
      </KeycloakProvider>
    );
  }
}

export default PersistedApp;