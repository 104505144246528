import React, {useState, useEffect} from "react";
import {DEFAULT_LANGUAGE} from "../../config"
import {getAppMetadata} from "api/index"
import {STRINGS} from "../../variables/display-strings"
export const AppContext = React.createContext({
    language: DEFAULT_LANGUAGE,
    appmetadata: STRINGS
});

export const AppContextProvider = (props) => {
    const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
    const [appmetadata, setAppMetadata] = useState(STRINGS);

    return (
        <AppContext.Provider value={{
            language: language,
            setLanguage: setLanguage,
            setAppMetadata: setAppMetadata,
            appmetadata: appmetadata
        }}>
            {(Object.keys(appmetadata).length !== 0) ? props.children : null}
        </AppContext.Provider>
    )
};

export const AppContextConsumer = AppContext.Consumer;