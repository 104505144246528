import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {PrepaidTableContext} from "../../../prepaid-context";

function TextFilter ({filterKey, label, button}){

  const [filterValue, setFilterValue] = React.useState();
  const {addFilter, setPage} = React.useContext(PrepaidTableContext);

  const handleChange = (event) => {
    setFilterValue(event.target.value)
  };

  const triggerFilter = () => {
    if(filterValue){
      addFilter(filterKey, filterValue);
      setPage(0)
      setFilterValue('')
    }
  };

  const handleKeyDown = function(event) {
    if (event.keyCode === 13 /*enter*/) {
      triggerFilter();
    }
  };

  return (<Grid container> <Grid xs={12} md={button? 6 : 12}>
    <TextField
    id={filterKey+'-filter'}
    value={filterValue}
    onChange={handleChange}
    onKeyDown={event => handleKeyDown(event)}
    label={label}
    fullWidth={1}
  />
  </Grid>
      {button? <Grid xs={12} md={6}><Button variant="contained" color="primary"
                       onClick={triggerFilter} >Filtrar</Button> </Grid>: null}
  </Grid>)
}

export default TextFilter;