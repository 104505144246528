import React, {useContext} from 'react';
import Input from "../form-input/input"
import {FileContext} from "./form-input-file-context";
import {AppContext} from "../../../app/context";

const FileInfoViewer = ({itemLimit, style}) =>{
    const {files, valid, validated} = useContext(FileContext);
    const {language,appmetadata} = useContext(AppContext);

    return <Input type="text" validated={validated} value={(files || []).length === 0 ? appmetadata.FORM.NO_FILE[language] : appmetadata.FORM.NUMBER_OF_ATTACHED_FILES[language](files.length)} disabled valid={valid} metadata={{type:"text"}} style={style}/>
};

export default FileInfoViewer;