import React, {useContext} from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {UserService} from "../../../services/user-service";
import Tooltip from "@material-ui/core/Tooltip";
import {AppContext} from "../../app/context";
import Button, {BUTTON_STYLE} from "../../button"
import {KEYCLOAK} from "../../../variables/api-path-list";
import {useStyles} from "./icons-style";

export default function UserMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <UserIconButton id={id} handleClick={handleClick}/>
            <UserPopover id={id} open={open} handleClose={handleClose} anchorEl={anchorEl}/>
        </div>
    );
}

function UserIconButton({id, handleClick}) {
    const classes = useStyles();
    const {language,appmetadata} = useContext(AppContext);

    return (<Tooltip title={appmetadata.HEADER.USER[language]}>
        <IconButton
            aria-describedby={id}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
            className={classes.icon}
        >
            <AccountCircle />
        </IconButton>
    </Tooltip>)
}

function UserPopover ({id, open, anchorEl, handleClose}) {
    const classes = useStyles();

    return (<Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
    >
        <Typography className={classes.typography}>{UserService.getUserId()}</Typography>
        <Button variant={BUTTON_STYLE.DEFAULT_PRIMARY} onClick={() => {window.location.href=KEYCLOAK.CHANGE_PASSWORD}}>Alterar Senha</Button>
    </Popover>)
}