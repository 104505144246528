import React from "react";
import ShowImagesButton from "../form-input-file/show-images-button"
import FileInfoViewer from "../form-input-file/form-input-files-information"
import DocumentLayout from "../form-input/layout-documents"
import {FileContextProvider, FileContextConsumer} from "../form-input-file/form-input-file-context"
import Feedback from "../form-input-file/feedback"
import ScanButton from "../form-input-scanner/form-input-scanner";
import FileInput from "../form-input-file/form-input-file-view";

const FileScannerField = (props) =>{
    return (
        <FileContextProvider metadata={props.metadata}>
            <DocumentLayout metadata={props.metadata}>
                <FileInput metadata={props.metadata} />
                <FileContextConsumer>
                    {({handleChange}) =>
                        <ScanButton metadata={props.metadata} handleChange={handleChange}/>
                    }
                </FileContextConsumer>
                <FileInfoViewer itemLimit={(props.metadata.itemLimit || 1)}
                                metadata={props.metadata}
                                style={{"width": "44%", "marginLeft": "-1px"}}
                />
                <ShowImagesButton metadata={props.metadata}/>
                <Feedback/>
            </DocumentLayout>
        </FileContextProvider>
    )
};

export default FileScannerField;
