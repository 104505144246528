import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useLocation } from "react-router-dom";
import { pathTitle } from "../../../routes/path-list";

const SimpleBreadcrumbs = ({ crumbs, goTo }) => {
  let location = useLocation();

  return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {(crumbs || []).map((crumb, index) => {
          if (crumbs.length === index + 1 && location.pathname === crumb) {
            return (
              <Typography color="textPrimary">
                {pathTitle(location.pathname)}
              </Typography>
            );
          }
          return (
            <Link component="button" onClick={() => goTo(crumb)}>
              {" "}
              {pathTitle(crumb)}
            </Link>
          );
        })}
        {location.pathname === crumbs[crumbs.length - 1] ? null : (
          <Typography color="textPrimary">
            {pathTitle(location.pathname)}
          </Typography>
        )}
      </Breadcrumbs>
  );
};

export default SimpleBreadcrumbs;
