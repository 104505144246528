import React from 'react';

class HiddenFormField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.setFormValues(this.props.metadata.name, this.props.metadata.placeholder, true)
    }

    render() {
        return (
            null
        );
    }
}


export default HiddenFormField;