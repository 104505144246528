import NumberFormat from "react-number-format";
import React from "react";
import Input from "../form-input/input"
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context";
import {substringWithNOccurrencesOfCharacter} from "../../../../utils";

const CELLPHONE_MASK = "(##) #####-####";
const PHONE_MASK= "(##) ####-####";

const matchRegEx = (string, pattern) => {
    const regex = new RegExp('^([1-9]{2})(([2-8][0-9]{7})|(9[0-9]{8}))$');
    return regex.test(string);
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    setFormValues(metadata.name, event.target.value.replace(/\D/g,''),
        matchRegEx(event.target.value.replace(/\D/g,''), metadata.regexp));
};

const selectMask = (formValues, metadata) => {
    const phone = ((formValues[metadata.name] || {})["value"] || '');
    if (phone.length <= 2) {
        return "###########"
    }
    else if (phone.charAt(2) === "9"){
        return substringWithNOccurrencesOfCharacter(CELLPHONE_MASK, "#", phone.length+1);
    }
    else{
        return substringWithNOccurrencesOfCharacter(PHONE_MASK, "#", phone.length+1);
    }
};

export default function Telephone({metadata}){
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false)
            fieldDisabled = true;
    }
    return (<FormContextConsumer>
        {({ setFormValues, formValues }) => (
            <NumberFormat customInput={Input}
                          id={metadata.name}
                          metadata={metadata}
                          format={selectMask(formValues, metadata)}
                          mask={" "}
                          valid={matchRegEx(((formValues[metadata.name] || {})["value"] || ''), metadata.regexp) || !Boolean(metadata.required)}
                          onChange={onChange(setFormValues, formValues, metadata)}
                          isNumericString={true}
                          type={'tel'}
                          value={((formValues[metadata.name] || {})["value"] || '')}
                          disabled={fieldDisabled}
            />
        )}
    </FormContextConsumer>)
}
