import NumberFormat from "react-number-format";
import React from "react";
import Input from "../form-input/input"
import {FormContext} from "../../dynamic-form/dynamic-form-context";
import {TestaCPF, substringWithNOccurrencesOfCharacter, longestString, closestSizeString} from "../../../../utils"

const onChange = (setFormValues, formValues, metadata) => (event) => {
    setFormValues(metadata.name, event.target.value.replace(/\D/g,''), TestaCPF(event.target.value.replace(/\D/g,'')));
};

const selectMask = (formValues, metadata) => {
    const id = ((formValues[metadata.name] || {})["value"] || '');
    const masks = "###.###.###-##".split("|");
    //const masks = "###.###.###-##|##.###.###/####-##".split("|");
    const longestMask = longestString(masks);
    const closestMask = closestSizeString(masks, id.length);
    let initialMask = '#'.repeat(longestMask.split('#').length -1);
    const mask = longestMask === closestMask ? longestMask : closestMask + "#";
    if (id.length === 0) {
        return initialMask;
    }
    else {
        return substringWithNOccurrencesOfCharacter(mask,'#', id.length+1);
    }
};

export default function Cpf({metadata}){
    const { setFormValues, formValues } = React.useContext(FormContext);
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false)
            fieldDisabled = true;
    }

    return (<NumberFormat customInput={Input}
                          id={metadata.name}
                          format={selectMask(formValues, metadata)}
                          metadata={metadata}
                          mask={" "}
                          valid={TestaCPF(((formValues[metadata.name] || {})["value"] || '')) || !Boolean(metadata.required)}
                          onChange={onChange(setFormValues, formValues, metadata)}
                          isNumericString={true}
                          value={((formValues[metadata.name] || {})["value"] || '')}
                          disabled={fieldDisabled}
            />)
}
