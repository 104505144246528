import React from 'react';
import { useKeycloak } from 'react-keycloak';
import {PATH} from '../../routes/path-list';
import { withRouter } from 'react-router-dom';
import {authRequired, keycloakRedirectLogin} from "../../config"
import Loading from '../../components/fallback'

export const PrivateRoutes = (props) => {
    const { keycloak, initialized } = useKeycloak();

    if (!initialized && authRequired) {
        return <Loading/>;
    }

    if (!keycloak.authenticated && initialized && authRequired){

        keycloakRedirectLogin ? keycloak.login().then(authenticated => {
            this.setState({ keycloak: keycloak, authenticated: authenticated });
        }): props.history.push(PATH.LOG_IN);
    }

    return (
        <div>
            {props.children}
        </div>
    )
};

export default withRouter(PrivateRoutes);
