export class Session {
    sessionId;
    sequenceNumber;
    dataKey;
    macKey;
    constructor(sessionId, sequenceNumber,
        dataKey, macKey) {
        this.sessionId = sessionId;
        this.sequenceNumber = sequenceNumber;
        this.dataKey = dataKey;
        this.macKey = macKey;
    }

    get SessionId() {
        return this.sessionId;
    }
    get DataKey() {
        return this.dataKey;
    }
    get MacKey() {
        return this.macKey;
    }
    get SequenceNumber() {
        return this.sequenceNumber;
    }

    increaseSequenceNumber() {

        const tempSequenceNumber = [...this.SequenceNumber];
        let carry = 1;
        let i = tempSequenceNumber.length - 1;
        while (i >= 0 && carry !== 0) {
            const value = (tempSequenceNumber[i] & 0xff) + carry;
            tempSequenceNumber[i] = value;
            carry = value >>> 8;
            i--;
        }
        let reserved = true;
        i = 0;
        while (reserved && i < tempSequenceNumber.length) {
            reserved = tempSequenceNumber[i] === 0xFF;
            i++;
        }
        if (reserved) {
            tempSequenceNumber.fill(0);
        }
        this.sequenceNumber = new Int8Array(tempSequenceNumber);
    }
}
