import Chips from '../../../../../material-ui/chips/chips';
import React from 'react';
import { MesaThalesTableContext } from '../../../mesa-thales-context';

function FilterChips() {

    const { filters, deleteFilter } = React.useContext(MesaThalesTableContext);

    return <Chips onDelete={deleteFilter} values={filters} />
}

export default FilterChips;