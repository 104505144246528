import React from 'react';
//import Modal from './material-ui-dialog';
import Modal from './mui-dialog';
//import Modal from './bootstrap-modal';
import { ModalService } from '../../services/modal-service';

const GenericModal = () => {
  const [state, setState] = React.useState({
    show: false,
    title: '',
    text: '',
    textBottom: '',
    closeButton: false,
    spinner: false,
    buttons: null,
    maxWidth: 'xs'
  });

  React.useEffect(() => {
    const modalSubscription = ModalService.getChanges().subscribe(
      (newState) => {
        setState({ ...state, ...newState });
      }
    );
    return modalSubscription.unsubscribe;
  }, []);

  return (
    <Modal state={state}>
    </Modal>
  );
};

export default GenericModal;
