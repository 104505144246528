import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { MesaThalesTableContext } from "../../../mesa-thales-context";
import { PHASES_FILTER_LIST } from "../../../../../../variables/mesa-thales-filters";


function PhaseFilter({ label, filterKey }) {

    const [phaseList, setPhaseListList] = React.useState([]);
    const { filters, addFilter, setPage } = React.useContext(MesaThalesTableContext);

    React.useEffect(() => {
        setPhaseListList(PHASES_FILTER_LIST);
        addFilter(filterKey, PHASES_FILTER_LIST[0].value);
    }, []);

    const handleChange = (event) => {
        addFilter(filterKey, event.target.value);
        setPage(0);
    };

    return (<TextField
        id="phase-filter"
        select
        label={label}
        value={filters.phase}
        onChange={handleChange}
        fullWidth={1}
    >
        {phaseList.map((phase) => (
            <MenuItem key={phase.value} value={phase.value}>
                {phase.value}
            </MenuItem>
        ))}
    </TextField>)
}

export default PhaseFilter;