import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import {requestNotificationPermission} from "./push-notification";
import { fcmService } from "../../services/fcm-service";
import {PATH} from "../../routes/path-list";

const NotificationPermissionButton = ({children}) => {
    let history = useHistory();

    useEffect(() => {
        const tokenSubscription = fcmService.getFirebaseCloudMessageToken()
            .subscribe((token) => {
                if (token){
                    history.push(PATH.SALE_TYPE_FORM)
                }
            });
        return () => {
            tokenSubscription.unsubscribe()
        }
    }, []);

    return ( <Button onClick={() => requestNotificationPermission(history, fcmService.refreshFirebaseCloudMessageToken)}>{children}</Button>)
};

export default NotificationPermissionButton