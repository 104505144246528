import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        maxHeight: '100%',
        overflowY: 'auto',
    },
    table: {
        width: '100% !important',
    },
}));


export default function SimpleTable({header, rows}) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                {header ? <TableHead>
                    <TableRow>
                        {header.map((title) => {
                            return <TableCell key={Math.random()}
                                              style={{"whiteSpace": "pre-line"}}>
                                {title}
                            </TableCell>
                        })}
                    </TableRow>
                </TableHead> : null}
                <TableBody>
                    {(rows || []).map(row => (
                        <TableRow key={row.name}>
                            {row.value.map((field) => {
                                return <TableCell key={Math.random()}
                                                  style={{"whiteSpace": "pre-line"}}>
                                    {field}
                                </TableCell>
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}