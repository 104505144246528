import React from "react";
import RadioButtons from "./radio-buttons";
import MetadataLayout from "../form-input/layout-metadata";


export default function RadioButtonsGroup(props) {
    return (
        <MetadataLayout {...props}>
            <RadioButtons {...props} />
        </MetadataLayout>
    )
}