import Chips from '../../../../../material-ui/chips/chips';
import React from 'react';
import {LiveTableContext} from "../../../livetim-context";

function FilterChips() {

  const {filters, deleteFilter} = React.useContext(LiveTableContext);

  return <Chips onDelete={deleteFilter} values={filters}/>
}

export default FilterChips;


