
export class DEPResponse{
    responseHeader;
    body;
    mac;

    constructor(responseHeader, body, mac){
        this.responseHeader = responseHeader;
        this.body = body;
        this.mac = mac;
    }

    get ResponseHeader(){
        return this.responseHeader;
    }

    get Body(){
        return this.body;
    }

    get MAC(){
        return this.mac;
    }

}
