import React from 'react';
import MetadataLayout from '../form-input/layout-metadata'
import './form-input-datepicker-style.css'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {FormContext} from "../../dynamic-form/dynamic-form-context";
import { ptBR } from "date-fns/locale";
import 'moment-timezone';

var moment = require('moment-timezone');

const  chooseClassName = (valid, validated) =>{
    if (valid && validated){
        return "datepicker form-field-input--valid"
    }
    else if (!valid){
        return "datepicker form-field-input--invalid"
    }
    else{
        return "datepicker form-field-input"
    }
};

const FormDatepicker = (props) => {

    const [startDate, setStartDate] = React.useState(new Date());
    const { setFormValues, formValues, validated } = React.useContext(FormContext);

    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (props.metadata.updatable === false)
            fieldDisabled = true;
    }

    const dateToddmmyyyFormat = (date) => {
        let dateNoon = date.setHours(0,0,1);
        setStartDate(date);
        let dateFormatddmmyyyy = moment(dateNoon).format('DD/MM/YYYY');
        return dateFormatddmmyyyy;
    };

    React.useEffect( () => {
        if (formValues[props.metadata.name]){
            const date = formValues[props.metadata.name]['value'];
            setStartDate(new Date(date.split('/')[2], date.split('/')[1] - 1, date.split('/')[0]))
        }
        else{
            let dateFormatddmmyyyy  = dateToddmmyyyFormat(startDate);
            setFormValues(props.metadata.name, dateFormatddmmyyyy, true)
        }
    }, []);

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d) && d <= new Date();
    };

    const handleChange = date => {
        let dateNoon = date.setHours(0,0,1);
        setStartDate(date);
        let dateFormatddmmyyyy = moment(dateNoon).format('DD/MM/YYYY');
        if (isValidDate(date)){
            setFormValues(props.metadata.name, dateFormatddmmyyyy, true)
        }
        else{
            setFormValues(props.metadata.name, "", !props.metadata.required)
        }
    };

    return (
        <MetadataLayout metadata={props.metadata}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                    className={chooseClassName((formValues[props.metadata.name]|| {})['valid'], validated)}
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label=''
                    autoOk={true}
                    value={startDate}
                    onChange={handleChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    disableFuture={true}
                    invalidDateMessage={"Data Inválida"}
                    maxDateMessage={"Datas futuras não são permitidas"}
                    disabled={fieldDisabled}
                />
            </MuiPickersUtilsProvider>
        </MetadataLayout>
    );

};

export default FormDatepicker;