import React from "react";
import {FormContext} from '../../dynamic-form/dynamic-form-context';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function DisabledComponentField({metadata}){

  const style = {
    border: "1px solid #B5B5B5",
    height: "32px",
    width: "100%",
    'border-radius': '2px',
    'margin-bottom': '20px',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    'text-align': '-webkit-left',
    'padding-left': '10px',
  };

  const {formValues, setFormValues} = React.useContext(FormContext);

  const handleChange = (event) => {
    setFormValues(metadata.name, event.target.value, 1)
  };

  return <Select
    style={style}
    value={((formValues[metadata.name] || {})["value"] || '')}
    onChange={handleChange}
  >
    {metadata.options.map((component) =>{
      return <MenuItem value={Object.keys(component)[0]}>{Object.values(component)[0]}</MenuItem >
    })}
  </Select>
}
