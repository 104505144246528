import {ROLES_ABILITIES} from "../../variables/roles-permissions"
import {userPermissionRequired} from "../../config"
import { UserService } from "../../services/user-service"

export const checkPermisions = (permissions, request, roles) => {
    if (!permissions || !permissions[request] || !request || !roles || !roles.length){
        return false
    }
    return permissions[request].some((role) =>{
        return roles.includes(role)
    }) || !userPermissionRequired
};

export const isUserAllowed = (request) => {
    const roles = UserService.getRoles();
    return checkPermisions(ROLES_ABILITIES, request, roles)
};

export default (props) => {
    const roles = UserService.getRoles();
    return checkPermisions(ROLES_ABILITIES, props.I, roles) ? props.children : null;
}