import React from 'react'

export const Context = React.createContext(null);

const ContextProvider = ({children, request}) => {

    const [response, setResponse] = React.useState({});
    const [requestTrigger, setRequestTrigger] = React.useState(false);

    const triggerRequest = () => {
        setRequestTrigger(!requestTrigger)
    };

    React.useEffect(() => {
        request().then((resp)=>{
            setResponse(resp)
        })
    }, [requestTrigger]);

    return <Context.Provider value={{response: response, triggerRequest: triggerRequest}}>
        {children}
    </Context.Provider>
};

export const ContextConsumer = Context.Consumer;

export default ContextProvider;
