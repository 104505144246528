import React from "react";
import "./form-input-style.css"
import Grid from '@material-ui/core/Grid';

export default (props) => {
    return (
        <Grid item xs={12} className={"form-field"} >
            <div className={"form-input-label"}>
                {props.metadata.label}{props.metadata.required ? "*" : ""}
            </div>
            <div className={"input-group"}>
                {props.children}
            </div>
        </Grid>
    )
}