import React from "react";
import Input from "../form-input/input"
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context";
import MetadataLayout from "../form-input/layout-metadata";

const matchRegEx = (string, pattern) => {
    const regex =  Boolean(pattern) ? new RegExp(pattern) : new RegExp(".+");
    return regex.test(string);
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    setFormValues(metadata.name, event.target.value, matchRegEx(event.target.value, metadata.regexp) || (!Boolean(event.target.value) && !Boolean(metadata.required)));
};

const isValid = (value, metadata) => {
    return value ? matchRegEx(value, metadata.regexp) : !Boolean(metadata.required);
};

export default function Text(props){
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (props.metadata.updatable === false)
            fieldDisabled = true;
    }
    const {metadata} = props;
    return (
        <MetadataLayout {...props}>
        <FormContextConsumer>
        {({ setFormValues, formValues }) => (
            <Input id={metadata.name}
                   metadata={metadata}
                   valid={isValid(((formValues[metadata.name] || {})["value"] || ''), metadata)}
                   onChange={onChange(setFormValues, formValues, metadata)}
                   type={"text"}
                   value={((formValues[metadata.name] || {})["value"] || '')}
                   disabled={fieldDisabled}
            />
        )}
    </FormContextConsumer>
        </MetadataLayout>
    )
}