import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {PrepaidTableContext} from "../../../prepaid-context";
import { ptBR } from "date-fns/locale";


function FormDateRangePicker({ label, filterKey }) {

  const {filters, addFilter, setPage} = React.useContext(PrepaidTableContext);

  const handleDateChange = (value) => {
    if (value instanceof Date && !isNaN(value)) {
      let dateFormat = value.toISOString().slice(0, 10);
      let dateFormatddmmyyyy = dateFormat.slice(8, 10) + '/' + dateFormat.slice(5, 7) + '/' + dateFormat.slice(0, 4);
      addFilter(filterKey, dateFormatddmmyyyy);
      setPage(0)
    }
  };

  const convertDateFormatddmmyyyytoDate = (ddmmyyyy) => {
    if (ddmmyyyy && ddmmyyyy.length === 10){
      return Date.parse(ddmmyyyy.slice(3, 5) + '/' + ddmmyyyy.slice(0, 2) + '/' + ddmmyyyy.slice(6, 10));
    }
    return null;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} className={'coisas'} locale={ptBR}>
      <KeyboardDatePicker
        clearable
        label={label}
        placeholder="10/10/2018"
        value={convertDateFormatddmmyyyytoDate(filters[filterKey])}
        onChange={date => handleDateChange(date)}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormDateRangePicker;