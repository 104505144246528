export const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    textAlign: 'center',
    minWidth: 'min-content'
  },
  title:{
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    padding: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  contentChildren:{
    padding: theme.spacing(1),
  }
});