export class Keys {
    dataKey;
    macKey;
    constructor(dataKey, macKey){
        this.dataKey = dataKey;
        this.macKey = macKey;
    }

    get DataKey() {
      return this.dataKey
    }

    get MacKey() {
      return this.macKey
    }
}
