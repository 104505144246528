import React from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MediaFactory from './media-components/media-factory';


const Carousel = ({ fileList, shiftLeft, shiftRight, index }) => {


  return (<><Grid container direction="row"
                  justify="center"
                  alignItems="stretch"
                  style={{ height: '100%' }}
  >
    {fileList.length === 0 ? <Grid container item xs={12}>
      <div style={{
        alignSelf: 'center', marginLeft: 'auto',
        marginRight: 'auto'
      }}>Nenhum arquivo para ver...
      </div>
    </Grid> : <><Grid container item xs={1}>
      <Button onClick={shiftLeft}>
      <KeyboardArrowLeftIcon fontSize="large" style={{ alignSelf: 'center' }}/>
      </Button>
    </Grid>
      <Grid container item xs={10} style={{margin: 'auto', maxHeight:'100%', justifyContent: 'center'}}>
        <MediaFactory file={fileList && fileList[index] ? fileList[index]: null}/>
      </Grid>
      <Grid container item xs={1}>
        <Button onClick={shiftRight}>
        <KeyboardArrowRightIcon fontSize="large" style={{ alignSelf: 'center' }}/>
        </Button>
      </Grid>
    </>}
  </Grid>
</>);

};

export default Carousel;



