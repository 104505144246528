import React, { useState, useEffect } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import {FILTER_VALUES_NAME_REPLACEMENT} from "../../../variables/consult-info";
import SaleType from 'components/consult/sale-type';
import { take } from 'rxjs/operators';
import { DataService } from 'services/data-service';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(1),
    },
    operationChip: {
        display: 'inline-block'
    }
}));

export default function Chips({values, onDelete}) {
    const classes = useStyles();
    const [dropdownValues, setDropdownValues] = useState({});
    
    function handleDelete(value) {
        if (value !== 'phase') {
            onDelete(value);
        } else {
            alert("O filtro da fase do projeto não pode ser excluído!");
        }
    }

    useEffect(() => {
        const getPayloadSubscription = DataService.getPayloadToRetrieveFormInfo().pipe(
            take(1)
        ).subscribe((payload) => {
            setDropdownValues(payload);
        });
        return () => {
            getPayloadSubscription.unsubscribe()
        }
    }, []);

    return (
        <div className={classes.root}>
            {Object.keys(values).map((key) => {
                return (<Grid>
                    { key != 'operation' ? <Chip
                        onDelete={() => handleDelete(key)}
                        className={classes.chip}
                        key={key}
                        label={`${key}:${values[key]}`}>{Object.keys(FILTER_VALUES_NAME_REPLACEMENT).includes(values[key]) ? FILTER_VALUES_NAME_REPLACEMENT[values[key]] : values[key]}
                    </Chip>
                    : <Chip
                        onDelete={() => handleDelete(key)}
                        className={classes.chip}
                        key={key}
                        label={<span>Operação: <SaleType className={classes.operationChip} {...dropdownValues} /></span>}>
                    </Chip>}
                </Grid>    
                )
            })}
        </div>
    );
}

