import React from 'react';
import { useHistory } from 'react-router-dom';
import { getDocument } from '../../../api';
import Can from '../../../components/permissions';
import { PATH } from '../../../routes/path-list';
import { DataService } from '../../../services/data-service';
import { ALLOWED_UPDATE_PACKAGE_STATUS, UNALLOWED_UPDATE_PACKAGE_ROLEIDS } from '../../../variables/consult-info';
import { GET_DOCS_PAYLOAD_KEYS, PACKAGE_ID, USER_ID } from '../../../variables/document-matrix';
import { ABILITY } from '../../../variables/roles-permissions';
import Button, { BUTTON_STYLE } from '../../button';
import { METADATA_NAME } from '../../tdi/status';
import { PackageContext } from './package-context';
import { UserService } from '../../../services/user-service';
import {base64Size} from '../../../utils';

export const payloadToRetrieveFormInfo = (_package) => {
  return Object.values(GET_DOCS_PAYLOAD_KEYS).reduce((payload, key) => {
    if (_package.info.hasOwnProperty(key)) {
      payload[key] = _package.info[key];
    }
    payload[PACKAGE_ID] = _package.info.id;
    payload[USER_ID] = UserService.getUserId();
    return payload;
  }, {});
};

export const objectKeysToUpperCase = (obj) => {
  return Object.keys(obj).reduce((c, k) => {
    return (
      (c[k.toUpperCase() === 'ID' ? 'PACKAGE-ID' : k.toUpperCase()] = obj[k]), c
    );
  }, {});
};

const updatePackage = (_package, history) => {
  const packageId = _package.info.id;
  const search = `?${METADATA_NAME.PACKAGE_ID}=${packageId}`;
  DataService.setPayloadToRetrieveFormInfo(payloadToRetrieveFormInfo(_package));
  delete _package.info.id;
  history.push({
    pathname: PATH.DOCUMENTS_FORM,
    search: search,
    state: {
      ...objectKeysToUpperCase(_package.info),
        package: _package,
        packageId: packageId,
    },
  });
};

const UpdatePackageButton = () => {
  let history = useHistory();
  const { _package } = React.useContext(PackageContext);

  if (
    ALLOWED_UPDATE_PACKAGE_STATUS.includes((_package.info || {}).status) &&
    !UNALLOWED_UPDATE_PACKAGE_ROLEIDS.includes((_package.info || {}).ROLEID)
  ) {
    return (
      <Can I={ABILITY.UPDATE_PACKAGE}>
        <Can I={ABILITY.CREATE_PACKAGE}>
          <Button
            variant={BUTTON_STYLE.NEGATIVE_SECONDARY}
            onClick={() => updatePackage(_package, history)}
          >
            {' '}
            Atualizar Pacote{' '}
          </Button>
        </Can>
      </Can>
    );
  } else {
    return null;
  }
};

export const retrieveDocuments = (_package, packageId) => {
  return Promise.all(
    _package.documents.map((document) => {
      return Promise.all(
        document.files.map((file) => getDocument(packageId, file.fileId))
      ).then((responses) => {
        return {
          name: document.name,
          value: responses.map((res) =>
          {
            return {
              image: res.file,
              size: base64Size(res.file)
            }
          }
          ),
        };
      });
    })
  );
};

export const arrayOfObjectsToObject = (array) => {
  return array.reduce((obj, item) => {
    obj[item.name] = item.value;
    return obj;
  }, {});
};

export default UpdatePackageButton;
