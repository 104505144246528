import React from 'react'
import LiveTimTable from './livetim-table'
import FilterChips from './table/filters/livetim-filters/chips'
import FilterDate from './table/filters/livetim-filters/date'
import FilterList from './table/filters/livetim-filters/list'
import Grid from '@material-ui/core/Grid';
import DownloadLiveButtons from './table/footer/download-livetim-button';
import TextFilter from './table/filters/livetim-filters/text';
import StatusFilter from './table/filters/livetim-filters/status';

const filterList = [
    {
        "value": "cpf",
        "label": "CPF",
        "component": <TextFilter label={"Valor"} filterKey={"cpf"} button={1}/>,
    },
    {
        "value": "CNPJ",
        "label": "CNPJ",
        "component": <TextFilter label={"Valor"} filterKey={"CNPJ"} button={1}/>,
    },
    {
        "value": "SOCIO-DOC",
        "label": "Documento Sócio",
        "component": <TextFilter label={"Valor"} filterKey={"SOCIO-DOC"} button={1}/>,
    },
    {
        "value": "status",
        "label": "Status",
        "component": <StatusFilter/>,
    },
    {
        "value": "userid",
        "label": "Usuário",
        "component": <TextFilter label={"Valor"} filterKey={"userid"} button={1}/>,
    },
    {
        "value": "LIVE-PROTOCOLO",
        "label": "Protocolo Live",
        "component": <TextFilter label={"Valor"} filterKey={"LIVE-PROTOCOLO"} button={1}/>,
    },
];

const LiveTimConsultList = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                Data de criação:
            </Grid>
            <Grid item xs={12}>
                <FilterDate filterKey={'creationStart'} label={'De'}/>
                <FilterDate filterKey={'creationEnd'} label={'Até'}/>
            </Grid>
            <Grid item xs={12}>
                <FilterList filterList={filterList}/>
            </Grid>
            <Grid item xs={12}>
                <FilterChips/>
            </Grid>
            <Grid item xs={12} style={{ overflow: 'auto' }}>
                <LiveTimTable/>
            </Grid>
            <Grid item xs={12}>
                <DownloadLiveButtons/>
            </Grid>
        </Grid>
    )
};

export default LiveTimConsultList;