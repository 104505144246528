import React from 'react';
import {getKeys} from '../../api';
import {keysService} from '../../services/keys-service';

export function KeysProvider(){

  React.useEffect(() =>{
    getKeys().then((keys) =>{
      keysService.setKeys(keys.keys)
    })
  });

  return null;
}