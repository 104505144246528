import {makeStyles} from "@material-ui/core";
import {THEME, THEME_CONFIG} from "../../config";

export const useStyles = makeStyles(theme => ({
    header:{
        background: THEME_CONFIG[THEME].HEADER_BACKGROUND_COLOR
    },
    grow: {
        flexGrow: 1,
        height: '9vh',
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    section:{
        display: 'flex'
    }
}));