import { matchPath } from "react-router-dom";

export const PATH = {
  INVALID: "/invalid/",
  HOME: "/",
  LOG_IN: "/login/",
  SALE_TYPE_FORM: "/sale-type/",
  DOCUMENTS_FORM: "/capture/",
  NOTIFICATION_PERMISSION: "/notification/",
  NOTIFICATION_PERMISSION_RETRY: "/notification/retry/",
  PACKAGE: "/package/",
  PACKAGE_DETAILS: "/package/consumer/:packageId",
  PACKAGE_DETAILS_CREATION: "/package/consumer/:scenarioId",
  CORPORATE_PACKAGE: "/package/corporate/",
  CORPORATE_PACKAGE_DETAILS: "/package/corporate/:packageId",
  TIMLIVE_PACKAGE: "/package/timlive/",
  TIMLIVE_PACKAGE_DETAILS: "/package/timlive/:packageId",
  PREPAID_PACKAGE: "/package/prepaid/",
  PREPAID_PACKAGE_DETAILS: "/package/prepaid/:packageId",
  MESATHALES_PACKAGE: "/package/mesathales/",
  MESATHALES_PACKAGE_DETAILS: "/package/mesathales/:packageId",
  ERROR503: "/error/",
  END: "/end/",
};

export const PATH_TITLES = {
  [PATH.HOME]: "Home",
  [PATH.SALE_TYPE_FORM]: "Criar Pacote",
  [PATH.DOCUMENTS_FORM]: "Cadastro de Documentos",
  [PATH.PACKAGE]: "Busca",
  [PATH.PACKAGE_DETAILS]: "Pacote",
  [PATH.CORPORATE_PACKAGE]: "Busca Corporativa",
  [PATH.CORPORATE_PACKAGE_DETAILS]: "Pacote Corporativo",
  [PATH.TIMLIVE_PACKAGE]: "Busca TIM Live",
  [PATH.TIMLIVE_PACKAGE_DETAILS]: "Pacote TIM Live",
  [PATH.PREPAID_PACKAGE]: "Busca Pré-pago",
  [PATH.PREPAID_PACKAGE_DETAILS]: "Pacote Pré-pago",
  [PATH.MESATHALES_PACKAGE]: "Busca Mesa Thales",
  [PATH.MESATHALES_PACKAGE_DETAILS]: "Pacote Mesa Thales",
  [PATH.NOTIFICATION_PERMISSION]: "Permitir Notificações",
  [PATH.NOTIFICATION_PERMISSION_RETRY]: "Permitir Notificações",
};

export const PATH_LIST_HISTORY = {
  [PATH.HOME]: PATH.HOME,
  [PATH.SALE_TYPE_FORM]: PATH.SALE_TYPE_FORM,
  [PATH.PACKAGE]: PATH.PACKAGE,
  [PATH.PACKAGE_DETAILS]: PATH.PACKAGE_DETAILS,
  [PATH.CORPORATE_PACKAGE]: PATH.CORPORATE_PACKAGE,
  [PATH.CORPORATE_PACKAGE_DETAILS]: PATH.CORPORATE_PACKAGE_DETAILS,
  [PATH.TIMLIVE_PACKAGE]: PATH.TIMLIVE_PACKAGE,
  [PATH.TIMLIVE_PACKAGE_DETAILS]: PATH.TIMLIVE_PACKAGE_DETAILS,
  [PATH.PREPAID_PACKAGE]: PATH.PREPAID_PACKAGE,
  [PATH.PREPAID_PACKAGE_DETAILS]: PATH.PREPAID_PACKAGE_DETAILS,
  [PATH.MESATHALES_PACKAGE]: PATH.MESATHALES_PACKAGE,
  [PATH.MESATHALES_PACKAGE_DETAILS]: PATH.MESATHALES_PACKAGE_DETAILS,
};

export const matchPathListHistory = (path) => {
  return Object.keys(PATH_LIST_HISTORY).some((key) => {
    return matchPath(path, { path: key, exact: true });
  });
};

export const pathTitle = (path) => {
  const matched_path = Object.keys(PATH_TITLES).filter((key) => {
    return matchPath(path, { path: key, exact: true });
  });
  return PATH_TITLES[matched_path && matched_path[0]];
};
