import React from 'react';
import {keycloakService} from "../../services/keycloak-service";
import {ModalService} from "../../services/modal-service";
import {idletimeoutBreakEvents, idletimeoutInMillis, idletimeoutCheckInMillis} from "../../config";
import Button, {BUTTON_STYLE} from "../button"

export const timeoutLabel = function () {
    let minutes = Math.floor(idletimeoutInMillis / 60000);
    let seconds = ((idletimeoutInMillis % 60000) / 1000).toFixed(0);
    seconds = '' + seconds;
    seconds = ('00' + seconds).substring(seconds.length);
    let timeoutTime = minutes + ':' + seconds;
    return ('O tempo máximo de inatividade é de %TIMEOUT_MINUTES% minutos. Você será desconectado em:.'.replace('%TIMEOUT_MINUTES%', timeoutTime));
};

/**
 * @return {null}
 */

export default function IdleTimeout(props) {
    const [timer, setTimer] = React.useState(idletimeoutInMillis);

    // setting handler for certain activities / events

    function shouldShowAlert(){
        return timer < idletimeoutCheckInMillis
    }

    function resetTimer(){
        if (shouldShowAlert()){
            ModalService.close();
        }
        setTimer(idletimeoutInMillis);
    }

    function showAlert(timer){
        ModalService.changeInfo({
            show: true,
            text: timeoutLabel(),
            title: 'Alerta de Inatividade',
            closeButton: false,
            spinner: false,
            buttons: <Button style={{width: 'fit-content'}} variant={BUTTON_STYLE.DEFAULT_PRIMARY}>Ainda estou aqui!</Button>,
            textBottom: timer/1000,
            maxWidth: 'xs'
        });
    }

    React.useEffect(() => {
        idletimeoutBreakEvents.forEach(($event) => {
            document[$event] = () => {
                resetTimer();
            }
        });
    });


    React.useEffect(() => {
        const interval = setInterval(() => {
            if (timer === 0){
                keycloakService.logout()
            }
            if (shouldShowAlert()){
                showAlert(timer)
            }
            setTimer(timer => timer - 1000);
        }, 1000);
        return () => {
            return clearInterval(interval)
        };
    }, [timer]);

    return null;
}
