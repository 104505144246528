import React from "react";
import Select from "./select"
import MetadataLayout from "../form-input/layout-metadata"


export default function DisabledComponentField(props){
  return (<MetadataLayout {...props}>
      <Select {...props}/>
    </MetadataLayout>
  )
}