import NumberFormat from "react-number-format";
import React from "react";
import Input from "../form-input/input"
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context";
import {substringWithNOccurrencesOfCharacter} from "../../../../utils";

var imei = require('imei');

const isValidImei = (value, metadata) => {
    const reg = new RegExp(metadata.regexp);
    const imeiNumber = parseInt(value);
    if (value) {
        return imei.isValid(imeiNumber.toString()) && reg.test(imeiNumber);
    } else {
        return !Boolean(metadata.required);
    }
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    setFormValues(metadata.name, event.target.value.replace(/\D/g,''), isValidImei(event.target.value.replace(/\D/g,''), metadata));
};

const selectMask = (formValues, metadata) => {
    const phone = ((formValues[metadata.name] || {})["value"] || '');
    if (phone.length === 0) {
        return "######-##-######-#"
    }
    else {
        return substringWithNOccurrencesOfCharacter("######-##-######-#", "#", phone.length+1);
    }
};

export default function Imei({metadata}){
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false)
            fieldDisabled = true;
    }
    return (<FormContextConsumer>
        {({ setFormValues, formValues }) => (
            <NumberFormat customInput={Input}
                          id={metadata.name}
                          format={selectMask(formValues, metadata)}
                          metadata={metadata}
                          mask={" "}
                          valid={isValidImei(((formValues[metadata.name] || {})["value"] || ''), metadata)}
                          onChange={onChange(setFormValues, formValues, metadata)}
                          isNumericString={true}
                          value={((formValues[metadata.name] || {})["value"] || '')}
                          disabled={fieldDisabled}
            />
        )}
    </FormContextConsumer>)
}
