export class Body {

    mediaType;
    encryptedContent
    constructor(mediaType, encryptedContent) {
        this.mediaType = mediaType;
        this.encryptedContent = encryptedContent;
    }

    get MediaType() {
        return this.mediaType;
    }

    get EncryptedContent() {
        return this.encryptedContent;
    }
}
