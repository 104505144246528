import React from "react";
import ShowImagesButton from "../form-input-file/show-images-button"
import FileInfoViewer from "../form-input-file/form-input-files-information"
import DocumentLayout from "../form-input/layout-documents"
import {FileContextProvider, FileContextConsumer} from "../form-input-file/form-input-file-context"
import Feedback from "../form-input-file/feedback"
import ScanButton from "./form-input-scanner";

const ScannerField = (props) =>{
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (props.metadata.updatable === false) {
            fieldDisabled = true
        }
    }
    return (
        <FileContextProvider metadata={props.metadata}>
            <DocumentLayout metadata={props.metadata}>
                <FileContextConsumer>
                    {({handleChange}) =>
                        <ScanButton metadata={props.metadata} handleChange={handleChange} disabled={true}/>
                    }
                </FileContextConsumer>
                <FileInfoViewer itemLimit={(props.metadata.itemLimit || 1)}
                                metadata={props.metadata}
                                style={{"width": "64%", "marginLeft": "-1px"}}
                />
                <ShowImagesButton metadata={props.metadata}/>
                <Feedback/>
            </DocumentLayout>
        </FileContextProvider>
    )
};

export default ScannerField;