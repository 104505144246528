import AxiosService from 'services/axios-service';
import { keycloakService } from '../services/keycloak-service';
import { take } from 'rxjs/operators';

const axios = AxiosService.getInstanceWithLoader();

export class Request {

  requestStructure(resolveFunction) {
    return new Promise(resolve => {
      keycloakService.getToken().pipe(
        take(1)
      ).subscribe(
        (token) => {
          const config = { headers: { 'Authorization': 'Bearer ' + token } };
          resolve(resolveFunction(config));
        });
    });
  }

  get(uri) {
    return this.requestStructure(
      (config) => axios.get(uri, config)
    );
  }

  post(uri, payload) {
    return this.requestStructure((config) =>{
        return process.env.NODE_ENV === 'development' ?
          axios.post(uri, payload) :
          axios.post(uri, payload, config)
    });
  }

  put(uri, payload) {
    return this.requestStructure(
      (config) => axios.put(uri, payload, config)
    );
  }

  delete(uri) {
    return this.requestStructure(
      (config) => axios.delete(uri, config)
    );
  }
}

