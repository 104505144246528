import React from "react";
import Input from "../form-input/input"
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context";

const matchRegEx = (string, pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(string);
};

const eraseRegEx = (string, paddingSize) => {
    const regex = new RegExp(`^([0]{${paddingSize-1}})$`);
    return regex.test(string);
};

const zeroPadding = (number, metadata) => {
    const paddingSize = parseInt(metadata.zeroPadding);
    const erase = eraseRegEx(number, paddingSize);
    if(erase){
        return "";
    }
    else{
        const paddedNumber = number.padStart(paddingSize,0).toString();
        const test = matchRegEx(paddedNumber, metadata.regexp);
        if(!test){
            return paddedNumber.slice(-paddingSize);
        }
        else{
            return paddedNumber.slice(0,paddingSize);
        }
    }
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    event.target.value = zeroPadding(event.target.value, metadata);
    setFormValues(metadata.name, event.target.value.replace(/\D/g, ''), event.target.value.replace(/.{7,}/g, ''), matchRegEx(event.target.value) || !metadata.required);
};


export default function Number({metadata}) {
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false)
            fieldDisabled = true;
    }
    return (
        <FormContextConsumer>
            {({setFormValues, formValues}) => (
                <Input id={metadata.name}
                       metadata={metadata}
                       valid={matchRegEx(((formValues[metadata.name] || {})["value"] || ''), metadata.regexp) || !Boolean(metadata.required)}
                       onChange={onChange(setFormValues, formValues, metadata)}
                       value={((formValues[metadata.name] || {})["value"] || '')}
                       type={"text"}
                       disabled={fieldDisabled}
                />
            )}
        </FormContextConsumer>
    )
}
