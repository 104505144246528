import {AVAILABLE_THEMES} from "../themes";

export const firebaseConfig = {
    authDomain: "tdi-v1-tim-brazil.firebaseapp.com",
    databaseURL: "https://tdi-v1-tim-brazil.firebaseio.com",
    projectId: "tdi-v1-tim-brazil",
    storageBucket: "tdi-v1-tim-brazil.appspot.com",
    messagingSenderId: "863264273481",
    appId: "1:863264273481:web:1550529ae502d7235c3b98"
};

export const keycloakRedirectLogin = true;

export const depEnabled = true;

export const authRequired = true;

export const userPermissionRequired = true;

export const documentFormRedirect = true;

export const fcmApiKey = "firebase_APIKey_p";

export const fcmVapKey = "fcmVapKey_p";

export const keycloakJsonConfigFile = "/keycloak-prod.json";

export const URI_SUFFIX = '';

const BASE_URL = "https://tim.dnbsa1.thalescloud.io/";

const BASE_URL_TDI = "https://api.tdisa.se1.msi.acloud.gemalto.com/";

export const persistTlsCa = '';

export const persistTlsKey = '';

export const persistTlsCert = '';

export const DOC_MATRIX = {
    BASE_URL: BASE_URL + 'docs/',
    URI_PATH: {
        SALE_TYPE: 'getCombos',
        REFRESH_SALE_TYPE: 'getCombos',
        REFRESH_SALE_TYPE2: 'getCombos',
        DOCUMENT_LIST: 'getDocs',
        STATUS_COLOR: 'getStatusColor/{status}',
        UPDATABLE_STATUS: 'getStatusRules/{status}',
        GET_KEYS: 'getKeys/',
        GET_HELP_FILE: 'getHelpFile',
    }
};

export const SELF_DOCS = {
    BASE_URL: window.location.origin + '/',
    URI_PATH: {
        FILTER_LIST: 'filters',
    }
};

export const TDI = {
    BASE_URL: 'https://api.tdisa.se1.msi.acloud.gemalto.com/dep/v1/',
    URI_PATH: {
        SCENARIO_STEP: "scenarios/{scenarioId}/state/steps/{stepId}",
        SCENARIO_STATUS: "scenarios/{scenarioId}/",
        SCENARIO: "scenarios"
    }
};


export const KEYCLOAK = {
    BASE_URL: BASE_URL,
    URI_PATH: {
        SCENARIO_STEP: '/scenarios/{scenarioId}/state/steps/{stepId}',
        TOKEN: 'realms/DCS_test/protocol/openid-connect/token',
        CHANGE_PASSWORD: 'auth/realms/TIM-TDI-V1/account/#/security/signingin',
    }
};

export const CONSULT = {
    BASE_URL: BASE_URL + 'dep/consult',
    URI_PATH: {
        PACKAGE: "",
        FILE: "{packageId}/files/{fileId}",
        DOCUMENT: "{packageId}/documents/{idDocument}",
        ZIP: "zip",
        PDF: "pdf",
        UPDATE_STATUS: "updateStatus",
        UPDATABLE_STATUS: 'getStatusTransitions/{status}',
        EXPORT: 'export',
    }
};

export const PROFILE = {
    BASE_URL: BASE_URL_TDI + "v2",
    URI_PATH: {
        PROFILE: 'profile',
    },
};

export const PERSIST = {
    BASE_URL: BASE_URL + 'persist',
};

export const idletimeoutBreakEvents = [
    "onmouseup",
    "onscroll",
    "onkeypress"
];

export const idletimeoutInMillis = 15 * 60 * 1000;

export const idletimeoutCheckInMillis = 60 * 1000;

export const tdiRetryDelayinMillis = 3 * 1000;

export const tdiPatchStepDelayinMillis = 3 * 1000;

export const FILESIZE_THRESHHOLD_SUM = 3 * 1024 * 1024;

export const MIN_FILESIZE_EACH = 20 * 1024;

export const THEME = AVAILABLE_THEMES.TIM;

export const DYNAMSOFT_LICENSE_KEY = "dynamsoft_p";

export const DYNAMSOFT_RESOURCES_URI = "https://tim.dnbsa1.thalescloud.io/dynamsoft";

export const exportCsvHeaderValues = ['MSISDN','cpf', 'CPFCNPJ','id','Operation','CLIENTID','SUBCLIENTID','CONTRATO','NOVO-PLANO','imei','COND','ENTRY','DISCOUNT','CCP1','CCP2','CC1','CC2','CCA1','CCA2','creationDate','TR_DATE','lastModificationDate','status','statusDescription','username','FRONT','store','superStore'];