import {isPlainObject} from "../utils";
import {TYPE} from "../utils/const";

const qs = require('querystring');

export const addTrailingCharacters = (str, chars) => {
    return str.endsWith(chars) ? str : str.concat(chars);
};

export const replaceStrings = (str, strReplacements) => {
    if (isPlainObject(strReplacements)){
        Object.keys(strReplacements).forEach((key) => {
            str  = str.replace(`{${key}}`, strReplacements[key]);
        });
    }
    return typeof str === TYPE.STRING ? str : ""
};

export const removeTrailingSlash = (str) => {
    return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const composeRegularURI = (url, path, pathNameReplacements, suffix) => {
    return addTrailingCharacters(addTrailingCharacters(url, "/") + removeTrailingSlash(replaceStrings(path, pathNameReplacements)), suffix);
};

export const composeSearchParams = (params) => {
    return isPlainObject(params) ?
        Object.keys(params).length === 0 ? "" : "?"+qs.stringify(params) : ""
};

export const addQueryParams = (uri, queryParams) =>{
    return uri + composeSearchParams(queryParams);
};

export const composeURI = (url, path, suffix) => {
    return (pathNameReplacements, queryParams) => {
        return addQueryParams(removeTrailingSlash(composeRegularURI(url, path, pathNameReplacements, suffix)), queryParams)
    }
};

