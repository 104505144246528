import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import TextFilter from './text';
import { Grid } from '@material-ui/core';

function FilterList({filterList}){

  const [filterKey, setFilterKey] = React.useState(null);

  const handleFilterKeyChange = (event) => {
    setFilterKey(event.target.value);
  };

  const findFilterComponent = () => {
    const selectedFilter = filterList.find(filterDetails => filterDetails.value === filterKey);
    if (selectedFilter){
      return selectedFilter.component
    }
    return <TextFilter
      label="Valor"
      filterKey={filterKey}
      button={1}
    />

  };

  return (<Grid container direction={'row'} spacing={1}>
    <Grid item md={2} xs={10}>
      <TextField
        id="quick-filter"
        select
        label="Filtro"
        value={filterKey}
        onChange={handleFilterKeyChange}
        fullWidth={1}
      >
        {filterList.map((filter) => (
          <MenuItem key={filter.value} value={filter.value}>
            {filter.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
    <Grid item md={2} xs={10}>
      <TextField
        id="open-filter"
        label="Filtro"
        value={filterKey}
        onChange={handleFilterKeyChange}
        fullWidth={1}
      />
    </Grid>
    <Grid item md={4} xs={10}>
      {findFilterComponent()}
    </Grid>
  </Grid>)
}

export default FilterList;