
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormContext } from '../../dynamic-form/dynamic-form-context';

export default function CheckboxesGroup(metadata) {

    const { formValues, setFormValues } = React.useContext(FormContext);

    const handleChange = (event) => {
        if (metadata.onChange) {
            metadata.onChange(event)
        } else {
            const formValueField = "justifications"
            let currentValue = formValues[formValueField]?.value;
            let currentGroup = formValues[formValueField]?.value[metadata.group] || [];
            let newValue;
            if (event.target.checked) {
                currentGroup.push(metadata.metadata.name);
                newValue = { ...currentValue, ...{ [metadata.group]: currentGroup } };
            } else {
                const newGroup = currentGroup.filter(function (i, n) {
                    return i != metadata.metadata.name;
                });
                newValue = { ...currentValue, ...{ [metadata.group]: newGroup } };
            }
            setFormValues(formValueField, newValue, true)
        }
    };

    return (
        <FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox color="primary" onChange={handleChange} name="just"
                        value={((formValues[metadata.name] || {})["value"] || '')} />}
                    label={metadata.metadata.name} labelPlacement="end"
                    style={{ textAlign: 'left', fontSize: 16, lineHeight: 1, color: 'black' }}
                />
            </FormGroup>
        </FormControl>
    );
}


