import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SaleType from '../sale-type';
import TextFilter from './table/filters/corporate-filters/text';
import PageView from '@material-ui/icons/Pageview';
import { PATH } from '../../../routes/path-list';
import { CorporateTableContext } from './corporate-context';
import { Styles } from './table/style';
import Table from './table/table';
import { generateSaleTypePayload } from './consumer-table';
import Operacao from '../operacao';
import { profileService } from 'services/profile-service';
import {getProfile} from "api/index"

function CorporateTable() {

  let history = useHistory();

  useEffect(() => {
    (async () => {
        let profileData = await getProfile();
        profileService.initI18n(profileData);
        profileService.setLocalization(profileData.localization);
        profileService.setProfile(profileData);
    })();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID do Pacote',
        columns: [
          {
            accessor: 'id',
            Header: <TextFilter filterKey={'packageId'}/>
          }
        ]
      },
      {
        Header: 'Operação',
        id: 'saleType',
        columns: [
          {
            id: 'saleType',
            filter: '',
            accessor: d => {
              let props = d;
              return <Operacao {...props} />
            }
          }
        ]
      },
      {
        Header: 'P2B',
        columns: [
          {
            accessor: 'P2B',
            Header: <TextFilter filterKey={'P2B'}/>
          }
        ]
      },
      {
        Header: 'RADAR',
        columns: [
          {
            accessor: 'RADAR',
            Header: <TextFilter filterKey={'RADAR'}/>
          }
        ]
      },
      {
        Header: 'CNPJ',
        columns: [
          {
            accessor: 'CPFCNPJ',
            Header: <TextFilter filterKey={'CPFCNPJ'}/>
          }
        ]
      },
      {
        Header: 'CLASSE',
        columns: [
          {
            accessor: 'CLASSE',
            Header: <TextFilter filterKey={'CLASSE'}/>
          }
        ]
      },
      {
        Header: 'Data de Criação',
        columns: [
          {
            accessor: 'creationDate',
            Header: ''
          }
        ]
      },
      {
        Header: 'Data de Ativação',
        columns: [
          {
            accessor: 'TR_DATE',
            Header: ''
          }
        ]
      },
      {
        Header: '',
        id: 'view',
        columns: [
          {
            id: 'view',
            accessor: d => <PageView color='primary' style={{ cursor: 'pointer' }}
                                     onClick={() => {
                                       history.push({
                                         pathname: PATH.CORPORATE_PACKAGE_DETAILS.replace(':packageId', d.id),
                                       });
                                     }}> </PageView>,
            Header: ''
          }
        ]
      }
    ]
  );

  const { data, setAccessed } = React.useContext(CorporateTableContext);

  React.useEffect(() => {
    setAccessed(true)
  });

  return (
    <Styles>
      <Table columns={columns} data={data} context={CorporateTableContext}/>
    </Styles>
  );
}

export default CorporateTable;