import Chips from '../../../../material-ui/chips/chips';
import React from 'react';
import { TableContext } from '../context';

function FilterChips() {

  const {filters, deleteFilter} = React.useContext(TableContext);

  return <Chips onDelete={deleteFilter} values={filters}/>
}

export default FilterChips;


