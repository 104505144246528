export class SessionKeys {
    subjectKeyIdentifier;
    encryptedKeys;
    constructor(subjectKeyIdentifier,
        encryptedKeys){
        this.subjectKeyIdentifier = subjectKeyIdentifier;
        this.encryptedKeys = encryptedKeys;
    }

    get SubjectKeyIdentifier() {
        return this.subjectKeyIdentifier;
    }

    get EncryptedKeys() {
        return this.encryptedKeys;
    }
}
