import React from 'react'
import Table from "../../../material-ui/table";
import { FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT, FILTER_VALUES_NAME_REPLACEMENT } from "../../../../variables/consult-info";
import { PackageContext } from "../package-context";
import Status from '../../status';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import ExpansionPanels from '../../../material-ui/expansion-panel'


export const createFullDetailList = (depData) => {
    return Object.entries(depData).map(([k, v]) => {
        return {
            name: k,
            value: [
                k,
                v
            ]
        }
    });
};

function DependentsTab(props) {

    const { _package } = React.useContext(PackageContext);
    let rows;
    if(_package.dependents !== undefined){
         rows = Object.entries(_package.dependents).map(([cont, value]) => {
            const dependent_detail_list = createFullDetailList(value);
            dependent_detail_list.sort( (a, b) => a.value[0].localeCompare(b.value[0], 'fr', { ignorePunctuation: true }));
            return {
                title: cont,
                id: 'panel' + cont,
                content: <Table rows = {dependent_detail_list}/>
            }
    
        });
        rows.sort( (a, b) => a.title.localeCompare(b.title, 'fr', { ignorePunctuation: true }));
    }   
    return <ExpansionPanels style={'flex'} panels={rows} />
}

export default DependentsTab;