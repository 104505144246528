import React, {useCallback, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Button from '@material-ui/core/Button';
import './image-viewer-style.css';
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import DeleteModal from "../modal/delete-modal";
import {isBase64} from "../../utils"
import CloseIcon from "@material-ui/icons/Close"
import DeleteIcon from "@material-ui/icons/Delete"
import { FileDisplayEditService } from "../../services/file-display-edit-service";
import { arrayRemove, guessImageMime } from "../../utils";


const ImageViewer = () => {
    const [fileList, setFileList] = useState([]);
    const [title, setTitle] = useState([]);
    const [show, setShow] = useState("none");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [imageId, setImageId] = useState();

    const showImageViewer = () =>{
        setShow("block")
    };

    useEffect(() => {
        const fileListSubscription = FileDisplayEditService.getFileList()
            .subscribe((fileList) => {
                setFileList(fileList);
                showImageViewer();
            });
        return () => {
            fileListSubscription.unsubscribe()
        }
    }, []);

    useEffect(() => {
        const titleSubscription = FileDisplayEditService.getTitle()
            .subscribe((title) => {
                setTitle(title);
            });
        return () => {
            titleSubscription.unsubscribe()
        }
    }, []);

    const removeFile = useCallback((image) => {
        setFileList(prevFiles => [...arrayRemove(prevFiles, image)]);
    }, [fileList]);

    const createFileSrc = (file) => {
        return file ? URL.createObjectURL(file) : ""
    };

    const getSpecificViewer = (file,isBase64List) => {
        // assumption for now mime types pdf and image will be supported 
        let MIMEType = guessImageMime(file);
        if(MIMEType.indexOf("pdf") > -1)
        {
            if(isBase64List)
                return (<object className="pdfViewer" data={"data:" + MIMEType + ";base64," + file}></object>)
            else
                return (<object className="pdfViewer" data={createFileSrc(file)}></object>)
        }
        else if(MIMEType.indexOf("image") > -1)
        {
            if(isBase64List)
                return (<Figure className="imageViewer"><Figure.Image className="figure-container" src={ "data:image/png;base64," + file }/></Figure>)
            else
                return (<Figure className="imageViewer"><Figure.Image className="figure-container" src={ createFileSrc(file) }/></Figure>)
        }
    };

    const isFileList = (items) => {
        return items ? Array.from(items).every( file => file instanceof File) : false
    };

    const isBase64List = (items) => {
        return items ? Array.from(items).every( file => isBase64(file)) : false
    };

    const openDeleteModal = (id) => {
        setImageId(id);
        setShowDeleteModal(true);
    };

    const createCarouselItems = () => {
        const files = fileList ? Array.from(fileList): false;
        if (isFileList(files)){
            return files.map((file) => {
                return <Carousel.Item key={file.name}>
                   {getSpecificViewer(file.image,false)}
                </Carousel.Item>
            })
        }
        else if(isBase64List(files)){
            return files.map((file) => {
                return (<Carousel.Item key={Math.random()}>
                        {getSpecificViewer(file.image,true)}
                    <Carousel.Caption>
                        <Button variant="contained" color="secondary" onClick={() => openDeleteModal(file)}>
                            <DeleteIcon/>
                        </Button>
                    </Carousel.Caption>
                </Carousel.Item>)
            })
        }
        else{
            return null;
        }
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const deleteImage = (deleteConfirmation, file) => {
        closeDeleteModal();
        if (deleteConfirmation){
            FileDisplayEditService.deleteFile(file);
            removeFile(file);
        }
    };

    const closeImageViewer = () =>{
        setShow("none")
    };

    return (<Col lg={{ span: 6, offset: 6 }} md={12} style={{display: show}}>
        <DeleteModal
            show={showDeleteModal}
            callback={deleteImage}
            id={imageId}
            style={{zIndex: 1500}}
        />
        <Container as={Card} className="card-wrapper" style={{zIndex: 1400}}>
            <Row>
                <Col xs={{ span: 6, offset: 3}} style={{'text-align': 'center'}}>
                    <div className={"sale-type"}>{title}</div>
                </Col>
                <Col xs={{ span: 3}} style={{'textAlign': 'end', 'paddingTop': '7px'}}>
                    <Button style={{}} variant="contained" color="secondary" className={"image-viewer-close-button"} onClick={closeImageViewer} >
                        <CloseIcon/>
                    </Button>
                </Col>
            </Row>
            <Carousel interval={null} touch={1} slide={0}>
                {createCarouselItems()}
            </Carousel>
        </Container>
    </Col>)
};

export default ImageViewer;
