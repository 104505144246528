import React from 'react';
import Grid from '@material-ui/core/Grid';
import Status from '../../../status';
import Can from '../../../../permissions';
import { ABILITY } from '../../../../../variables/roles-permissions';
import UpdateStatusButton from './update-status-button';
import {useLocation} from "react-router-dom";

export const StatusRow = (value, _package, reloadPackage) => {
  let location = useLocation();

  let details_path = location.pathname.split('/')[2];
  let isDetailsAllowed = true;

  if(details_path === 'prepaid'){
    isDetailsAllowed = false;
  };

    return <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Status status_name={value}/>
        </Grid>
        <Grid item xs={6} disabled={isDetailsAllowed}>
          <Can I={ABILITY.UPDATE_STATUS}>
            <UpdateStatusButton
              value={value}
              _package={_package}
              reloadPackage={reloadPackage}
              notPrepaid = {isDetailsAllowed}
            />
          </Can>
        </Grid>
      </Grid>
    </div>;
};
