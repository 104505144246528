import { PATH } from "../../routes/path-list";

export const requestNotificationPermission = async (history, updateFirebase) => {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            updateFirebase();
            history.push(PATH.SALE_TYPE_FORM);

        } else {
            history.push(PATH.NOTIFICATION_PERMISSION_RETRY)
        }
    });
};
