import { Subject } from "rxjs";

const $files = new Subject();
const $delete = new Subject();
const $title = new Subject();

export const FileDisplayEditService = {
    getFileList: () => $files,
    setFileList: (fileList) => $files.next(fileList),
    getFileToBeDeleted: () => $delete,
    deleteFile: (file) => $delete.next(file),
    getTitle: () => $title,
    setTitle: (title) => $title.next(title),
};