import React, { useContext } from 'react';
import Input from '../form-input/input';
import { v4 as uuidv4 } from 'uuid';
import { FormContext } from '../../dynamic-form/dynamic-form-context';
import {getSelectedValue} from '../form-input-dropdown/form-input-dropdown-view';

const validate = (string, options) => {
  if (options){
    const result =  options.some((option) => {
      return string in option
    });
    return result
  }
  else{
    return false
  }
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
  setFormValues(metadata.name, event.target.value, validate(event.target.value, (metadata && metadata.options)) || !metadata.required);
};

function Datalist({metadata}) {
  const { setFormValues, formValues } = useContext(FormContext);
  const setSelectedValue = onChange(setFormValues, formValues, metadata);
  const value = ((formValues[metadata.name] || [])['value'] || '');
  const valid = ((formValues[metadata.name] || [])['valid'] || '');

  return (<>
    <Input list={metadata.name}
           name={metadata.name}
           id={uuidv4()}
           onChange={setSelectedValue}
           value={value}
           valid={valid}
    />
      <datalist id={metadata.name}>
          {metadata.options.map((option) =>
            Object.keys(option).map((key) =>
              <option key={key} value={key}>{option[key]}</option>
            ))}
      </datalist>
    </>
  );
}

export default Datalist;