export const history = [];

export const HistoryService = {
  getHistory: () => history,
  setHistory: (path) => {
    if (history[history.length - 1] !== path) {
      history.push(path);
    }
  },
  removeNPaths: (number_of_paths) => {
    history.splice(-number_of_paths);
  },
};
