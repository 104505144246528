import React, { useEffect } from 'react';

export default () =>{

    const [text, setText] = React.useState('Loading...');

    useEffect(() => {
        setTimeout(() => {
            setText('Não foi possível entrar em contato com o serviço de login, tente novamente');
        }, 10000);
    });

    return <div>{text}</div>
}