import React, { useContext } from "react";
import Select from "../form-input/select"
import { FormContext } from "../../dynamic-form/dynamic-form-context";
import { DataService } from "../../../../services/data-service";
import {take } from "rxjs/operators";

const matchRegEx = (string, pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(string);
};

const onChange = (setFormValues, formValues, metadata) => (event) => {
    DataService.getPayloadToRetrieveFormInfo().pipe(take(1)).subscribe((payload) => {
            payload[metadata.name] = event.target.value;
            DataService.setPayloadToRetrieveFormInfo(payload)
        }
    );
    setFormValues(metadata.name, event.target.value, matchRegEx(event.target.value, ".+") || !Boolean(metadata.required));
};

const getSelectedValue = (formValues, metadata, setSelectedValue) => {
    if ((formValues || {}).hasOwnProperty(metadata.name)){
        if (metadata.options.some((option) => Object.keys(option).includes(formValues[metadata.name].value))){
            return formValues[metadata.name].value;
        }
        else{
            setSelectedValue({target:{value: Object.keys(metadata.options[0])[0]}})
        }
    }
    else{
        setSelectedValue({target:{value: Object.keys(metadata.options[0])[0]}})
    }
};

export default function ReduxDropdown({metadata}){
    const { setFormValues, formValues } = useContext(FormContext);
    const setSelectedValue = onChange(setFormValues, formValues, metadata);

    return (
        <Select id={metadata.name}
                metadata={metadata}
                valid={matchRegEx(((formValues[metadata.name] || {})["value"] || ''), ".+") || !Boolean(metadata.required)}
                onChange={setSelectedValue}
                type={"select"}
                value={getSelectedValue(formValues, metadata, setSelectedValue)}
                valueFromKey={1}
        />)
}