import React from "react";
import FormDatepicker from "./form-input-datepicker-view"
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context";

export default function Text(props){
    return (
            <FormContextConsumer>
                {({ setFormValues, formValues }) => (
                    <FormDatepicker {...props} setFormValues={setFormValues} formValues={formValues} />
                )}
            </FormContextConsumer>

    )
}