import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PageView from '@material-ui/icons/Pageview';
import { PATH } from '../../../routes/path-list';
import { Styles } from './table/style';
import TextFilter from './table/filters/mesa-thales/text';
import OperationFilter from "./table/filters/mesa-thales/operation-filter";
import StatusFilter from './table/filters/mesa-thales/status';
import Status from '../status';
import Operacao from '../operacao';
import Table from './table/table';
import { MesaThalesTableContext } from './mesa-thales-context';
import { profileService } from 'services/profile-service';
import {getProfile} from "api/index"

function MesaThalesTable() {

    let history = useHistory();

    useEffect(() => {
        (async () => {
            let profileData = await getProfile();
            profileService.initI18n(profileData);
            profileService.setLocalization(profileData.localization);
            profileService.setProfile(profileData);
        })();
      }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID do Pacote',
                columns: [
                    {
                        accessor: 'id',
                        Header: <TextFilter filterKey={'packageId'} />,
                    },
                ],
            },
            {
                Header: 'Operação',
                id: 'saleType',
                columns: [
                    {
                        id: 'saleType',
                        filter: "",
                        accessor: d => {
                            let props = d;
                            return <Operacao {...props} />
                        },
                        Header: "",
                    },
                ],
            },
            {
                Header: 'Tipo Loja',
                columns: [
                    {
                        accessor: 'ROLEID',
                        Header: "",
                    },
                ],
            },
            {
                Header: 'Usuário de criação',
                columns: [
                    {
                        accessor: 'username',
                        Header: "",
                    },
                ],
            },
            {
                Header: 'CPF',
                columns: [
                    {
                        accessor: 'cpf',
                        Header: <TextFilter filterKey={'CPF'} />,
                    },
                ],
            },
            {
                Header: 'CNPJ',
                columns: [
                    {
                        accessor: 'CPFCNPJ',
                        Header: <TextFilter filterKey={'CPFCNPJ'} />,
                    },
                ],
            },
            {
                Header: 'MSISDN',
                columns: [
                    {
                        accessor: 'MSISDN',
                        Header: <TextFilter filterKey={'MSISDN'} />,
                    },
                ],
            },
            {
                Header: 'Data de Criação',
                columns: [
                    {
                        accessor: 'creationDate',
                        Header: "",
                    },
                ],
            },
            {
                Header: 'Status',
                id: 'status',
                columns: [
                    {
                        id: 'status-filter-field',
                        accessor: d => <Status status_name={d.status} showDescription={1} />,
                        Header: <StatusFilter />,
                    },
                ],
            },
            {
                Header: '',
                id: 'view',
                columns: [
                    {
                        id: 'view',
                        accessor: d => <PageView color='primary' style={{ cursor: 'pointer' }}
                            onClick={() => {
                                history.push({
                                    pathname: PATH.MESATHALES_PACKAGE_DETAILS.replace(':packageId', d.id),
                                })
                            }}> </PageView>,
                        Header: "",
                    },
                ],
            },
        ],
    );

    const { data, setAccessed } = React.useContext(MesaThalesTableContext);
    React.useEffect(() => {
        setAccessed(true)
    });

    return (
        <Styles>
            <Table columns={columns} data={data} context={MesaThalesTableContext} isMesaThales={true} />
        </Styles>
    )
}

export default MesaThalesTable;