import React, {useContext} from "react";
import {FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT} from "../../../../variables/consult-info";
import Button from "@material-ui/core/Button";
import GetAppIcon from '@material-ui/icons/GetApp'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Tooltip from "@material-ui/core/Tooltip";
import {AppContext} from "../../../app/context";
import Table from "../../../material-ui/table";
import {PackageContext} from "../package-context";
import {downloadFiles, downloadZip, openImageViewer, downloadPdf} from './utils';
import { ABILITY } from '../../../../variables/roles-permissions';
import Can from '../../../../components/permissions';
import {checkPermisions} from '../../../../components/permissions';
import { UserService } from "../../../../services/user-service"
import {ROLES_ABILITIES} from "../../../../variables/roles-permissions"


function DownloadFileRows(props){
    const {language,appmetadata} = useContext(AppContext);
    const {_package}  = React.useContext(PackageContext);

    let rows = (_package.documents || [])
        .filter((document) => document.files.length !== 0)
        .map((document) => {
            if(document.name === "id" || document.name === "idCED" || document.name === "selfie" || document.name === "inv" || document.name === "crCardRt" || document.name === "crCardRt2" || document.name === "selfieCED"){   
                const roles = UserService.getRoles();
                let accessSensitiveInfo = checkPermisions(ROLES_ABILITIES, ABILITY.ACCESS_SENSITIVE_INFO, roles);

                if(accessSensitiveInfo){
                    return {
                        name: document.name,
                        value:[FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[document.name] || document.name,  
                            <Tooltip title={appmetadata.CONSULT.SEE[language]}>
                                <Button variant="contained" color="primary" onClick={
                                    () => openImageViewer(document, _package)
                                }>
                                    <VisibilityIcon/>
                                </Button>
                            </Tooltip>,
                            <Tooltip title={appmetadata.CONSULT.DOWNLOAD[language]}>
                                <Button variant="contained" color="primary" onClick={() => downloadFiles(document, _package)}>
                                    <GetAppIcon/>
                                </Button>
                            </Tooltip>
                        ]
                    }
                }else if(!accessSensitiveInfo){
                    return {
                        name: document.name,
                        value:[FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[document.name] || document.name,  
                            <Tooltip title={"Restrito"}>
                                <Button variant="contained" color="default" disabled="disabled">
                                    <VisibilityIcon/>
                                </Button>
                            </Tooltip>,
                            <Tooltip title={"Restrito"}>
                                <Button variant="contained" color="default" disabled="disabled">
                                    <GetAppIcon/>
                                </Button>
                            </Tooltip>
                    ]
                    }
                }
            }else{
                return {
                    name: document.name,
                    value:[FILTER_DOCUMENT_KEYS_NAME_REPLACEMENT[document.name] || document.name,
                    <Tooltip title={appmetadata.CONSULT.SEE[language]}>
                        <Button variant="contained" color="primary" onClick={
                            () => openImageViewer(document, _package)
                        }>
                            <VisibilityIcon/>
                        </Button>
                    </Tooltip>,
                    <Tooltip title={appmetadata.CONSULT.DOWNLOAD[language]}>
                        <Button variant="contained" color="primary" onClick={() => downloadFiles(document, _package)}>
                            <GetAppIcon/>
                        </Button>
                    </Tooltip>
                    ]
                }
            }
        });
        
    rows.push({
        name: 'all_files',
        value: [appmetadata.CONSULT.ALL_FILES[language],'',
        
        <Tooltip title={appmetadata.CONSULT.DOWNLOAD[language]}>
            <Button variant="contained" color="primary" onClick={() => downloadPdf(_package)}>
                <GetAppIcon/>
            </Button>
        </Tooltip>
    ]
});

    rows.sort( (a, b) => a.value[0].localeCompare(b.value[0], 'fr', { ignorePunctuation: true }));

    return <Table rows={rows} />
}

export default DownloadFileRows;