import { BehaviorSubject } from "rxjs";

let loader = 0;
const $loader = new BehaviorSubject(loader);

export const LoaderService = {
    requestLoader: () => {
        loader++;
        $loader.next(loader);
    },
    closeLoaderRequest:() => {
        loader--;
        $loader.next(loader);
    },
    getLoaderValue:() => $loader,
};