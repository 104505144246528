import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import WebCaptureLayoutRoute from './web-capture-layout-route';
import Home from '../screens/home';
import DocumentCapture from '../screens/document-capture';
import SaleType from '../screens/sale-type-selection';
import PackageDetail from '../screens/consult/detail';
import PackageList from '../screens/consult/list';
import CorporatePackageList from '../screens/consult/corporate/list';
import PrepaidPackageList from '../screens/consult/prepaid/list';
import LiveTimPackageList from '../screens/consult/livetim/list';
import MesaThalesPackageList from '../screens/consult/mesa-thales/list';
import NotificationPermission from '../screens/notification-permission';
import NotificationPermissionRetry from '../screens/notification-permission/notification-permission-retry';
import End from '../screens/end';
import Error503 from '../screens/errors/503';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PATH } from './path-list';
import PrivateRoutes from '../components/private-route';
import PrivateRouteByRole from '../components/private-route/route-by-role';
import { THEME_CONFIG, THEME } from '../config';
import { HistoryTracker } from '../components/history';


export const routing = (
  <BrowserRouter basename={THEME_CONFIG[THEME].ROUTE_BASENAME}>
    <HistoryTracker />
    <Switch>
      <WebCaptureLayoutRoute path={PATH.ERROR503} exact={true} component={Error503} />
      <PrivateRoutes>
        <WebCaptureLayoutRoute path={PATH.HOME} exact={true} component={Home} />
        <PrivateRouteByRole basepath={PATH.SALE_TYPE_FORM} path={PATH.SALE_TYPE_FORM} exact={true}
          component={SaleType} />
        <PrivateRouteByRole basepath={PATH.DOCUMENTS_FORM} path={PATH.DOCUMENTS_FORM} exact={true}
          component={DocumentCapture} />
        <WebCaptureLayoutRoute path={PATH.NOTIFICATION_PERMISSION} exact={true} component={NotificationPermission} />
        <WebCaptureLayoutRoute path={PATH.NOTIFICATION_PERMISSION_RETRY} exact={true}
          component={NotificationPermissionRetry} />
        <PrivateRouteByRole basepath={PATH.PACKAGE} path={PATH.PACKAGE} component={PackageList} exact={true} />
        <PrivateRouteByRole basepath={PATH.CORPORATE_PACKAGE} path={PATH.CORPORATE_PACKAGE}
          component={CorporatePackageList} exact={true} />
        <PrivateRouteByRole basepath={PATH.PREPAID_PACKAGE} path={PATH.PREPAID_PACKAGE} component={PrepaidPackageList} exact={true} />
        <PrivateRouteByRole basepath={PATH.TIMLIVE_PACKAGE} path={PATH.TIMLIVE_PACKAGE} component={LiveTimPackageList} exact={true} />
        <PrivateRouteByRole basepath={PATH.MESATHALES_PACKAGE} path={PATH.MESATHALES_PACKAGE} component={MesaThalesPackageList} exact={true} />
        <PrivateRouteByRole basepath={PATH.PACKAGE} path={PATH.PACKAGE_DETAILS} component={PackageDetail} exact={true} />
        <PrivateRouteByRole basepath={PATH.PACKAGE} path={PATH.CORPORATE_PACKAGE_DETAILS} component={PackageDetail} exact={true} />
        <PrivateRouteByRole basepath={PATH.PACKAGE} path={PATH.PREPAID_PACKAGE_DETAILS} component={PackageDetail} exact={true} />
        <PrivateRouteByRole basepath={PATH.PACKAGE} path={PATH.TIMLIVE_PACKAGE_DETAILS} component={PackageDetail} exact={true} />
        <PrivateRouteByRole basepath={PATH.PACKAGE} path={PATH.MESATHALES_PACKAGE_DETAILS} component={PackageDetail} exact={true} />
        <WebCaptureLayoutRoute path={PATH.END} exact={true} component={End} />
      </PrivateRoutes>
    </Switch>
  </BrowserRouter>
);
