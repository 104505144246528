import { Subject, of, Observable, combineLatest, range, timer, iif, throwError, interval, pipe } from 'rxjs';
import { keycloakService } from './keycloak-service';
import { createTDIScenario, TDIStatus, TDIStep } from '../api';
import { createTDIStepPayload, translateCode } from '../components/tdi/tdi-payload-utils';
import { fcmService } from './fcm-service';
import { NotificationService } from './notification-service';
import { depEnabled, CONSULT_CONFIG, TDI_CONFIG } from '../config';
import { profileService } from 'services/profile-service';
import {getProfile} from "api/index";
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  catchError,
  delay,
  expand,
  mergeMap,
  timeout,
  map,
  tap,
  retry,
  take,
  retryWhen,
  concatMap
} from 'rxjs/operators';
import { TDI_STATUS } from '../components/tdi/status';
import { tdiPatchStepDelayinMillis, tdiRetryDelayinMillis } from '../config';

const $step = new Subject();
const $running = new Subject();
export const $stop = new Subject();
export const $complete = new Subject();
let _finishedSteps = [];
let _payload = {};
let _fileFormData = {};
let _dropdownValues = {};

const setRunningStatus = (status) => {
  return $running.next(status);
};

const setCurrentStep = (step) => $step.next(step);

export const TDIService = {
  invoke: (payload, fileFormData, dropdownValues) => tdiFlow(payload, fileFormData, dropdownValues),
  getCurrentStep: () => $step,
  isRunning: () => $running,
  getComplete: () => $complete
};

function tdiFlow(payload, fileFormData, dropdownValues) {
  _payload = payload;
  _fileFormData = fileFormData;
  _dropdownValues = dropdownValues;
  keycloakService.refreshToken();
  setRunningStatus(true);
  setCurrentStep('creating scenario...');
  return process.env.NODE_ENV === 'development' ? postScenario().pipe(
    map((scenario) => {
      tdiSecondaryFlow(scenario.id);
    })
  ) : keycloakService.getRefreshedTokenSignal()
    .pipe(
      mergeMap(_ => postScenario().pipe(
        map((scenario) => {
          tdiSecondaryFlow(scenario.id);
        })
      ))
    );
};

function postScenario() {
  
    (async () => {
        let profileData = await getProfile();
        profileService.initI18n(profileData);
        profileService.setLocalization(profileData.localization);
        profileService.setProfile(profileData);
    })();
 
  return new Observable(subscriber => {
    createTDIScenario({ 'name': _fileFormData.scenario }, fcmService.getFirebaseCloudMessageToken().getValue())
      .then(response => {
        subscriber.next(response.data);
      }).catch(error => {
        NotificationService.send(error && error.message, 'error');
        wrapUpStream();
        subscriber.error(error);
      });
  });
};

function tdiSecondaryFlow(scenarioId) {
  return waifForFcmStream().pipe(
    tap(polling2(scenarioId))
  );
};

function waifForFcmStream() {
  return fcmService.getFirebaseCloudMessage().pipe(
    timeout(3000),
    catchError(err => {
      return of(true);
    })
  );
};


async function polling2(scenarioId) {
  try {
    const tdiResponse = await TDIStatus(scenarioId);
    const status = tdiResponse && tdiResponse.status;
    const nextSteps = getNextSteps(tdiResponse, TDI_STATUS.WAITING);
    const currentStep = getNextStep(tdiResponse, TDI_STATUS.RUNNING);
    setCurrentStep(status + ' ' + (currentStep && currentStep.id));
    if (status === TDI_STATUS.WAITING) {
      setTimeout(function () {
        combineLatest(nextSteps.map(nextStep => {
          return patchStep(scenarioId, nextStep.id)
            .pipe(retryWhen(errors => errors.pipe(
              concatMap((value, index) =>
                iif(
                  () => index < 3,
                  of(value).pipe(delay(tdiPatchStepDelayinMillis)),
                  throwError(value)
                )))));
        })
        )
          .pipe(take(1))
          .subscribe(data => {
            polling2(scenarioId);
          }, err => {
            NotificationService.send(translateCode(err.response.status), 'error');
            wrapUpStream();
          },
            () => console.log('HTTP request completed. ' + JSON.stringify(TDI_CONFIG)));
      }, tdiRetryDelayinMillis);

    } else if (status === TDI_STATUS.ERROR || status === TDI_STATUS.FAILURE) {
      const statusMessage = tdiResponse.state.result.object.docVerifResultDetails;
      const noFaceMessage = "NO_FACE";
      if(statusMessage !== noFaceMessage){
        NotificationService.send(translateCode(tdiResponse.state.result.code), 'error');
      }else{
        NotificationService.send(translateCode(4304), 'error');
      }
      wrapUpStream();
    } else if (status === TDI_STATUS.SUCCESS || status === TDI_STATUS.FINISHED) {
      wrapUpStream();
      NotificationService.send('Pacote criado com sucesso', 'success');
      $complete.next(tdiResponse.id);
    } else {
      setTimeout(() => polling2(scenarioId), 2000);
    }

  } catch
  (error) {
    NotificationService.send(error && error.message, 'error');
    wrapUpStream();
  }
}

function polling(scenarioId) {
  return getTdiStatus(scenarioId).pipe(
    expand((tdiResponse) => {
      const status = tdiResponse && tdiResponse.status;
      const nextStep = getNextStep(tdiResponse, TDI_STATUS.WAITING);
      const currentStep = getNextStep(tdiResponse, TDI_STATUS.RUNNING);
      setCurrentStep(status + ' ' + ((nextStep && nextStep.id) || (currentStep && currentStep.id)));
      if (status === TDI_STATUS.WAITING && shouldPushToStep(nextStep)) {
        pushToSteps(nextStep);
        return patchStep(tdiResponse.id, nextStep).pipe(
          mergeMap(() => {
            return waifForFcmStream().pipe(
              mergeMap(() => {
                return getTdiStatus(tdiResponse.id);
              })
            );
          })
        );
      } else if (status === TDI_STATUS.ERROR || status === TDI_STATUS.FAILURE) {
        NotificationService.send(translateCode(tdiResponse.state.result.code), 'error');
        wrapUpStream();
      } else if (status === TDI_STATUS.SUCCESS || status === TDI_STATUS.FINISHED) {
        wrapUpStream();
        NotificationService.send('Pacote criado com sucesso', 'success');
        $complete.next(tdiResponse.id);
      } else {
        return getTdiStatus(tdiResponse.id).pipe(delay(5000));
      }
    })
  ).toPromise();
};

function getTdiStatus(scenarioId) {
  return new Observable(subscriber => {
    TDIStatus(scenarioId).then(response => {
      subscriber.next(response);
    }).catch(error => {
      NotificationService.send(error && error.message, 'error');
      wrapUpStream();
      subscriber.error(error);
    });
  });
};

function wrapUpStream() {
  _finishedSteps = [];
  setRunningStatus(false);
  $stop.next(true);
};

function getNextStep(data, stepType) {
  let steps = data && data.state && data.state.steps;
  let step = null;
  if (Array.isArray(steps)) {
    step = steps.filter((step) => step.status === stepType)[0];
  }
  return step;
};

function getNextSteps(data, stepType) {
  let steps = data && data.state && data.state.steps;
  let step = null;
  if (Array.isArray(steps)) {
    step = steps.filter((step) => step.status === stepType);
  }
  return step;
};

function pushToSteps(step) {
  if (step && step.id) {
    _finishedSteps.push(step.id);
  }
};

function patchStep(scenarioId, stepId) {
  return new Observable(subscriber => {
    TDIStep({
      name: _fileFormData.scenario,
      input: { ...createTDIStepPayload(stepId, _payload, _fileFormData, _dropdownValues) }
    }, scenarioId, stepId).then(response => {
      subscriber.next(response.data);
    }, error => {
      subscriber.error(error);
    });
  });
};

function shouldPushToStep(step) {
  if (step && step.id) {
    return !_finishedSteps.includes(step.id);
  }
};
