export class DEPRequest {

    requestHeader;
    sessionKeys;
    body;
    mac;
    constructor(requestHeader, sessionKeys, body, mac){
        this.requestHeader = requestHeader;
        this.sessionKeys = sessionKeys;
        this.body = body;
        this.mac = mac;
    }

    get RequestHeader() {
        return this.requestHeader;
    }

    get SessionKeys() {
        return this.sessionKeys;
    }

    get Body() {
        return this.body;
    }

    get Mac() {
        return this.mac;
    }
}
