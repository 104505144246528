import React from "react";
import {FormContextConsumer} from "../../dynamic-form/dynamic-form-context"

const  chooseClassName = (valid, validated) =>{
    if (valid && validated){
        return "form-field-input-default form-field-input--valid"
    }
    else if (!valid && validated){
        return "form-field-input-default form-field-input--invalid"
    }
    else{
        return "form-field-input-default form-field-input"
    }
};

const FormInputField = (props) => {
    return (
        <FormContextConsumer>
            {({ validated }) => (
                <select className={chooseClassName(props.valid, validated)} {...props}>
                    {props.metadata.options.map((option) =>
                        Object.keys(option).map((key) =>
                            <option key={key} value={props.valueFromKey ? key : option[key]}>{option[key]}</option>
                        ))}
                </select>
            )}
        </FormContextConsumer>
    );
};

export default FormInputField;