import React from "react";
import {getStatusColor, getUpdatableStatus} from '../../../api/'
import Grid from "@material-ui/core/Grid";
import {FormContextProvider} from "../dynamic-form/dynamic-form-context";
import SubmitButton from "../dynamic-form/dynamic-form-submit-button"
import Form from "../dynamic-form/dynamic-form-view"
import Status from '../../consult/status';
import {DataService} from '../../../services/data-service';
import SaleType from '../../consult/sale-type';
import { generateSaleTypePayload } from '../../consult/list/consumer-table';
import {ModalService} from '../../../services/modal-service';
import StatusConfirmationForm from './confirmation'


const onSubmit = (reloadPackage) => (payload) => {
    ModalService.changeInfo({
      show: true,
      title: 'Confirmar alterações do pacote?',
      text: <StatusConfirmationForm reloadPackage={reloadPackage} currentPayload={payload}/>,
      closeButton: true,
      spinner: false,
      callback: () => {},
      text2: "",
      maxWidth: "md",
    });
};

async function getStatusForm({_package}) {
  const updatableStatus = await getUpdatableStatus((_package && (_package.info) && _package.info.status));
  const toColors = await Promise.all(updatableStatus.data.allowedStatus.map((status) => {
    return getStatusColor(status)
  }));
  const props = generateSaleTypePayload(((_package && _package.info) || {}));

  return {fields:[
      {"label": "ID do pacote",
        "name": "packageId",
        "required": false,
        "type": "text",
        "disabled": true
      },
      {"label": "Operação",
        "name": "operation",
        "required": false,
        "type": "component",
        "component":<SaleType {...props}/>,
        "disabled": true
      },
      {"label": "Status atual",
        "name": "oldStatus",
        "required": false,
        "type": "component",
        "component":<Status
          showDescription={1}
          status_name={((_package && _package.info) && _package.info.status)} />,
        "disabled": true
      },
      {
        "label": "Novo status",
        "method": "waitForMetadata",
        "multiple": false,
        "name": "status",
        "options": toColors.map((color)=>{
          return color.data.map((status_info) => {
            return {[status_info.status]: <Status
                showDescription={status_info.description}
                status={status_info}/>}
          })
        }).flat(),
        "required": true,
        "type": "componentSelect",
      },
      {"label": "Justificativa",
        "name": "observations",
        "required": false,
        "type": "textArea",
      },
    ]}
}

function createPayload(_package){
  return {
    packageId: {value: _package.info.id.toString(), valid:true},
    oldStatus: {value:_package.info.status, valid:true},
  }
}

const StatusFormView = ({status, _package, reloadPackage}) => {

  React.useEffect(() =>{
    DataService.setPayloadToRetrieveFormInfo({status: status, _package: _package});
  });

  return (
    <Grid container spacing={0}>
      <FormContextProvider getFormInfo={getStatusForm} payload={createPayload(_package)}>
        <Grid container item xs={12} spacing={0} alignItems={'center'} style={{"flexFlow": "column"}}>
          <Form/>
        </Grid>
        <SubmitButton text={'Confirmar'} customSubmit={onSubmit(reloadPackage)}/>
      </FormContextProvider>
    </Grid>
  )
};

export default StatusFormView;
