import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { createSaleTypePayloadHash, DataService } from '../../services/data-service';

export const generateSaleTypePayload = (packageInfo) => {
  const IDCOMBOS = Object.keys(packageInfo).filter((key) => {
    return key.match(/IDCOMBO/gi);
  });
  const CLIENTIDS = Object.keys(packageInfo).filter((key) => {
    return key.match(key.match(/CLIENTID/gi));
  });
  let payload = {
    USERID: packageInfo.USERID,
    ROLEID: packageInfo.ROLEID,
    CLIENTID: 1,
    SUBCLIENTID: 1
  };
  IDCOMBOS.forEach((IDCOMBO) => {
    payload[IDCOMBO] = packageInfo[IDCOMBO];
  });
  CLIENTIDS.forEach((CLIENTID) => {
    payload[CLIENTID] = packageInfo[CLIENTID];
  });
  return payload;

};

export const Operacao = (props) => {
  const [saleType, setSaleType] = React.useState('');

  React.useEffect(() => {
    let saleType = generateSaleTypePayload(props);
    if (saleType.IDCOMBO1) {
      const IDCOMBOS_PRESENT = Object.keys(saleType || {})
        .some((element) => {
          return element.includes('IDCOMBO');
        });
      if (IDCOMBOS_PRESENT) {
        DataService.setSaleTypeList(props);
      }
    }


  }, [props]);

  useEffect(() => {
    const getSaleTypeSubscription = DataService.getSaleTypeList()
      .subscribe((saleTypeList) => {
        setSaleType(saleTypeList[createSaleTypePayloadHash(props)]);
      });
    return () => {
      getSaleTypeSubscription.unsubscribe();
    };
  }, [props]);

  if(props.IDCOMBO1){
    return <div className={props.className}>{saleType}</div>;
  }
  else if (props.OPERACAO) {
    let operacao = props.OPERACAO;
    return <div className={props.className}>{operacao}</div>;
  }
  else return null
};

export default Operacao;