import React from 'react';
import { getPackages } from '../../../api';

export const CorporateTableContext = React.createContext({});

export function TableCorporateContextProvider({children}) {

    const [data, setData] = React.useState([]);
    const [accessed, setAccessed] = React.useState(false);
    const [filters, setFilters] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [count, setCount] = React.useState(10);
    const [corporatePackage, setCorporatePackage] = React.useState(true);

    const addFilter = (filterKey, value) => {
        setFilters((previousFilters) => {return {...previousFilters, [filterKey]:value}})
    };

    const deleteFilter = (key) => {
        setFilters((previousFilters) => {
            delete previousFilters[key];
            return {...previousFilters}
        })
    };

    React.useEffect(() => {
        if (accessed){
            getPackages({pageSize, page, corporatePackage, ...filters}).then((response) => {
                setData((response.packages || []).map(_package => _package.info));
                setCount(response.totalSize)
            })
        }
    }, [filters, pageSize, page, corporatePackage, accessed]);


    return (<CorporateTableContext.Provider value={{
        data,
        filters,
        addFilter: addFilter,
        deleteFilter: deleteFilter,
        page,
        setPage,
        pageSize,
        setPageSize,
        count,
        corporatePackage,
        setCorporatePackage,
        setAccessed
    }}>
        {children}
    </CorporateTableContext.Provider>)
}